import {AxiosResponse} from "axios";

import {BACKEND_URL} from "@/shared/config/constants";

import {apiClient} from "../../apiService";
import {detectOrderSource, getAxiosConfig, getPatchParams} from "../../utils";

import {
	IDeleteCalendarEventsRequestContract,
	IDeleteCalendarRecurrenceEventInstanceRequestContract,
	IDeleteCalendarRecurrenceEventRequestContract,
	IDeleteCustomClientRequestContract,
	IDeleteCustomClientResponseContract,
	IGetCalendarConflictsRequestContract,
	IGetCalendarConflictsResponseContract,
	IGetCalendarEventsRequestContract,
	IGetCalendarEventsResponseContract,
	IGetCalendarRecurrenceEventInstancesRequestContract,
	IGetCalendarRecurrenceEventInstancesResponseContract,
	IGetCalendarRequestContract,
	IGetCalendarResponseContract,
	IGetCalendarTimestamp,
	IGetCustomClientsRequestContract,
	IGetCustomClientsResponseContract,
	IPatchCalendarEventsRequestContract,
	IPatchCalendarRecurrenceEventInstanceRequestContract,
	IPatchCalendarRecurrenceEventRequestContract,
	IPatchCalendarRequestContract,
	IPostCalendarEventsRequestContract,
	IPostCalendarEventsResponseContract,
	IPostCalendarRecurrenceEventRequestContract,
	IPostCalendarRecurrenceEventResponseContract,
	IPostCalendarRecurrenceRuleRequestContract,
	IPostCalendarRecurrenceRuleResponseContract,
	IPutCustomClientRequestContract,
	IPutCustomClientResponseContract,
	IPutIntegrationStateRequestContract,
	IPutIntegrationStateResponseContract
} from "./contracts";

const URL = `${BACKEND_URL}/calendar/v1`;

export const getCalendarApi = (
	contract: IGetCalendarRequestContract
): Promise<AxiosResponse<IGetCalendarResponseContract>> => {
	return apiClient.get(
		`${URL}/calendars/${contract.calendarId}`,
		getAxiosConfig()
	);
};

export const patchCalendarApi = (
	contract: IPatchCalendarRequestContract
): Promise<AxiosResponse<void>> => {
	return apiClient.patch(
		`${URL}/calendars/${contract.calendarId}`,
		getPatchParams(contract, ["timezone"]),
		getAxiosConfig()
	);
};

export const getEventsApi = (
	contract: IGetCalendarEventsRequestContract
): Promise<AxiosResponse<IGetCalendarEventsResponseContract>> => {
	return apiClient.get(
		`${URL}/events?psychologistId=${contract.psychologistId}`,
		getAxiosConfig()
	);
};

export const postEventsApi = (
	contract: IPostCalendarEventsRequestContract
): Promise<AxiosResponse<IPostCalendarEventsResponseContract>> => {
	return apiClient.post(`${URL}/events${detectOrderSource()}`, contract, getAxiosConfig());
};

export const deleteEventApi = (
	contract: IDeleteCalendarEventsRequestContract
): Promise<AxiosResponse<void>> => {
	const queryParams = contract.reason ? `?reason=${contract.reason}` : "";

	return apiClient.delete(
		`${URL}/events/event/${contract.id}${queryParams}`,
		getAxiosConfig()
	);
};

export const patchEventApi = (
	contract: IPatchCalendarEventsRequestContract
): Promise<AxiosResponse<void>> => {
	return apiClient.patch(
		`${URL}/events/${contract.id}`,
		getPatchParams(contract, [
			"type",
			"attendeeId",
			"tsStart",
			"tsEnd",
			"clientInfoId",
			"clientInfoName",
			"title",
			"description"
		]),
		getAxiosConfig()
	);
};

export const postRecurrenceRuleApi = (
	contract: IPostCalendarRecurrenceRuleRequestContract
): Promise<AxiosResponse<IPostCalendarRecurrenceRuleResponseContract>> => {
	return apiClient.post(`${URL}/recurrence/rule`, contract, getAxiosConfig());
};

export const postRecurrenceEventApi = (
	contract: IPostCalendarRecurrenceEventRequestContract
): Promise<AxiosResponse<IPostCalendarRecurrenceEventResponseContract>> => {
	return apiClient.post(`${URL}/recurrence/event`, contract, getAxiosConfig());
};

export const deleteRecurrenceEventApi = (
	contract: IDeleteCalendarRecurrenceEventRequestContract
): Promise<AxiosResponse<void>> => {
	const queryParams = contract.reason ? `?reason=${contract.reason}` : "";

	return apiClient.delete(
		`${URL}/events/recurrence/${contract.id}${queryParams}`,
		getAxiosConfig()
	);
};

export const patchRecurrenceEventApi = (
	contract: IPatchCalendarRecurrenceEventRequestContract
): Promise<AxiosResponse<void>> => {
	return apiClient.patch(
		`${URL}/events/recurrence/${contract.idEvent}`,
		getPatchParams(contract, [
			"type",
			"attendeeId",
			"tsStart",
			"tsEnd",
			"clientInfoId",
			"clientInfoName",
			"title",
			"description"
		]),
		getAxiosConfig()
	);
};

export const patchRecurrenceEventInstanceApi = (
	contract: IPatchCalendarRecurrenceEventInstanceRequestContract
): Promise<AxiosResponse<void>> => {
	return apiClient.patch(
		`${URL}/events/recurrence/${contract.idEvent}/instances/${contract.id}`,
		getPatchParams(contract, [
			"type",
			"attendeeId",
			"tsStart",
			"tsEnd",
			"clientInfoId",
			"clientInfoName",
			"title",
			"description"
		]),
		getAxiosConfig()
	);
};

export const deleteRecurrenceEventInstanceApi = (
	contract: IDeleteCalendarRecurrenceEventInstanceRequestContract
): Promise<AxiosResponse<void>> => {
	const queryParams = contract.reason ? `?reason=${contract.reason}` : "";

	return apiClient.delete(
		`${URL}/events/recurrence/${contract.idEvent}/instances/${contract.id}${queryParams}`,
		getAxiosConfig()
	);
};

export const getRecurrenceEventInstancesApi = (
	contract: IGetCalendarRecurrenceEventInstancesRequestContract
): Promise<
AxiosResponse<IGetCalendarRecurrenceEventInstancesResponseContract>
> => {
	return apiClient.get(
		`${URL}/recurrence/instances?psychologistId=${contract.psychologistId}`,
		getAxiosConfig()
	);
};

export const getConflictsApi = (
	contract: IGetCalendarConflictsRequestContract,
	psychologistId: number
): Promise<AxiosResponse<IGetCalendarConflictsResponseContract[]>> => {
	return apiClient.post(`${URL}/conflicts/search`, contract, getAxiosConfig());
};

export const putIntegrationStateApi = (
	contract: IPutIntegrationStateRequestContract
): Promise<AxiosResponse<IPutIntegrationStateResponseContract>> => {
	const {calendarId, ...bodyParams} = contract;
	return apiClient.put(
		`${URL}/calendars/${calendarId}/integration/sync`,
		bodyParams,
		getAxiosConfig()
	);
};

export const getCustomClients = (
	contract: IGetCustomClientsRequestContract
): Promise<AxiosResponse<IGetCustomClientsResponseContract>> => {
	return apiClient.get(
		`${URL}/calendars/clients/info?calendarId=${contract.calendarId}`,
		getAxiosConfig()
	);
};

export const putCustomClient = (
	contract: IPutCustomClientRequestContract
): Promise<AxiosResponse<IPutCustomClientResponseContract>> => {
	return apiClient.put(
		`${URL}/calendars/clients/info`,
		contract,
		getAxiosConfig()
	);
};

export const deleteCustomClient = (
	contract: IDeleteCustomClientRequestContract
): Promise<AxiosResponse<IDeleteCustomClientResponseContract>> => {
	return apiClient.delete(
		`${URL}/calendars/clients/info/${contract.id}`,
		getAxiosConfig()
	);
};

export const getCalendarTimestamp = () => {
	return apiClient.get<IGetCalendarTimestamp>(
		`${URL}/timestamp`
	);
};
