import React from "react";

export const Block = ({
	children,
	className,
	...props
}: React.PropsWithoutRef<JSX.IntrinsicElements["div"]>): React.ReactElement => {
	return (
    <div className={className} {...props}>
      {children}
    </div>
	);
};
