import {getYear} from "date-fns";
import {range} from "lodash";

export const MONTHS = [
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентябрь",
	"Октябрь",
	"Ноябрь",
	"Декабрь"
];

export const years = range(1940, getYear(new Date()) + 1, 1);
