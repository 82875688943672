import React from "react";
import {useSelector} from "react-redux";

import {getNotificationState} from "@/shared/model/notification/selectors";
import {Typography} from "@/shared/ui/uikit/Typography";

import "./styles.scss";

interface Props {
	children: React.ReactNode
}

export const Layout = (props: Props): React.ReactElement => {
	const {error} = useSelector(getNotificationState);

	return (
		<div className="zg">
			{props.children}
			{error && (
				<div className="zg-notification">
					<div className="zg-notification-content">
						<Typography>{error.value.message}</Typography>
					</div>
				</div>
			)}
			<div id="datepicker-portal" className="calendar-datepicker with-shadow" />
			<div id="timepicker-portal" className="timepicker__block" />
		</div>
	);
};
