import React, {FocusEventHandler, LegacyRef, MouseEventHandler, ReactElement, useState} from "react";

import {EyeSvg} from "@/shared/ui/assets/svg";

import "./custom-input.scss";

export const CustomInput: React.FC<TProps> = React.memo(React.forwardRef(({
	className,
	defaultValue,
	errorMessage,
	icon,
	id,
	isDisabled,
	isValid = true,
	label,
	name,
	onBlur,
	onChange,
	onClick,
	onFocus,
	readOnly,
	showPassword = false,
	type,
	value,
	placeholder,
	min,
	max
}, ref) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	const props: Omit<TProps, "className" | "label" | "icon"> = {
		defaultValue,
		id,
		name,
		onBlur,
		onChange,
		onClick,
		onFocus,
		ref,
		type: passwordVisible && showPassword && type === "password" ? "text" : type,
		value,
		placeholder,
		min,
		max
	};
	return (
    <div className={`custom-input ${className}`}>
      {_renderLabel(label)}
      <div className="custom-input__wrapper">
        {_renderIcon(icon)}
        <input readOnly={readOnly}
               {...props}
               className={`custom-input__input ${isValid ? "" : "custom-input__input--invalid"}`}
               disabled={isDisabled}
        />
        {_renderErrorMessage(errorMessage)}
        {showPassword && _renderSuffix(setPasswordVisible, passwordVisible)}
      </div>
    </div>
	);
}));

const _renderLabel = (label) => {
	if (!label) return null;
	return (
    <span className="custom-input__label">{label}</span>
	);
};

const _renderIcon = (icon) => {
	if (!icon) return null;
	return (
    <div className="custom-input__icon">{icon}</div>
	);
};

const _renderErrorMessage = (errorMessage) => {
	if (!errorMessage) return null;
	return (
    <span className="custom-input__error-message">{errorMessage}</span>
	);
};

const _renderSuffix = (setPasswordVisible, passwordVisible) => (
  <EyeSvg onClick={() => setPasswordVisible(!passwordVisible)} className="custom-input__icon-suffix"/>
);

interface TProps {
	className: string
	defaultValue?: string | number
	icon?: ReactElement
	id?: string
	isValid?: boolean
	label?: string | number
	name?: string
	onBlur?: FocusEventHandler<HTMLInputElement>
	onChange?: FocusEventHandler<HTMLInputElement>
	onFocus?: FocusEventHandler<HTMLInputElement>
	onClick?: MouseEventHandler<HTMLInputElement>
	ref?: LegacyRef<HTMLInputElement>
	value?: string | number
	type?: "text" | "number" | "password"
	isDisabled?: boolean
	showPassword?: boolean
	errorMessage?: string
	readOnly?: boolean
	placeholder?: string
	min?: number
	max?: number
}

CustomInput.displayName = "CustomInput";
