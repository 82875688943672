import React from "react";

import {Block} from "@/shared/ui/uikit/Layout";

import "./input-code.scss";

type IInputCodeProps = {
	hasError: boolean
	className?: string
	errorMessage?: string
	emoji?: string
} & React.PropsWithoutRef<JSX.IntrinsicElements["input"]>;

const InputCode: React.FC<IInputCodeProps> = ({
	hasError,
	disabled,
	onChange,
	className,
	errorMessage,
	emoji
}) => {
	return (
		<Block className={`input-code ${className}`}>
			<Block className="input-code__outer">
				<Block className="input-code__inner">
					<input type="text"
						name="code"
						id="code"
						className={`input-code__field ${hasError && "input-code__field--error"}`}
						disabled={disabled}
						onChange={onChange}
						maxLength={4}
						autoComplete="one-time-code"
					/>
				</Block>
			</Block>
			{_renderErrorMessage(errorMessage, emoji)}
		</Block>
	);
};

const _renderErrorMessage = (errorMessage: string, emoji?: string) => {
	if (!errorMessage) return null;
	return (
		<Block className="input-code__error-message-wrapper">
			<Block className="input-code__error-message-icon-wrapper">
				<img className="input-code__error-message-icon" src={emoji} alt="emoji"/>
			</Block>
			<span className="input-code__error-message">{errorMessage}</span>
		</Block>
	);
};

export default React.memo(InputCode);
