import React from "react";
import "./styles.scss";
import cls from "classnames";

/**
 * @prop children Дочерний компонент.
 * @prop [separator] Флаг использования разделителей.
 */
type Props = {
	children: React.ReactNode
	separator?: boolean
};

export const FlexContainer = ({children, separator}: Props): React.ReactElement => {
	return (
    <div className={cls("zg__flex-container", {"zg__flex-container_separator": separator})}>
      {children}
    </div>
	);
};
