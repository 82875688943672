import {getDefaultMiddleware} from "@reduxjs/toolkit";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
// import { routerMiddleware } from 'connected-react-router';
// import {PUT_PHOTO_ON_MODERATION} from "@/shared/types/profile";
// import {PUT_EDUCATION_ON_MODERATION} from "@/shared/types/onboarding";
// import {actions} from "./onboarding/slice";

export const sagaMiddleware = createSagaMiddleware();
const middleware = [
	...getDefaultMiddleware({
		// Allow non-serializable data, see https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
		serializableCheck: false
	}),
	sagaMiddleware
	// routerMiddleware(history)
];

// {
// 	// Ignore these field paths in all actions
// 	ignoredActionPaths: ['payload.onSuccess'],
// 	ignoredActions: [PUT_PHOTO_ON_MODERATION, PUT_EDUCATION_ON_MODERATION]
// }

// if (process.env.NODE_ENV !== "production") {
// 	middleware.push(logger);
// }

export default middleware;
