import {BACKEND_URL} from "@/shared/config/constants";
import {apiClient} from "../../apiService";
import {getAxiosConfig} from "../../utils";
import {
	TChangeUserEmail,
	TDeleteEducationOnModeration,
	TGetOnboardingPersonal,
	TGetOnboardingUserContract,
	TGetUserEducation,
	TGetUserExperience,
	TGetUserInformation,
	TGetUserPractices,
	TGetUserSupervision,
	TPostEducationOnModeration,
	TPostUserMedia,
	TPostUserPersonalOnModerationContract,
	TPutEducationOnModeration,
	TPutExperienceOnModeration,
	TPutPracticesOnModeration,
	TPutSupervisionOnModeration,
	TPutUserInformationOnModeration,
	TPutUserPersonalOnModerationContract,
	TPutUserSkype,
	TPutUserStatus
} from "./contracts";

export const getUserDataApi = (contract: TGetOnboardingUserContract["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v2/user/${contract.userId}`);
};

export const putUserPersonalApi = (
	contract: TPutUserPersonalOnModerationContract["payload"]
) => {
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/personal`, contract.data);
};

export const postUserPersonalApi = (
	contract: TPostUserPersonalOnModerationContract["payload"]
) => {
	return apiClient.post(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/personal`, contract.data);
};

export const getUserPersonalApi = (contract: TGetOnboardingPersonal["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/personal`);
};

export const getUserSupervisionApi = (contract: TGetUserSupervision["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/supervision`);
};

export const putUserSupervisionApi = (contract: TPutSupervisionOnModeration["payload"]) => {
	const {userId, data} = contract;
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${userId}/supervision`, data);
};

export const putUserEducationApi = (contract: TPutEducationOnModeration["payload"]) => {
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v2/educations`, contract.data);
};

export const postUserEducationApi = (contract: TPostEducationOnModeration["payload"]) => {
	const {userId, data} = contract;
	return apiClient.post(`${BACKEND_URL}/onboarding-psychologist/v2/user/${userId}/educations`, data);
};

export const postUserMediaFiles = (contract: TPostUserMedia["payload"]) => {
	const {userId, data} = contract;
	const formData = new FormData();
	formData.append("file", data);
	formData.append("userId", userId);

	return apiClient.post(`${BACKEND_URL}/media/v1/files`, formData, {
		timeout: 15000
	});
};

export const deleteUserEducationApi = (contract: TDeleteEducationOnModeration["payload"]) => {
	return apiClient.delete(`${BACKEND_URL}/onboarding-psychologist/v1/educations/${contract.id}`);
};

export const getUserEducationApi = (contract: TGetUserEducation["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/educations`);
};

export const getUserExperienceApi = (contract: TGetUserExperience["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/experience`);
};

export const putUserExperienceApi = (contract: TPutExperienceOnModeration["payload"]) => {
	const {userId, data} = contract;
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${userId}/experience`, data);
};

export const getUserPracticesApi = (contract: TGetUserPractices["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/practices`);
};

export const putUserPracticesApi = (contract: TPutPracticesOnModeration["payload"]) => {
	const {userId, data} = contract;
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${userId}/practices`, data);
};

export const putUserInformationApi = (contract: TPutUserInformationOnModeration["payload"]) => {
	const {userId, hoursPerWeek} = contract;
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${userId}/information`, {hoursPerWeek: hoursPerWeek});
};

export const getUserInformationApi = (contract: TGetUserInformation["payload"]) => {
	return apiClient.get(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/information`);
};

export const putUserSkypeApi = (contract: TPutUserSkype) => {
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/information`, {skype: contract.skype});
};

export const putUserStatusApi = (contract: TPutUserStatus) => {
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/status`, {statusIdNew: contract.statusIdNew});
};

export const changeUserEmailApi = (contract: TChangeUserEmail) => {
	return apiClient.put(`${BACKEND_URL}/onboarding-psychologist/v1/user/${contract.userId}/personal`, {email: contract.email});
};
