import {RootState} from "@/app/model/reducer";

export const getAuthState = (state: RootState) => state.auth;

export const GET_PASSWORD_CHANGE_STATE = (state: RootState) => state.auth.passwordChange;

const GET_EMAIL_CHANGE_STATE = (state: RootState) => state.auth.emailChange;

const GET_EMAIL_CONFIRM_MODAL_STATE = (state: RootState) => state.auth.emailConfirm.isOpen;

const GET_REQUEST_ERROR_TIMER = (state: RootState) => state.auth.requestErrorTimer;

export default {
	GET_EMAIL_CHANGE_STATE,
	GET_PASSWORD_CHANGE_STATE,
	GET_EMAIL_CONFIRM_MODAL_STATE,
	GET_REQUEST_ERROR_TIMER
};
