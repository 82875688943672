import {all, put, call, takeLatest} from "redux-saga/effects";
import * as types from "@/shared/types/services";
import {getAllPsychologistOffersApi, postOfferAcceptApi} from "@/shared/api/v1/offer/requests";
import actions from "@/shared/model/services/slice";
import {getAllDocumentTypesApi, getAllPracticesApi, getAllTopicsApi} from "@/shared/api/v1/psychologists/requests";

function* callGetAllPsychologistOffersSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const offers = yield call(getAllPsychologistOffersApi, {userId});
		yield put(actions.OFFER.GET_ALL_OFFERS_SUCCESS(offers.data));
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(actions.OFFER.GET_ALL_OFFERS_ERROR(err.response.status));
	}
}

function* callPostPsychologistOfferAcceptSaga({payload}: types.TOffer["TPostPsychologistOfferAccept"]) {
	try {
		const userId = +localStorage.getItem("userId");
		yield call(postOfferAcceptApi, {...payload, userId});
		yield put(actions.OFFER.POST_ACCEPT_SUCCESS());
		yield put(actions.OFFER.GET_ALL_OFFERS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(actions.OFFER.POST_ACCEPT_ERROR(err.response.status));
	}
}

function* callGetAllTopicsSaga() {
	try {
		const topics = yield call(getAllTopicsApi);
		yield put(actions.PSYCHOLOGIST.GET_ALL_TOPICS_SUCCESS(topics.data));
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(actions.PSYCHOLOGIST.GET_ALL_TOPICS_ERROR(err.response.status));
	}
}

function* callGetAllPracticesSaga() {
	try {
		const practices = yield call(getAllPracticesApi);
		yield put(actions.PSYCHOLOGIST.GET_ALL_PRACTICES_SUCCESS(practices.data));
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(actions.PSYCHOLOGIST.GET_ALL_PRACTICES_ERROR(err.response.status));
	}
}

function* callGetAllDocumentTypesSaga() {
	try {
		const documentTypes = yield call(getAllDocumentTypesApi);
		yield put(actions.PSYCHOLOGIST.GET_ALL_DOCUMENT_TYPES_SUCCESS(documentTypes.data));
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(actions.PSYCHOLOGIST.GET_ALL_DOCUMENT_TYPES_ERROR(err.response.status));
	}
}

function* servicesSaga() {
	yield all([
		takeLatest(types.GET_ALL_OFFERS, callGetAllPsychologistOffersSaga),
		takeLatest(types.POST_ACCEPT, callPostPsychologistOfferAcceptSaga),
		takeLatest(types.GET_ALL_TOPICS, callGetAllTopicsSaga),
		takeLatest(types.GET_ALL_PRACTICES, callGetAllPracticesSaga),
		takeLatest(types.GET_ALL_DOCUMENT_TYPES, callGetAllDocumentTypesSaga)
	]);
}

export default servicesSaga;
