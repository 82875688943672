import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {PutPsychologistPaymentData} from "./contracts";

export const putPsychologistPaymentDataApi = (contracts: PutPsychologistPaymentData) => {
	const {psychologistId, bankAccount, paymentAccount} = contracts;
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v6/psychologists/${psychologistId}/payment_account`, {
			bankAccount,
			paymentAccount
		}
	);
};
