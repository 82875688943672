import React, {useEffect, useMemo, useState} from "react";
import {useForm, UseFormMethods} from "react-hook-form";
import {useSelector} from "react-redux";
import format from "date-fns/format";
import moment from "moment-timezone";

import authSelector, {getAuthState} from "@/shared/model/auth/selectors";
import {TPutUserPersonalOnModeration} from "@/shared/types/onboarding";
import {CustomInput} from "@/shared/ui/uikit/CustomInput/custom-input";
import {CustomRadio, TRadioData} from "@/shared/ui/uikit/CustomRadio/custom-radio";
import CustomReactDatePicker from "@/shared/ui/uikit/CustomReactDatePicker/CustomReactDatePicker";
import {Button} from "@/shared/ui/uikit/Form";
import {Block} from "@/shared/ui/uikit/Layout";
import PhoneNumber from "@/shared/ui/uikit/PhoneNumber/PhoneNumber";
import PrivacyAndPolicy from "@/widgets/PrivacyAndPolicy";

import {data, VALID_RULES} from "../../config/registration.constants";

import "./registartion-form.scss";

interface TProps {
	onSubmit: (data: TPutUserPersonalOnModeration, email: string) => void
}

const RegistrationForm: React.FC<TProps> = ({onSubmit}) => {
	const {register, handleSubmit, formState: {errors}, control} = useForm<TPutUserPersonalOnModeration>({
		mode          : "onChange",
		reValidateMode: "onChange"
	});
	const [sex, setSex] = useState("");
	const {requestError} = useSelector(getAuthState);
	const requestErrorTimer = useSelector(authSelector.GET_REQUEST_ERROR_TIMER);

	const [timer, setTimer] = useState(requestErrorTimer);
	const [timerActive, setTimerActive] = useState(false);

	useEffect(() => {
		if (requestErrorTimer > 0) {
			setTimer(requestErrorTimer);
			setTimerActive(true);
			const intervalId = setInterval(() => {
				setTimer((prevTimer) => {
					if (prevTimer === 0) {
						clearInterval(intervalId);
						setTimerActive(false);
						return prevTimer;
					}
					return prevTimer - 1;
				});
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [requestErrorTimer]);

	const _submit = (data: TPutUserPersonalOnModeration) => {
		onSubmit({
			...data,
			phone: {
				...data.phone,
				number: `+${data.phone.number}`
			},
			birthDate: format(new Date(data.birthDate), "yyyy-MM-dd")
		}, data.email);
		localStorage.setItem("email", data.email);
	};

	const handleSex = (e: TRadioData) => {
		setSex(e.value);
	};

	return (
		<form className="registration-form" onSubmit={handleSubmit(_submit)}>
			{renderLastName(register, errors)}
			{renderFirstName(register, errors)}
			{renderMiddleName(register, errors)}
			{renderDatePicker(errors, control)}
			{renderSex(sex, register, errors, handleSex)}
			{renderNationality(register, errors)}
			{renderCountry(register, errors)}
			{renderCity(register, errors)}
			{renderPhoneNumber(errors, control)}
			{renderEmail(register, errors)}
			<Button className="registration-form__submit"
				type="submit"
				color="primary"
				disabled={timerActive}
			>
        Далее
			</Button>
			{requestError && requestError.status === 429 && timerActive && <Block className="registration-form__error-timer">
				<div>Вы исчерпали свои попытки зарегистрироваться, попробуйте снова через {timer} cек.</div>
			</Block>}
			<Block className="registration-form__text">
				<PrivacyAndPolicy text="Нажимая «Далее»" />
			</Block>
		</form>
	);
};

const renderSex = (
	sex: string,
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"],
	handleSex: (e: TRadioData) => void
) => useMemo(() => {
	return (
		<CustomRadio className="registration-form__field registration-form__field--radio"
			checked={sex}
			data={data}
			isValid={!errors.sex}
			label="Пол"
			name="sex"
			onChange={e => handleSex(e)}
			ref={register(VALID_RULES.sex)}
			errorMessage={errors.sex?.message}
		/>
	);
}, [errors.sex, sex]);

const renderFirstName = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.firstName}
			label='Имя'
			name="firstName"
			ref={register(VALID_RULES.firstName)}
			errorMessage={errors.firstName?.message}
		/>
	);
}, [errors.firstName]);

const renderLastName = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.lastName}
			label='Фамилия'
			name="lastName"
			ref={register(VALID_RULES.lastName)}
			errorMessage={errors.lastName?.message}
		/>
	);
}, [errors.lastName]);

const renderMiddleName = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.middleName}
			label='Отчество'
			name="middleName"
			ref={register(VALID_RULES.middleName)}
			errorMessage={errors.middleName?.message}
		/>
	);
}, [errors.middleName]);

const renderNationality = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.nationality}
			label='Гражданство'
			name="nationality"
			ref={register(VALID_RULES.nationality)}
			errorMessage={errors.nationality?.message}
		/>
	);
}, [errors.nationality]);

const renderCountry = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.country}
			label='Страна'
			name="country"
			ref={register(VALID_RULES.country)}
			errorMessage={errors.country?.message}
		/>
	);
}, [errors.country]);

const renderCity = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className='registration-form__field'
			isValid={!errors.city}
			label='Город'
			name="city"
			ref={register(VALID_RULES.city)}
			errorMessage={errors.city?.message}
		/>
	);
}, [errors.city]);

const renderEmail = (
	register: UseFormMethods["register"],
	errors: UseFormMethods["errors"]
) => useMemo(() => {
	return (
		<CustomInput className="registration-form__field"
			ref={register(VALID_RULES.email)}
			label='E-mail'
			name="email"
			isValid={!errors.email}
			errorMessage={errors.email?.message}
		/>
	);
}, [errors.email]);

const renderDatePicker = (
	errors: UseFormMethods["errors"],
	control: UseFormMethods["control"]
) => useMemo(() => {
	return (
		<CustomReactDatePicker className="registration-form__field registration-form__field--datepicker"
			label="Дата рождения"
			name="birthDate"
			rules={VALID_RULES.birthDate}
			control={control}
			onChange={(date) => date}
			dateFormat="dd.MM.yyyy"
			placeholder="01.01.2000"
			isValid={!errors.birthDate}
			errorMessage={errors.birthDate?.message}
			maxDate={moment().subtract(18, "years").startOf("day").toDate()}
		/>
	);
}, [errors.birthDate]);

const renderPhoneNumber = (
	errors: UseFormMethods["errors"],
	control: UseFormMethods["control"]
) => useMemo(() => {
	return (
		<PhoneNumber className="registration-form__field"
			control={control}
			errorMessage={errors.phone?.message}
			label="Телефон"
			rules={VALID_RULES.phone}
			isValid={!errors.phone}
		/>
	);
}, [errors.phone]);

export default React.memo(RegistrationForm);
