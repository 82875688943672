import type {FeatureFlagsList} from "../ui/provider";

export const STORAGE_FLAGS_KEY = "zo_feature_flags";

export const getStorageFlags = (): FeatureFlagsList | null => {
	const storageData = sessionStorage.getItem(STORAGE_FLAGS_KEY);
	return storageData ? JSON.parse(storageData) : null;
};

export const setStorageFlags = (flagsList: FeatureFlagsList) => {
	sessionStorage.setItem(STORAGE_FLAGS_KEY, JSON.stringify(flagsList));
};
