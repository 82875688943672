import React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import TagManager from "react-gtm-module";
import {BrowserRouter} from "react-router-dom";
import {ExtraErrorData as ExtraErrorDataIntegration} from "@sentry/integrations";
import * as Sentry from "@sentry/react";

import {isDevelopment, TAG_ENV} from "@/shared/config/constants";

import {FeatureFlagsProvider} from "./providers/feature-flags";
import App from "./App";

if (!isDevelopment) {
	console.log("INIT ANALYTICS");
	// GTM
	const tagManagerArgs = {
		gtmId        : "GTM-WQ2TCZN",
		dataLayerName: "PageDataLayer"
	};
	TagManager.initialize(tagManagerArgs);
}

if (!isDevelopment) {
	const lastEventTime = 0;

	Sentry.init({
		dsn         : "https://71c6dd64632aa1071bd39ae859f405d0@sentry.zsvc.tech/4",
		environment : process.env.DEPLOY_ENV || "development",
		ignoreErrors: [
			"top.GLOBALS",
			"ChunkLoadError",
			"Loading CSS chunk",
			"Loading chunk",
			"Can't find variable: VK",
			"Can't find variable: yaCounter",
			"Unexpected token '<'",
			"runCustomize is not defined",
			"Unexpected token 'function'",
			"processRandomSelector is not defined",
			"Unexpected identifier",
			"VK is not defined",
			"Failed to fetch dynamically imported module",
			"Importing a module script failed",
			"'text/html' is not a valid JavaScript MIME type"

		],
		denyUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i
		  ],
		release: TAG_ENV,
		beforeSend(event, hint) {
			if (event.timestamp && event.timestamp - lastEventTime < 60) {
				return null;
			}

			if (hint?.originalException === "Timeout") return null;
			return event;
		},
		integrations: [
			new ExtraErrorDataIntegration({
				depth: 8
			})
		],
		normalizeDepth: 8
	});
	Sentry.setContext("psychologistId", {
		id: localStorage.getItem("userId")
	});
}

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const Index = (
	<BrowserRouter>
		<FeatureFlagsProvider>
			<App />
		</FeatureFlagsProvider>
	</BrowserRouter>
);

const rootElement = document.getElementById("app")!;

const root = createRoot(rootElement);
if (rootElement?.hasChildNodes()) {
	hydrateRoot(rootElement, Index);
} else {
	root.render(Index);
}
