import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CalendarEvent, CalendarRecurranceRule} from "@/shared/types/calendar";
import {RequestError} from "@/shared/types/error";

const addEventToState = (
	eventState: ICalendarState["events"],
	event: CalendarEvent
): ICalendarState["events"] => ({
	...eventState,
	data: [...eventState.data, event]
});
const updateEventForState = (
	eventState: ICalendarState["events"],
	event: CalendarEvent
): ICalendarState["events"] => ({
	...eventState,
	data: eventState.data.map((evnt) => (evnt.id === event.id ? event : evnt))
});

const removeEventFromState = (
	eventState: ICalendarState["events"],
	eventId: number
): ICalendarState["events"] => ({
	...eventState,
	data: eventState.data.filter((evnt) => evnt.id !== eventId)
});

interface ICalendarState {
	calendar: {
		isLoading: boolean
		data?: {
			userId: number
			timezone: string
		}
		errors?: RequestError
	}
	events: {
		isLoading: boolean
		data: CalendarEvent[]
		errors?: RequestError
	}
	createEvent: {
		isLoading: boolean
		data: CalendarEvent
		errors?: RequestError
	}
	deleteEvent: {
		isLoading: boolean
		errors?: RequestError
	}
	patchEvent: {
		isLoading: boolean
		errors?: RequestError
	}
	createRecurrenceRule: {
		isLoading: boolean
		data: CalendarRecurranceRule
		errors?: RequestError
	}
	createRecurrenceEvent: {
		isLoading: boolean
		errors?: RequestError
	}
	deleteRecurrenceEvent: {
		isLoading: boolean
		errors?: RequestError
	}
	patchRecurrenceEvent: {
		isLoading: boolean
		errors?: RequestError
	}
	patchRecurrenceEventInstance: {
		isLoading: boolean
		errors?: RequestError
	}
	deleteRecurrenceEventInstance: {
		isLoading: boolean
		errors?: RequestError
	}
	recurrenceEventInstances: {
		isLoading: boolean
		data: CalendarEvent[]
		errors?: RequestError
	}
}

const getInitialState = (): ICalendarState => ({
	calendar: {
		isLoading: false,
		data     : null
	},
	events: {
		isLoading: false,
		data     : []
	},
	createEvent: {
		isLoading: false,
		data     : null
	},
	deleteEvent: {
		isLoading: false
	},
	patchEvent: {
		isLoading: false
	},
	createRecurrenceRule: {
		isLoading: false,
		data     : null
	},
	createRecurrenceEvent: {
		isLoading: false
	},
	deleteRecurrenceEvent: {
		isLoading: false
	},
	patchRecurrenceEvent: {
		isLoading: false
	},
	patchRecurrenceEventInstance: {
		isLoading: false
	},
	deleteRecurrenceEventInstance: {
		isLoading: false
	},
	recurrenceEventInstances: {
		isLoading: false,
		data     : []
	}
});

const getCalendarSuccess: CaseReducer<
ICalendarState,
PayloadAction<{
	userId: number
	timezone: string
}>
> = (state, action) => ({
	...state,
	calendar: {
		isLoading: false,
		data     : action.payload
	}
});
const getCalendarFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	calendar: {
		...state.calendar,
		isLoading: false,
		errors   : action.payload
	}
});
const patchCalendar: CaseReducer<
ICalendarState,
PayloadAction<{
	calendarId: number
	timezone: string
}>
> = (state) => ({
	...state,
	calendar: {
		...state.calendar,
		isLoading: true
	}
});
const patchCalendarSuccess: CaseReducer<
ICalendarState,
PayloadAction<string>
> = (state, action) => ({
	...state,
	calendar: {
		isLoading: false,
		data     : {
			...state.calendar.data,
			timezone: action.payload
		}
	}
});
const patchCalendarFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	calendar: {
		...state.calendar,
		isLoading: false,
		errors   : action.payload
	}
});
const getEvents: CaseReducer<ICalendarState> = (state) => ({
	...state,
	events: {
		...state.events,
		isLoading: true
	}
});
const getEventsSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	events: {
		isLoading: false,
		data     : action.payload
	}
});
const getEventsFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	events: {
		...state.events,
		isLoading: false,
		errors   : action.payload
	}
});

export interface IdPayloadWithSuccess {
	id: number
	onSuccess: () => void
}
export interface EventPayloadWithSuccess {
	event: CalendarEvent
	onSuccess: () => void
}
export interface PayloadWithReason {
	reason: string
}

const createEvent: CaseReducer<
ICalendarState,
PayloadAction<EventPayloadWithSuccess>
> = (state) => ({
	...state,
	createEvent: {
		...state.createEvent,
		isLoading: true
	}
});
const createEventSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent>
> = (state, action) => ({
	...state,
	createEvent: {
		isLoading: false,
		data     : action.payload
	},
	events: addEventToState(state.events, action.payload)
});
const createEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	createEvent: {
		...state.createEvent,
		isLoading: false,
		errors   : action.payload
	}
});
const deleteEvent: CaseReducer<
ICalendarState,
PayloadAction<IdPayloadWithSuccess & PayloadWithReason>
> = (state) => ({
	...state,
	deleteEvent: {
		...state.deleteEvent,
		isLoading: true
	}
});
const deleteEventSuccess: CaseReducer<ICalendarState, PayloadAction<number>> = (
	state,
	action
) => ({
	...state,
	deleteEvent: {
		isLoading: false
	},
	events: removeEventFromState(state.events, action.payload)
});
const deleteEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	deleteEvent: {
		isLoading: false,
		errors   : action.payload
	}
});
const patchEvent: CaseReducer<
ICalendarState,
PayloadAction<EventPayloadWithSuccess>
> = (state) => ({
	...state,
	patchEvent: {
		...state.patchEvent,
		isLoading: true
	}
});
const patchEventSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent>
> = (state, action) => ({
	...state,
	patchEvent: {
		isLoading: false
	},
	events: updateEventForState(state.events, action.payload)
});
const patchEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	patchEvent: {
		isLoading: false,
		errors   : action.payload
	}
});
const createRecurrenceRule: CaseReducer<
ICalendarState,
PayloadAction<CalendarRecurranceRule>
> = (state) => ({
	...state,
	createRecurrenceRule: {
		...state.createRecurrenceRule,
		isLoading: true
	}
});
const createRecurrenceRuleSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarRecurranceRule>
> = (state, action) => ({
	...state,
	createRecurrenceRule: {
		isLoading: false,
		data     : action.payload
	}
});
const createRecurrenceRuleFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	createRecurrenceRule: {
		...state.createRecurrenceRule,
		isLoading: false,
		errors   : action.payload
	}
});
const createRecurrenceEvent: CaseReducer<
ICalendarState,
PayloadAction<{
	event: CalendarEvent
	rule: CalendarRecurranceRule
	onSuccess: () => void
}>
> = (state) => ({
	...state,
	createRecurrenceEvent: {
		...state.createRecurrenceEvent,
		isLoading: true
	}
});
const createRecurrenceEventSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	createRecurrenceEvent: {
		...state.createRecurrenceEvent,
		isLoading: false
	},
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		data: action.payload
	}
});
const createRecurrenceEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	createRecurrenceEvent: {
		...state.createRecurrenceEvent,
		isLoading: false,
		errors   : action.payload
	}
});
const deleteRecurrenceEvent: CaseReducer<
ICalendarState,
PayloadAction<IdPayloadWithSuccess & PayloadWithReason>
> = (state) => ({
	...state,
	deleteRecurrenceEvent: {
		...state.deleteRecurrenceEvent,
		isLoading: true
	}
});
const deleteRecurrenceEventSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	deleteRecurrenceEvent: {
		isLoading: false
	},
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		data: action.payload
	}
});
const deleteRecurrenceEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	deleteRecurrenceEvent: {
		isLoading: false,
		errors   : action.payload
	}
});
const patchRecurrenceEvent: CaseReducer<
ICalendarState,
PayloadAction<EventPayloadWithSuccess>
> = (state) => ({
	...state,
	patchRecurrenceEvent: {
		...state.patchRecurrenceEvent,
		isLoading: true
	}
});
const patchRecurrenceEventSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	patchRecurrenceEvent: {
		isLoading: false
	},
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		data: action.payload
	}
});
const patchRecurrenceEventFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	patchRecurrenceEvent: {
		isLoading: false,
		errors   : action.payload
	}
});
const patchRecurrenceEventInstance: CaseReducer<
ICalendarState,
PayloadAction<EventPayloadWithSuccess>
> = (state) => ({
	...state,
	patchRecurrenceEventInstance: {
		...state.patchRecurrenceEventInstance,
		isLoading: true
	}
});
const patchRecurrenceEventInstanceSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	patchRecurrenceEventInstance: {
		isLoading: false
	},
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		data: action.payload
	}
});
const patchRecurrenceEventInstanceFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	patchRecurrenceEventInstance: {
		isLoading: false,
		errors   : action.payload
	}
});
const deleteRecurrenceEventInstance: CaseReducer<
ICalendarState,
PayloadAction<EventPayloadWithSuccess & PayloadWithReason>
> = (state) => ({
	...state,
	deleteRecurrenceEventInstance: {
		...state.deleteRecurrenceEventInstance,
		isLoading: true
	}
});
const deleteRecurrenceEventInstanceSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	deleteRecurrenceEventInstance: {
		isLoading: false
	},
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		data: action.payload
	}
});
const deleteRecurrenceEventInstanceFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	deleteRecurrenceEventInstance: {
		isLoading: false,
		errors   : action.payload
	}
});
const getRecurrenceEventInstances: CaseReducer<
ICalendarState,
PayloadAction<number>
> = (state) => ({
	...state,
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		isLoading: true
	}
});
const getRecurrenceEventInstancesSuccess: CaseReducer<
ICalendarState,
PayloadAction<CalendarEvent[]>
> = (state, action) => ({
	...state,
	recurrenceEventInstances: {
		isLoading: false,
		data     : action.payload
	}
});
const getRecurrenceEventInstancesFailure: CaseReducer<
ICalendarState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	recurrenceEventInstances: {
		...state.recurrenceEventInstances,
		isLoading: false,
		errors   : action.payload
	}
});
const clearEventsState: CaseReducer<ICalendarState> = (state) => ({
	...getInitialState(),
	calendar                : state.calendar,
	events                  : state.events,
	recurrenceEventInstances: state.recurrenceEventInstances
});

const slice = createSlice({
	name        : "calendar",
	initialState: getInitialState(),
	reducers    : {
		getCalendarSuccess,
		getCalendarFailure,
		patchCalendar,
		patchCalendarSuccess,
		patchCalendarFailure,
		getEvents,
		getEventsSuccess,
		getEventsFailure,
		createEvent,
		createEventSuccess,
		createEventFailure,
		deleteEvent,
		deleteEventSuccess,
		deleteEventFailure,
		patchEvent,
		patchEventSuccess,
		patchEventFailure,
		createRecurrenceRule,
		createRecurrenceRuleSuccess,
		createRecurrenceRuleFailure,
		createRecurrenceEvent,
		createRecurrenceEventSuccess,
		createRecurrenceEventFailure,
		deleteRecurrenceEvent,
		deleteRecurrenceEventSuccess,
		deleteRecurrenceEventFailure,
		patchRecurrenceEvent,
		patchRecurrenceEventSuccess,
		patchRecurrenceEventFailure,
		patchRecurrenceEventInstance,
		patchRecurrenceEventInstanceSuccess,
		patchRecurrenceEventInstanceFailure,
		deleteRecurrenceEventInstance,
		deleteRecurrenceEventInstanceSuccess,
		deleteRecurrenceEventInstanceFailure,
		getRecurrenceEventInstances,
		getRecurrenceEventInstancesSuccess,
		getRecurrenceEventInstancesFailure,
		clearEventsState
	}
});

export const {reducer, actions} = slice;
