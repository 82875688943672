import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as types from "@/shared/types/profile";
import {TProfileBioPractices, TProfileFetchTrigger, TProfileModerationTrigger, TProfileOnModeration, TProfilePage, TProfileSubpage, TProfileTopicV1} from "@/shared/types/profile";

const initialState: TProfileState = {
	editor: {
		editablePage: undefined
	},
	moderation: {
		data: undefined
	},
	loading: false,
	success: undefined,
	error  : undefined
};

/**
 * window.scrollTo используется для положительного опыта юзера, чтобы шапка не появлялась резко после перехода на страницу
 */
const SET_EDITABLE_PAGE: CaseReducer<TProfileState, PayloadAction<types.SetEditablePageTAction["payload"]>> = (state, action) => {
	window.scrollTo({top: 0});
	return {
		...state,
		success: undefined,
		error  : undefined,
		editor : {
			...state.editor,
			editablePage: action.payload
		}
	};
};

const CLEAR_PROFILE_STATUS_STATE: CaseReducer<TProfileState> = (state) => ({
	...state,
	loading: false,
	error  : undefined,
	success: undefined
});

const PUT_COMPETENCES_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutCompetencesOnModerationTAction["payload"]>> = (state, action) => ({
	...state,
	loading: "competencesOnModeration"
});

const PUT_COMPETENCES_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state) => ({
	...state,
	loading: false,
	success: "competencesOnModeration"
});

const PUT_COMPETENCES_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutCompetencesOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "competencesOnModeration", code: action.payload}
});

const PUT_PRACTICES_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutPracticesOnModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "practicesOnModeration"
});

const PUT_PRACTICES_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state, action) => ({
	...state,
	loading: false,
	success: "practicesOnModeration"
});

const PUT_PRACTICES_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutPracticesOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "practicesOnModeration", code: action.payload}
});

const PUT_ABOUT_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutAboutOnModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "aboutOnModeration"
});

const PUT_ABOUT_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state, action) => ({
	...state,
	loading: false,
	success: "aboutOnModeration"
});

const PUT_ABOUT_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutAboutOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "aboutOnModeration", code: action.payload}
});

const PUT_PERSONAL_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutPersonalOnModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "personalOnModeration"
});

const PUT_PERSONAL_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state, action) => ({
	...state,
	loading: false,
	success: "personalOnModeration"
});

const PUT_PERSONAL_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutPersonalOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "personalOnModeration", code: action.payload}
});

const PUT_EDUCATION_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutEducationOnModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "educationOnModeration"
});

const PUT_EDUCATION_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state, action) => ({
	...state,
	loading: false,
	success: "educationOnModeration"
});

const PUT_EDUCATION_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutEducationOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "educationOnModeration", code: action.payload}
});

const PUT_PHOTO_ON_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutPhotoOnModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "photoOnModeration"
});

const PUT_PHOTO_ON_MODERATION_SUCCESS: CaseReducer<TProfileState> = (state, action) => ({
	...state,
	loading: false,
	success: "photoOnModeration"
});

const PUT_PHOTO_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutPhotoOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "photoOnModeration", code: action.payload}
});

const PUT_REJECT_MODERATION: CaseReducer<TProfileState, PayloadAction<types.PutRejectModerationTAction["payload"]>> = (state) => ({
	...state,
	loading: "rejectModeration"
});

const PUT_REJECT_MODERATION_SUCCESS: CaseReducer<TProfileState, PayloadAction<number>> = (state, action) => ({
	...state,
	loading        : false,
	success        : "rejectModeration",
	successResponse: action.payload
});

const PUT_REJECT_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.PutRejectModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "rejectModeration", code: action.payload}
});

const GET_PROFILE_ON_MODERATION: CaseReducer<TProfileState> = (state) => ({
	...state,
	loading: "profileOnModeration"
});

const GET_PROFILE_ON_MODERATION_SUCCESS: CaseReducer<TProfileState, PayloadAction<types.GetProfileOnModerationTAction["payload"]>> = (state, action) => ({
	...state,
	moderation: {
		...state.moderation,
		data: action.payload
	},
	loading: false
});

const GET_PROFILE_ON_MODERATION_ERROR: CaseReducer<TProfileState, PayloadAction<types.GetProfileOnModerationTAction["errorPayload"]>> = (state, action) => ({
	...state,
	loading: false,
	error  : {service: "profileOnModeration", code: action.payload}
});

const slice = createSlice({
	name    : "profile",
	initialState,
	reducers: {
		CLEAR_PROFILE_STATUS_STATE,
		GET_PROFILE_ON_MODERATION,
		GET_PROFILE_ON_MODERATION_ERROR,
		GET_PROFILE_ON_MODERATION_SUCCESS,
		PUT_ABOUT_ON_MODERATION,
		PUT_ABOUT_ON_MODERATION_ERROR,
		PUT_ABOUT_ON_MODERATION_SUCCESS,
		PUT_COMPETENCES_ON_MODERATION,
		PUT_COMPETENCES_ON_MODERATION_ERROR,
		PUT_COMPETENCES_ON_MODERATION_SUCCESS,
		PUT_EDUCATION_ON_MODERATION,
		PUT_EDUCATION_ON_MODERATION_ERROR,
		PUT_EDUCATION_ON_MODERATION_SUCCESS,
		PUT_PERSONAL_ON_MODERATION,
		PUT_PERSONAL_ON_MODERATION_ERROR,
		PUT_PERSONAL_ON_MODERATION_SUCCESS,
		PUT_PHOTO_ON_MODERATION,
		PUT_PHOTO_ON_MODERATION_ERROR,
		PUT_PHOTO_ON_MODERATION_SUCCESS,
		PUT_REJECT_MODERATION,
		PUT_REJECT_MODERATION_ERROR,
		PUT_REJECT_MODERATION_SUCCESS,
		SET_EDITABLE_PAGE,
		PUT_PRACTICES_ON_MODERATION,
		PUT_PRACTICES_ON_MODERATION_SUCCESS,
		PUT_PRACTICES_ON_MODERATION_ERROR
	}
});

export const {reducer, actions} = slice;

export type TProfileState = {
	editor: {
		editablePage?: TProfilePage
	}
	topics?: TProfileTopicV1[]
	practices?: TProfileBioPractices
	moderation: {
		data?: TProfileOnModeration
	}
	loading: TProfileFetchTrigger | TProfileModerationTrigger | boolean
	success: TProfileFetchTrigger | TProfileModerationTrigger
	successResponse?: number
	error?: {service: TProfileFetchTrigger | TProfileModerationTrigger; code: number}
}
