import {AxiosResponse} from "axios";

import {BACKEND_URL} from "@/shared/config/constants";

import {apiClient} from "../../apiService";

import {
	IAuthRequestContract,
	IAuthResponseContract,
	IPatchPasswordChangeRequestContract,
	IPostEmailChangeRequestContract,
	IPostPasswordRecoveryRequestContract,
	IPostRefreshTokenResponseContract,
	IPutEmailChangeConfirmContract,
	IPutEmailSession,
	ISearchUserByEmailOrPhone,
	ISearchUserByEmailOrPhoneUnauthorized
} from "./contracts";

export const endpoint = `${BACKEND_URL}/auth/v1/email_sessions`;

export const createEmailSession = (email: string) =>
	apiClient.post(endpoint, {email});

export const commitEmailSession = (contract: IPutEmailSession["payload"]) => {
	const {sessionId, email, code, phone, name} = contract;
	return apiClient.put(`${endpoint}/${sessionId}`, {email, code, name, phone});
};

export const login = (contract: IAuthRequestContract): Promise<AxiosResponse<IAuthResponseContract>> => {
	const formData = new FormData();
	for (const key in contract) {
		formData.append(key, contract[key]);
	}
	return apiClient.post(`${BACKEND_URL}/auth/v1/sessions/password_session`, formData);
};

export const recoveryPassword = (
	contract: IPostPasswordRecoveryRequestContract
) =>
	apiClient.post(`${BACKEND_URL}/auth/v1/passwords/password_reset`, contract, {
		validateStatus: function(status) {
			// 204 код - это тоже ошибочный код: Пользователь с данным email не существует.
			return status === 200;
		}
	});

export const refreshToken = (): Promise<AxiosResponse<IPostRefreshTokenResponseContract>> => {
	const refreshToken: string = localStorage.getItem("refreshToken");
	return apiClient.post(`${BACKEND_URL}/auth/v1/refresh`, null, {
		headers: {
			"X-Refresh-Token": refreshToken
		}
	});
};

export const logout = (): Promise<AxiosResponse<void>> => {
	return apiClient.post(`${BACKEND_URL}/auth/v1/logout`, null);
};

export const changePassword = (contract: IPatchPasswordChangeRequestContract) =>
	apiClient.patch(`${BACKEND_URL}/auth/v1/users/${contract.psychologistId}/password`,
		contract.passwords);

export const emailChangeRequestApi = ({userId, email, role}: IPostEmailChangeRequestContract) => {
	return apiClient.post(`${BACKEND_URL}/auth/v1/emails/email_change`,
		{userId, email}
	);
};

export const emailChangeConfirmApi = ({userId, email, code}: IPutEmailChangeConfirmContract) => {
	return apiClient.put(`${BACKEND_URL}/auth/v1/emails/email_confirm`,
		{userId, email, code}
	);
};

export const checkEmailOrPhone = (contract: ISearchUserByEmailOrPhoneUnauthorized["payload"]) => {
	return apiClient.post<ISearchUserByEmailOrPhoneUnauthorized["response"]>(`${BACKEND_URL}/auth/v1/users/search`,
		contract
	);
};
