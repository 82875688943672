import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import {routePath} from "@/app/routes";
import {getQiwiStatePayoutsCount} from "@/shared/model/qiwi/selectors";
import {Block} from "@/shared/ui/uikit/Layout";

import {getIsShowMenu} from "../model/selectors";
import {actions} from "../model/slice";

interface TProps {
	className?: string
}

const ProfileNavigation = ({className}: TProps) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const uncheckedPayments = useSelector(getQiwiStatePayoutsCount);
	const isShow = useSelector(getIsShowMenu);
	const onToggleShow = () => {
		if (isShow) {
			dispatch(actions.toggleShow());
		}
	};

	return (
		<div className={`navigation ${className || ""}`}>
			<Link to={routePath.dashboard}
				onClick={onToggleShow}
				className={`navigation__link ${location.pathname === routePath.dashboard ? "navigation__link--active" : ""}`}
			>
        Календарь
			</Link>
			<Block className="navigation__link-wrapper">
				<Link to={routePath.payments}
					onClick={onToggleShow}
					className={`navigation__link ${location.pathname === routePath.payments ? "navigation__link--active" : ""}`}
				>
					Платежи
				</Link>
			</Block>
			<Block className="navigation__link-wrapper">
				<Link to={routePath.paymentHistory}
					onClick={onToggleShow}
					className={`navigation__link ${location.pathname === routePath.paymentHistory ? "navigation__link--active" : ""}`}
				>
					Мои выплаты
				</Link>
			</Block>
		</div>
	);
};

export default ProfileNavigation;
