import {createSlice, CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {RequestError} from "@/shared/types/error";
import * as types from "@/shared/types/auth";
import {PostCreateEmailSessionRequest} from "@/shared/api/v1/auth/contracts";

export interface IAuthState {
	loading: boolean
	error: RequestError
	emailLoading: boolean
	codeLoading: boolean
	requestError: RequestError | null
	requestErrorTimer: number | null
	passwordRecovery: {
		isLoading: boolean
		errors?: RequestError
	}
	passwordChange: {
		isLoading: boolean
		errors?: number
		success?: boolean
	}
	emailChange: {
		isLoading: boolean
		status?: "success" | "in_progress" | "error"
		errors?: RequestError
	}
	emailConfirm: {
		isOpen: boolean
	}
}

const initialState: IAuthState = {
	emailLoading     : false,
	codeLoading      : false,
	requestError     : null,
	requestErrorTimer: null,
	loading          : false,
	error            : null,
	passwordRecovery : {
		isLoading: false
	},
	passwordChange: {
		isLoading: false
	},
	emailChange: {
		isLoading: false
	},
	emailConfirm: {
		isOpen: false
	}
};

const submitEmail: CaseReducer<
IAuthState,
PayloadAction<PostCreateEmailSessionRequest>
> = () => ({
	...initialState,
	emailLoading: true
});

const submitEmailError: CaseReducer<IAuthState> = (state) => ({
	...state,
	emailLoading: false
});

const submitCode: CaseReducer<IAuthState, PayloadAction<types.TPutEmailSession>> = (state) => ({
	...state,
	codeLoading: true
});

const submitCodeError: CaseReducer<IAuthState> = (state) => ({
	...state,
	codeLoading: false
});

const processRequestError: CaseReducer<IAuthState, PayloadAction<RequestError>> = (
	state,
	action
) => ({
	...state,
	emailLoading: false,
	codeLoading : false,
	requestError: action.payload
});

const processRequestErrorTimer: CaseReducer<IAuthState, PayloadAction<number>> = (
	state,
	action
) => ({
	...state,
	requestErrorTimer: action.payload
});

const submitAuth: CaseReducer<
IAuthState,
PayloadAction<{ login: string; password: string }>
> = (state) => ({
	...state,
	loading: true,
	error  : null
});

const submitAuthSuccess: CaseReducer<IAuthState> = (state) => ({
	...state,
	loading: false,
	error  : null
});

const submitAuthFailed: CaseReducer<IAuthState, PayloadAction<RequestError>> = (
	state,
	action
) => ({
	...state,
	loading: false,
	error  : action.payload
});

const resetError: CaseReducer<IAuthState> = (state) => ({
	...state,
	error: null
});

const submitPasswordRecovery: CaseReducer<IAuthState, PayloadAction<string>> = (
	state
) => ({
	...state,
	passwordRecovery: {
		isLoading: true
	}
});

const submitPasswordRecoverySuccess: CaseReducer<IAuthState> = (state) => ({
	...state,
	passwordRecovery: {
		isLoading: false
	}
});

const submitPasswordRecoveryFailure: CaseReducer<
IAuthState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	passwordRecovery: {
		isLoading: false,
		errors   : action.payload
	}
});

const resetPasswordRecovery: CaseReducer<IAuthState> = (state) => ({
	...state,
	passwordRecovery: initialState.passwordRecovery
});

const PATCH_PASSWORD_CHANGE: CaseReducer<IAuthState, PayloadAction<types.PatchChangePasswordTAction["payload"]>> = (state) => ({
	...state,
	passwordChange: {
		...state.passwordChange,
		isLoading: true,
		errors   : undefined,
		success  : false
	}
});

const PATCH_PASSWORD_CHANGE_SUCCESS: CaseReducer<IAuthState> = (state) => ({
	...state,
	passwordChange: {
		...state.passwordChange,
		isLoading: false,
		success  : true
	}
});

const PATCH_PASSWORD_CHANGE_ERROR: CaseReducer<IAuthState, PayloadAction<number>> = (state, action) => ({
	...state,
	passwordChange: {
		...state.passwordChange,
		isLoading: false,
		errors   : action.payload
	}
});

const CLEAR_PASSWORD_CHANGE_STATE: CaseReducer<IAuthState, PayloadAction<RequestError>> = (state) => ({
	...state,
	passwordChange: {
		isLoading: false
	}
});

const POST_EMAIL_CHANGE_REQUEST: CaseReducer<IAuthState, PayloadAction<types.PostEmailChangeRequestTAction["payload"]>> = (state) => ({
	...state,
	emailChange: {
		isLoading: true
	}
});

const POST_EMAIL_CHANGE_REQUEST_SUCCESS: CaseReducer<IAuthState> = (state) => ({
	...state,
	emailChange: {
		isLoading: false,
		status   : "in_progress"
	}
});

const POST_EMAIL_CHANGE_REQUEST_ERROR: CaseReducer<IAuthState, PayloadAction<RequestError>> = (state, action) => ({
	...state,
	emailChange: {
		isLoading: false,
		status   : "error",
		errors   : {
			status : action.payload.status,
			message: action.payload.message
		}
	}
});

const PUT_EMAIL_CHANGE_CONFIRM: CaseReducer<IAuthState, PayloadAction<types.PutEmailChangeConfirmTAction["payload"]>> = (state) => ({
	...state,
	emailChange: {
		...state.emailChange,
		isLoading: true
	}
});

const PUT_EMAIL_CHANGE_CONFIRM_SUCCESS: CaseReducer<IAuthState> = (state) => ({
	...state,
	emailChange: {
		isLoading: false,
		status   : "success"
	}
});

const PUT_EMAIL_CHANGE_CONFIRM_ERROR: CaseReducer<IAuthState, PayloadAction<RequestError>> = (state, action) => ({
	...state,
	emailChange: {
		isLoading: false,
		status   : "error",
		errors   : {
			status : action.payload.status,
			message: action.payload.message
		}
	}
});

const CLEAR_EMAIL_CHANGE_STATE: CaseReducer<IAuthState> = (state) => ({
	...state,
	emailChange: {
		isLoading: false
	}
});

const SET_EMAIL_CONFIRM_MODAL_STATE: CaseReducer<IAuthState, PayloadAction<boolean>> = (state, action) => ({
	...state,
	emailConfirm: {
		isOpen: action.payload
	}
});

const slice = createSlice({
	name    : "auth",
	initialState,
	reducers: {
		submitEmail,
		submitEmailError,
		submitCode,
		submitCodeError,
		processRequestError,
		processRequestErrorTimer,
		submitAuth,
		submitAuthSuccess,
		submitAuthFailed,
		resetError,
		submitPasswordRecovery,
		submitPasswordRecoverySuccess,
		submitPasswordRecoveryFailure,
		resetPasswordRecovery,
		PATCH_PASSWORD_CHANGE,
		PATCH_PASSWORD_CHANGE_SUCCESS,
		PATCH_PASSWORD_CHANGE_ERROR,
		CLEAR_PASSWORD_CHANGE_STATE,
		POST_EMAIL_CHANGE_REQUEST,
		POST_EMAIL_CHANGE_REQUEST_SUCCESS,
		POST_EMAIL_CHANGE_REQUEST_ERROR,
		PUT_EMAIL_CHANGE_CONFIRM,
		PUT_EMAIL_CHANGE_CONFIRM_SUCCESS,
		PUT_EMAIL_CHANGE_CONFIRM_ERROR,
		CLEAR_EMAIL_CHANGE_STATE,
		SET_EMAIL_CONFIRM_MODAL_STATE
	}
});

export const {actions, reducer} = slice;
