import {createSelector} from "@reduxjs/toolkit";

import {RootState} from "@/app/model/reducer";
import * as types from "@/shared/types/onboarding";
import {CustomCheckboxTData} from "@/shared/ui/uikit/CustomCheckbox/custom-checkbox.types";
import {TCustomSelectListItem} from "@/shared/ui/uikit/CustomSelect/custom-select";

import serviceSelector from "../../../shared/model/services/selectors";

const GET_STATE = (state: RootState) => state.onboarding;
const GET_DATA = (state: RootState) => state.onboarding.data;
const GET_DATA_PERSONAL = (state: RootState) => state.onboarding.data.personal;
const GET_DATA_EDUCATION = (state: RootState) => state.onboarding.data.educations;
const GET_DATA_SUPERVISION = (state: RootState) => state.onboarding.data.supervision;
const GET_DATA_PRACTICES = (state: RootState) => state.onboarding.data.practices;
const GET_DATA_EXPERIENCE = (state: RootState) => state.onboarding.data.experience;
const GET_LOADING = (state: RootState) => state.onboarding.loading;
const GET_USER_STATUS = (state: RootState) => state.onboarding.data.personal.statusId;
const GET_CURRENTLY_EDITABLE_EDUCATION_INDEX = (state: RootState) => state.onboarding.editableEducation;
const GET_CURRENTLY_EDITING_PAGE = (state: RootState) => state.onboarding.editor.editablePage;
const GET_IS_DATA_EQUAL = (state: RootState) => state.onboarding.isDataNotEqual;

const CHECK_IS_DATA_EMPTY = createSelector(
	GET_DATA,
	GET_IS_DATA_EQUAL,
	(data, isDataNotEqual): boolean => {
		const currentData = Object.keys(data).filter((item) => item !== "information");
		const isDataEmpty = currentData.some((key) => data[key] === null);
		if (!isDataNotEqual && !isDataEmpty) {
			return false;
		}
		return true;
	}
);

const GET_QUESTIONARY_FORMED_PRACTICES = createSelector(
	serviceSelector.PSYCHOLOGIST.GET_ALL_PRACTICES,
	GET_DATA_PRACTICES,
	(allpractices, practices): CustomCheckboxTData[] => {
		const buffer: CustomCheckboxTData[] = [];
		allpractices?.forEach(practice => {
    	if (practices?.selectedPractices?.some(activePractice => Number(activePractice) === practice.id)) {
    		return buffer.push({value: practice.id, id: String(practice.id), label: practice.name, defaultChecked: true});
    	}
    	buffer.push({value: practice.id, id: String(practice.id), label: practice.name, defaultChecked: false});
		});
		return buffer;
	}
);

const GET_QUESTIONARY_SELECT_PRACTICES = createSelector(
	serviceSelector.PSYCHOLOGIST.GET_ALL_PRACTICES,
	(allpractices): TCustomSelectListItem[] => {
		const buffer: TCustomSelectListItem[] = [];
		allpractices?.forEach(practice => {
    	return buffer.push({value: practice.id, label: practice.name});
		});
		return buffer;
	}
);

const GET_QUESTIONARY_FORMED_DOCUMENT_TYPES = createSelector(
	serviceSelector.PSYCHOLOGIST.GET_ALL_DOCUMENT_TYPES,
	(documentTypes = []): TCustomSelectListItem[] => {
		return documentTypes?.map(type => {
			return {
				label: type.name, value: type.id
			};
		});
	}
);

const GET_QUESTIONARY_SETTINGS_SETTINGS_SELECTOR = createSelector(
	GET_DATA_PERSONAL,
	({phone, email, skype}): types.TQuestionarySettings => {
		return {phone, email, skype};
	}
);

const GET_DATA_MAIN_PRACTICE = createSelector(
	GET_DATA_PRACTICES,
	({mainPractice}): types.TOnboardingUserPractices["mainPractice"] => {
		return mainPractice || null;
	}
);

export default {
	GET_STATE,
	GET_DATA,
	GET_LOADING,
	GET_DATA_PERSONAL,
	GET_DATA_EDUCATION,
	GET_DATA_SUPERVISION,
	GET_DATA_PRACTICES,
	GET_DATA_EXPERIENCE,
	GET_USER_STATUS,
	GET_DATA_MAIN_PRACTICE,
	GET_CURRENTLY_EDITABLE_EDUCATION_INDEX,
	GET_CURRENTLY_EDITING_PAGE,
	GET_QUESTIONARY_FORMED_PRACTICES,
	GET_QUESTIONARY_FORMED_DOCUMENT_TYPES,
	GET_QUESTIONARY_SETTINGS_SETTINGS_SELECTOR,
	GET_QUESTIONARY_SELECT_PRACTICES,
	CHECK_IS_DATA_EMPTY,
	GET_IS_DATA_EQUAL
};
