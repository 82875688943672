import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {RootState} from "@/app/model/reducer";
import {routePath} from "@/app/routes";
import onboardingSelectors from "@/pages/Questionary/model/selectors";
import {actions} from "@/pages/Questionary/model/slice";
import {Block} from "@/shared/ui/uikit/Layout";
import {Spinner} from "@/shared/ui/uikit/Spinner";

import Header from "./Header";
import {MobileMenuContent} from "./MobileMenuContent";
import Questionary from "./Questionary";

import "./styles.scss";

interface TProps {
	children: React.ReactNode
	onLogout: () => void
}

const OnboardingBlock: React.FC<TProps> = ({children, onLogout}) => {
	const loading = useSelector(onboardingSelectors.GET_LOADING);
	const personal = useSelector(onboardingSelectors.GET_DATA_PERSONAL);
	const userFetched = useSelector((state: RootState) => state.onboarding.userFetched);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (!loading && !personal) {
			dispatch(actions.getUser());
		} else {
			localStorage.removeItem("email");
		}
	}, []);

	useEffect(() => {
		if (userFetched && !personal) {
			navigate(routePath.dashboard);
		}
	}, [userFetched]);

	return (
		<>
			{personal && !loading ? (
				<div className="dashboard">
					<Header onLogout={onLogout}>
						<Questionary />
					</Header>
					<Block className="dashboard__content">{children}</Block>
					<MobileMenuContent onLogout={onLogout}>
						<Questionary className="mobile-menu__links"/>
					</MobileMenuContent>
				</div>
			) : (
				<Spinner isBlue />
			)}
		</>
	);
};

export default React.memo(OnboardingBlock);
