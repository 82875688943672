import React from "react";

import "./form.scss";

interface Props {
	error: string
	multiline?: boolean
}

export const Input = (
	props: Props & React.PropsWithoutRef<JSX.IntrinsicElements["input"]>
) => {
	const {
		error,
		className,
		multiline = false,
		...propsWithoutClassName
	} = props;

	return (
		<>
			{!multiline && (
				<input className={`${
					error ? "form-input__error" : "form-input"
				} ${className}`}
           {...propsWithoutClassName}
				/>
			)}
			{multiline && (
				// @ts-ignore
				<textarea rows={3}
              className={`${
     	error ? "form-input__error" : "form-input"
              } ${className}`}
              {...propsWithoutClassName}
				/>
			)}
		</>
	);
};
