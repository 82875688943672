export const STORAGE_KEY = {
	APPOINTMENT_ID      : "appointment_id",
	ZIGMUND_ONLINE_TOKEN: "zigmundOnlineToken",
	LEAD_ID             : "leadId",
	ENTER_SOURCE        : "enter_source"
};

export const clearUserOnboardingDataLocalStorage = () => {
	localStorage.removeItem("tour");
	localStorage.removeItem("welcome");
	localStorage.removeItem("supervision");
	localStorage.removeItem("experience");
	localStorage.removeItem("practices");
	localStorage.removeItem("timeSlider");
	localStorage.removeItem("banner");
};

export const setAppointmentIdForConference = (appointment_id ?: number) => {
	if (!appointment_id) return;
	const appointmentId = JSON.stringify(appointment_id);
	 localStorage.setItem(STORAGE_KEY.APPOINTMENT_ID, appointmentId);
};

export const setEnterSourceVideoConf = () => {
	localStorage.setItem(STORAGE_KEY.ENTER_SOURCE, "psychologist");
};
