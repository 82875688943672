import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	TGetOnboardingPersonal,
	TGetOnboardingUserContract,
	TGetUserEducation,
	TGetUserExperience,
	TGetUserInformation,
	TGetUserPractices,
	TGetUserSupervision
} from "@/shared/api/v1/onboarding/contracts";
import {RequestError} from "@/shared/types/error";
import * as types from "@/shared/types/onboarding";

const initialState: IOnboardingState = {
	loading       : false,
	error         : null,
	isDataNotEqual: false,
	editor        : {
		editablePage: undefined
	},
	editableEducation: null,
	data             : {
		personal   : null,
  	practices  : null,
		educations : null,
		supervision: null,
		experience : null,
		information: null
	},
	userFetched: false
};

const setIsDataEqual: CaseReducer<
IOnboardingState,
PayloadAction<boolean>
> = (state, action) => ({
	...state,
	isDataNotEqual: action.payload
});

const getUser: CaseReducer<IOnboardingState> = (state) => ({
	...state,
	loading: true
});

const getUserSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetOnboardingUserContract["response"]>
> = (state, action) => ({
	...state,
	loading    : false,
	userFetched: true,
	data       : {
		personal   : action.payload.personal,
		supervision: action.payload.supervision,
		educations : action.payload.educations,
		experience : action.payload.experience,
		practices  : action.payload.practices,
		information: action.payload.information
	}
});

const getUserFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action.payload
});

const getUserPersonal: CaseReducer<IOnboardingState> = (state) => ({
	...state,
	loading: true
});

const getUserPersonalSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetOnboardingPersonal["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		personal: action.payload
	}
});

const getUserPersonalFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const SET_EDITABLE_PAGE: CaseReducer<IOnboardingState, PayloadAction<types.SetEditablePageTAction["payload"]>> = (state, action) => {
	window.scrollTo({top: 0});
	return {
		...state,
		editor: {
			editablePage: action.payload
		}
	};
};

const getUserSupervision: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: true

});

const getUserSupervisionSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetUserSupervision["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		supervision: action.payload
	}
});

const getUserSupervisionFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserSupervision: CaseReducer<
IOnboardingState,
PayloadAction<types.PutSupervisionOnModerationTAction["payload"]>
> = (state, action) => ({
	...state,
	loading: true
});

const putUserSupervisionSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const putUserSupervisionFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const postUserEducation: CaseReducer<
IOnboardingState,
PayloadAction<types.PostEducationOnModerationTAction["payload"]>
> = (state) => ({
	...state,
	loading: true
});

const postUserEducationSuccess: CaseReducer<
IOnboardingState
> = (state, action) => ({
	...state,
	loading: false
});

const postUserEducationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserEducation: CaseReducer<
IOnboardingState,
PayloadAction<types.PutEducationOnModerationTAction["payload"]>
> = (state) => ({
	...state,
	loading: true
});

const putUserEducationSuccess: CaseReducer<
IOnboardingState
> = (state, action) => ({
	...state,
	loading: false
});

const putUserEducationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const deleteUserEducation: CaseReducer<
IOnboardingState,
PayloadAction<types.DeleteEducationOnModerationTAction["payload"]>
> = (state) => ({
	...state,
	loading: true
});

const deleteUserEducationSuccess: CaseReducer<
IOnboardingState
> = (state, action) => ({
	...state,
	loading: false
});

const deleteUserEducationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const getUserEducation: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: true
});

const getUserEducationSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetUserEducation["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		education: action.payload
	}
});

const getUserEducationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const getUserExperience: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: true
});

const getUserExperienceSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetUserExperience["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		experience: action.payload
	}
});

const getUserExperienceFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserExperience: CaseReducer<
IOnboardingState,
PayloadAction<types.PutExperienceOnModerationTAction["payload"]>
> = (state, action) => ({
	...state,
	loading: true
});

const putUserExperienceSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const putUserExperienceFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const getUserPractices: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: true
});

const getUserPracticesSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetUserPractices["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		practices: action.payload
	}
});

const getUserPracticesFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserPractices: CaseReducer<
IOnboardingState,
PayloadAction<types.PutPracticesOnModerationTAction["payload"]>
> = (state, action) => ({
	...state,
	loading: true
});

const putUserPracticesSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const putUserPracticesFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const postUserPersonal: CaseReducer<
IOnboardingState,
PayloadAction<types.TPutUserPersonalOnModeration>
> = (state) => ({
	...state,
	loading: true
});

const postUserPersonalSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const postUserPersonalFailure: CaseReducer<IOnboardingState, PayloadAction<RequestError>> = (
	state, action
) => ({
	...state,
	loading: false,
	errors : action.payload
});

const updateUserPersonal: CaseReducer<
IOnboardingState,
PayloadAction<types.TPutUserPersonalOnModeration>
> = (state) => ({
	...state,
	loading: true
});

const updateUserPersonalSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const updateUserPersonalFailure: CaseReducer<IOnboardingState, PayloadAction<RequestError>> = (
	state, action
) => ({
	...state,
	loading: false,
	errors : action.payload
});

const PUT_EDITABLE_EDUCATION_INDEX: CaseReducer<IOnboardingState, PayloadAction<number>> = (state, action) => {
	return {
		...state,
		editableEducation:	action.payload
	};
};

const getUserInformation: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: true

});

const getUserInformationSuccess: CaseReducer<
IOnboardingState,
PayloadAction<TGetUserInformation["response"]>
> = (state, action) => ({
	...state,
	loading: false,
	data   : {
		...state.data,
		information: action.payload
	}
});

const getUserInformationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserInformation: CaseReducer<
IOnboardingState,
PayloadAction<types.PutUserInformationOnModerationTAction["payload"]>
> = (state, action) => ({
	...state,
	loading: true
});

const putUserInformationSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const putUserInformationFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const putUserSkype: CaseReducer<
IOnboardingState,
PayloadAction<string>
> = (state, action) => ({
	...state,
	loading: true
});

const putUserSkypeSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const putUserSkypeFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const changeUserEmail: CaseReducer<
IOnboardingState,
PayloadAction<string>
> = (state, action) => ({
	...state,
	loading: true
});

const changeUserEmailSuccess: CaseReducer<
IOnboardingState
> = (state) => ({
	...state,
	loading: false
});

const changeUserEmailFailure: CaseReducer<
IOnboardingState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	loading: false,
	error  : action?.payload
});

const slice = createSlice({
	name    : "onboarding",
	initialState,
	reducers: {
		setIsDataEqual,
		getUser,
		getUserSuccess,
		getUserFailure,
		postUserPersonal,
		postUserPersonalSuccess,
		postUserPersonalFailure,
		getUserPersonal,
		updateUserPersonal,
		updateUserPersonalSuccess,
		updateUserPersonalFailure,
		getUserPersonalSuccess,
		getUserPersonalFailure,
		getUserSupervision,
		getUserSupervisionSuccess,
		getUserSupervisionFailure,
		putUserSupervision,
		putUserSupervisionSuccess,
		putUserSupervisionFailure,
		postUserEducation,
		postUserEducationSuccess,
		postUserEducationFailure,
		putUserEducation,
		putUserEducationSuccess,
		putUserEducationFailure,
		deleteUserEducation,
		deleteUserEducationSuccess,
		deleteUserEducationFailure,
		getUserEducation,
		getUserEducationSuccess,
		getUserEducationFailure,
		getUserExperience,
		getUserExperienceSuccess,
		getUserExperienceFailure,
		putUserExperience,
		putUserExperienceSuccess,
		putUserExperienceFailure,
		getUserPractices,
		getUserPracticesSuccess,
		getUserPracticesFailure,
		putUserPractices,
		putUserPracticesSuccess,
		putUserPracticesFailure,
		getUserInformation,
		getUserInformationSuccess,
		getUserInformationFailure,
		putUserInformation,
		putUserInformationSuccess,
		putUserInformationFailure,
		putUserSkype,
		putUserSkypeSuccess,
		putUserSkypeFailure,
		changeUserEmail,
		changeUserEmailSuccess,
		changeUserEmailFailure,
		SET_EDITABLE_PAGE,
		PUT_EDITABLE_EDUCATION_INDEX
	}
});
export interface IOnboardingState {
	loading: boolean
	error: RequestError | null
	isDataNotEqual: boolean
	editor: {
		editablePage?: types.TQuestionaryPage
	}
	editableEducation?: number
	data: {
		personal: types.TOnboardingUserPersonal
  	practices: types.TOnboardingUserPractices
		educations: types.TOnboardingUserEducation[]
		supervision: types.TOnboardingUserSupervision
		experience: types.TOnboardingUserExperience
		information: types.TOnboardingUserInformation
	}
	userFetched: boolean
}

export const {reducer, actions} = slice;
