import {CaseReducer, combineReducers, createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as types from "@/shared/types/services";

export type TServicesState = {
	offer: {
		allOffers: types.TOffer["TGetAllPsychologistOffers"]["response"][]
		loading: boolean
		success?: boolean
		error?: string | number
		method?: types.TOffer["methods"]
	}
	psychologist: {
		allTopics: types.TPsychologist["TGetAllTopics"]["response"][]
		allPractices: types.TPsychologist["TGetAllPractices"]["response"][]
		allDocumentTypes: types.TPsychologist["TGetAllDocumentTypes"]["response"][]
		loading: boolean
		success?: boolean
		error?: string | number
		method?: types.TPsychologist["methods"]
	}
}

const offerState: TServicesState["offer"] = {
	allOffers: undefined,
	loading  : false
};

const psychologistState: TServicesState["psychologist"] = {
	allTopics       : undefined,
	allPractices    : undefined,
	allDocumentTypes: undefined,
	loading         : false
};

const GET_ALL_OFFERS: CaseReducer<TServicesState["offer"]> = (state) => ({
	...state,
	loading: true,
	error  : undefined,
	success: undefined,
	method : "GET_OFFERS"
});

const GET_ALL_OFFERS_SUCCESS: CaseReducer<TServicesState["offer"], PayloadAction<types.TOffer["TGetAllPsychologistOffers"]["response"][]>> = (state, action) => ({
	...state,
	allOffers: action.payload,
	loading  : false,
	method   : "GET_OFFERS",
	success  : true
});

const GET_ALL_OFFERS_ERROR: CaseReducer<TServicesState["offer"], PayloadAction<number>> = (state, action) => ({
	...state,
	loading: false,
	method : "GET_OFFERS",
	error  : action.payload
});

const POST_ACCEPT: CaseReducer<TServicesState["offer"], PayloadAction<types.TOffer["TPostPsychologistOfferAccept"]["payload"]>> = (state) => ({
	...state,
	loading: true,
	error  : undefined,
	success: undefined,
	method : "ACCEPT_OFFER"
});

const POST_ACCEPT_SUCCESS: CaseReducer<TServicesState["offer"]> = (state) => ({
	...state,
	loading: false,
	method : "ACCEPT_OFFER",
	success: true
});

const POST_ACCEPT_ERROR: CaseReducer<TServicesState["offer"], PayloadAction<number>> = (state, action) => ({
	...state,
	loading: false,
	method : "ACCEPT_OFFER",
	error  : action.payload
});

const OFFER = createSlice({
	name        : "OFFER",
	initialState: offerState,
	reducers    : {
		GET_ALL_OFFERS,
		GET_ALL_OFFERS_SUCCESS,
		GET_ALL_OFFERS_ERROR,
		POST_ACCEPT,
		POST_ACCEPT_SUCCESS,
		POST_ACCEPT_ERROR
	}
});

const GET_ALL_TOPICS: CaseReducer<TServicesState["psychologist"]> = (state) => ({
	...state,
	loading: true,
	error  : undefined,
	success: undefined,
	method : "GET_ALL_TOPICS"
});

const GET_ALL_TOPICS_SUCCESS: CaseReducer<TServicesState["psychologist"], PayloadAction<types.TPsychologist["TGetAllTopics"]["response"][]>> = (state, action) => ({
	...state,
	loading  : false,
	success  : true,
	method   : "GET_ALL_TOPICS",
	allTopics: action.payload
});

const GET_ALL_TOPICS_ERROR: CaseReducer<TServicesState["psychologist"], PayloadAction<number>> = (state, action) => ({
	...state,
	loading: false,
	method : "GET_ALL_TOPICS",
	error  : action.payload
});

const GET_ALL_PRACTICES: CaseReducer<TServicesState["psychologist"]> = (state) => ({
	...state,
	loading: true,
	error  : undefined,
	success: undefined,
	method : "GET_ALL_PRACTICES"
});

const GET_ALL_PRACTICES_SUCCESS: CaseReducer<TServicesState["psychologist"], PayloadAction<types.TPsychologist["TGetAllPractices"]["response"][]>> = (state, action) => ({
	...state,
	loading     : false,
	success     : true,
	method      : "GET_ALL_PRACTICES",
	allPractices: action.payload
});

const GET_ALL_PRACTICES_ERROR: CaseReducer<TServicesState["psychologist"], PayloadAction<number>> = (state, action) => ({
	...state,
	loading: false,
	method : "GET_ALL_PRACTICES",
	error  : action.payload
});

const GET_ALL_DOCUMENT_TYPES: CaseReducer<TServicesState["psychologist"]> = (state) => ({
	...state,
	loading: true,
	error  : undefined,
	success: undefined,
	method : "GET_ALL_DOCUMENT_TYPES"
});

const GET_ALL_DOCUMENT_TYPES_SUCCESS: CaseReducer<TServicesState["psychologist"], PayloadAction<types.TPsychologist["TGetAllDocumentTypes"]["response"][]>> = (state, action) => ({
	...state,
	loading         : false,
	success         : true,
	method          : "GET_ALL_DOCUMENT_TYPES",
	allDocumentTypes: action.payload
});

const GET_ALL_DOCUMENT_TYPES_ERROR: CaseReducer<TServicesState["psychologist"], PayloadAction<number>> = (state, action) => ({
	...state,
	loading: false,
	method : "GET_ALL_DOCUMENT_TYPES",
	error  : action.payload
});

const PSYCHOLOGIST = createSlice({
	name        : "PSYCHOLOGIST",
	initialState: psychologistState,
	reducers    : {
		GET_ALL_TOPICS,
		GET_ALL_TOPICS_SUCCESS,
		GET_ALL_TOPICS_ERROR,
		GET_ALL_PRACTICES,
		GET_ALL_PRACTICES_SUCCESS,
		GET_ALL_PRACTICES_ERROR,
		GET_ALL_DOCUMENT_TYPES,
		GET_ALL_DOCUMENT_TYPES_SUCCESS,
		GET_ALL_DOCUMENT_TYPES_ERROR
	}
});

export const reducer = combineReducers({
	psychologist: PSYCHOLOGIST.reducer,
	offer       : OFFER.reducer
});

export default {
	OFFER       : OFFER.actions,
	PSYCHOLOGIST: PSYCHOLOGIST.actions
};
