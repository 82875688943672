import {RootState} from "@/app/model/reducer";

const GET_ALL_OFFERS = (state: RootState) => state.services.offer.allOffers;
const GET_SUCCESS_STATE = (state: RootState) => state.services.offer.success;

const OFFER = {
	GET_ALL_OFFERS
};

const GET_ALL_TOPICS = (state: RootState) => state.services.psychologist.allTopics;
const GET_ALL_PRACTICES = (state: RootState) => state.services.psychologist.allPractices;
const GET_ALL_DOCUMENT_TYPES = (state: RootState) => state.services.psychologist.allDocumentTypes;

const PSYCHOLOGIST = {
	GET_ALL_TOPICS,
	GET_ALL_PRACTICES,
	GET_ALL_DOCUMENT_TYPES
};

export default {
	OFFER,
	PSYCHOLOGIST,
	GET_SUCCESS_STATE
};
