export function getPatchParams<T>(obj: T, keys: Array<keyof T>) {
	return keys.reduce((accumulator, key) => {
		if (obj[key]) {
			accumulator.push({
				op   : "replace",
				path : `/${key}`,
				value: obj[key]
			});
		}

		return accumulator;
	}, []);
}

export const getAxiosConfig = (config?: any) => ({
	headers: {
		Authorization: `Bearer ${localStorage.getItem("accessToken")}`
	},
	...config
});

/**
 * 1 - UNKNOWN
 * 2 - Сессия создано клиентом в АМ
 * 3 - Сессия создано клиентом в профиле РП при переходе из рассылки подборки на e-mail
 * 4 - Сессия создано клиентом в профиле РП (от саппорта)
 * 5 - Сессия создано саппортом в AW
 * 6 - Сессия создано клиентом в ЛКК
 * 7 - Сессия создано психологом в ЛКП
 */
export const detectOrderSource = () => {
	if (!window) return "?source=1";
	const url = window.location.href;
	if (url.includes("dashboard")) return "?source=7";
	return "?source=1";
};
