import React from "react";

import {useFeatureFlag} from "@/app/providers/feature-flags";
import {AppRoutes, publicRoutes} from "@/app/routes";
import {AB_Login} from "@/pages/Auth/ab/new_brand_identity/Login";

interface Props {
	path: AppRoutes
}

/**
 * Компонент для отображения других компонентов для роутов строго без авторизации.
 */
export const UnauthorizedRoute : React.FC<Props> = ({path}): React.ReactElement => {
	let Component = publicRoutes[path]?.component;

	const newBrandFlag = useFeatureFlag("new_brand_identity");
	if (newBrandFlag && path === "login") Component = AB_Login;

	return <Component/>;
};
