import React from "react";
import cls from "classnames";
import "./styles.scss";

type Props = {
	children: React.ReactNode
};

export const Column = ({
	children,
	className
}: Props &
React.PropsWithoutRef<JSX.IntrinsicElements["div"]>): React.ReactElement => {
	return <div className={cls("zg__column", className)}>{children}</div>;
};
