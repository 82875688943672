import React from "react";

import "./typography.scss";

type Variant = "h1" | "h2" | "h3" | "p" | "sm" | "xs";
type TextAlign = "center" | "left" | "right";
type Weight = "regular" | "medium" | "bold";

interface Props {
	variant?: Variant
	textAlign?: TextAlign
	weight?: Weight
	children: React.ReactNode
}

export const Typography = (props: Props): React.ReactElement => {
	const {
		children,
		variant = "p",
		textAlign = "left",
		weight
	} = props;

	switch (variant) {
	case "h1":
	case "h2":
	case "h3":
		return (
      <h1 className={`
          typography
          typography--${variant}
          typography--text-align-${textAlign}
          typography--${weight}
          `}
          >
        {children}
      </h1>
		);
	case "p":
	case "sm":
	case "xs":
		return (
      <p className={`
					typography
					typography--${variant}
					typography--text-align-${textAlign}
					typography--${weight}
				`}
         >
        {children}
      </p>
		);
	}
};
