import React from "react";

type Props = {
	url: string
};

const ProfilePhoto = ({url}: Props) => {
	return (
    <div className="profile-menu__photo">
      <img src={url} alt="" />
    </div>
	);
};

export default ProfilePhoto;
