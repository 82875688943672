import {TGetPsychologistContract} from "@/shared/api/v2/psychologist/contracts";
import {CoockieOptions} from "@/shared/types/cookies";

export const getCookie = (name: string) => {
	const matches = document.cookie.match(new RegExp(
		// eslint-disable-next-line no-useless-escape
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string | number, options: CoockieOptions = {}) => {
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (const optionKey in options) {
		updatedCookie += "; " + optionKey;
		const optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) => {
	setCookie(name, "", {
		maxAge: -1
	});
};

export const useUseDeskWidget = () => {
	// метод для отправки данных пользователя в useDesk вместе с тикетом
	// без ввода данных им самим.
	const identifyUserBeforeChat = (psychologist: TGetPsychologistContract["response"]) => {
		window.__widgetInitCallback = function (widget) {
			// @ts-ignore
			const expectedWidget = window.usedeskMessenger;
			const token = getCookie("useDeskToken");
			if (token) {
				expectedWidget.identify({
					token: token
				});
			} else {
				expectedWidget.identify({
					name : `${psychologist.firstName} ${psychologist.lastName}`,
					email: psychologist.email
				});
			}
		};
	};

	// метод для получения токена пользователя, чтобы сохранить его для
	// использования при инициализации, чтобы сохранить историю сообщений
	const identifyUser = () => {
		window.__usedeskWidgetInitedCallback = function() {
			// @ts-ignore
			const widget = window.usedeskMessenger;
			const token = widget.getChatToken();
			setCookie("useDeskToken", token);
		};
	};

	return {
		identifyUserBeforeChat,
		identifyUser
	};
};
