import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import {AppRoutes, privateRoutes} from "@/app/routes";
import {getCalendarTimestamp} from "@/shared/api/v1/calendar/requests";
import {getPsychologistProfile} from "@/shared/model/psychologist/selectors";
import PrivateLayout from "@/widgets/PrivateLayout";

import {useUseDeskWidget} from "./useUseDeskWidget";

export let currentUnixTimeFromServer;

interface Props {
	path: AppRoutes
}

export const PrivateRoute : React.FC<Props> = ({path}): React.ReactElement => {
	const psychologist = useSelector(getPsychologistProfile);
	const {identifyUserBeforeChat, identifyUser} = useUseDeskWidget();

	useEffect(() => {
		(async() => {
			const calendarTimestampResponse = await getCalendarTimestamp();
			currentUnixTimeFromServer = calendarTimestampResponse.data.unix_time;
		})();

		const intervalId = setInterval(() => {
			if (currentUnixTimeFromServer) {
				currentUnixTimeFromServer += 1;
			} else {
				currentUnixTimeFromServer = Math.floor(+new Date() / 1000);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (psychologist) {
			identifyUserBeforeChat(psychologist);
			identifyUser();
		}
	}, [psychologist]);

	const _renderRoute = () => {
		const Component = privateRoutes[path]?.component;

		return <Component/>;
	};

	return (
		<PrivateLayout>
			{_renderRoute()}
		</PrivateLayout>
	);
};
