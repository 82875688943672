import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {fromUnixTime} from "date-fns";
import getUnixTime from "date-fns/getUnixTime";
import {ru} from "date-fns/locale";
import format from "date-fns-tz/format";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";

import {routePath} from "@/app/routes";
import {handleClickConnectToConf} from "@/shared/lib/analytics/handleGTM";
import {calendarNearestEventSelector} from "@/shared/model/calendar/selectors";
import {setEnterSourceVideoConf, STORAGE_KEY} from "@/shared/model/localStorage/storage";
import {getPsychologistState} from "@/shared/model/psychologist/selectors";
import {Block} from "@/shared/ui/uikit/Layout";

import "./connect-conference-banner.scss";

interface TProps {
	sessionTime?: string
	children: React.ReactNode
	link?: string
	className?: string
}

const ConnectToConferenceBanner: React.FC<TProps> = ({children, className}) => {
	const [isBannerActive, setIsBannerActive] = useState(false);
	const appointment = useSelector(calendarNearestEventSelector("confirmed", "withInProgress"));
	const [isSessionInProgress, setIsSessionInProgress] = useState(false);
	const [localAppointment, setLocalAppointment] = useState(appointment);

	const {
		clients: {list}
	} = useSelector(getPsychologistState);

	useEffect(() => {
		const updatedAppointment = appointment;
		setLocalAppointment(updatedAppointment);
	}, [appointment]);

	const currentClient = localAppointment ? list?.find(
		(client) => client.clientId === localAppointment.attendeeId || null
	) : null;

	const appointmentDate = localAppointment ? {tsEnd: localAppointment.tsEnd, tsStart: localAppointment.tsStart} : null;

	useEffect(() => {
		const intervalId = setInterval(() => {
			const currentTimeUnixTime = getUnixTime(new Date());
			const appointmentStartTimeMinus5Min =  appointmentDate ? appointmentDate.tsStart - 300 : 0;

			if (appointmentDate && appointmentDate.tsStart <= currentTimeUnixTime && currentTimeUnixTime <= appointmentDate.tsEnd && appointment.type === "appointment") {
				setIsSessionInProgress(true);
			} else {
				setIsSessionInProgress(false);
			}

			if (appointmentDate &&
				appointmentStartTimeMinus5Min <= currentTimeUnixTime &&
				  currentTimeUnixTime <= appointmentDate.tsEnd && localAppointment.type === "appointment") {
				setIsBannerActive(true);
			} else if (isBannerActive) {
				setIsBannerActive(false);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [appointmentDate]);

	if (!isBannerActive || !localAppointment || !localAppointment?.appointmentId) {
		return null;
	}

	const options = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("zigmundOnline"))));
	const timeZone = options?.timezone || "Europe/Moscow";

	const nextAppointmentDate = format(utcToZonedTime(fromUnixTime(appointmentDate.tsStart).toISOString(), timeZone), "H:mm", {
		locale: ru,
		timeZone
	});

	const handleConnectToConference = () => {
		if (localAppointment?.appointmentId) {
			handleClickConnectToConf({event: "GA4_LKP_click_video_conf_banner", APT_ID: localAppointment?.appointmentId});
		}
		setEnterSourceVideoConf();
		window.open(`${routePath.conference}?${STORAGE_KEY.APPOINTMENT_ID}=${localAppointment?.appointmentId}`, "_blank");
	};

	let bannerText;

	if (currentClient && !isSessionInProgress) {
		bannerText = `Ваша сессия c ${currentClient.name} начнется в ${nextAppointmentDate}`;
	} else if (!isSessionInProgress) {
		bannerText = `Ваша сессия начнется в ${nextAppointmentDate}`;
	} else if (currentClient && isSessionInProgress) {
		bannerText = `У вас идет сессия c ${currentClient.name}`;
	} else {
		bannerText = "У вас идет сессия";
	}

	return <div className="welcome-connect-conference">
		<Block className={`welcome-connect-conference__banner ${className}`}>
			<p>{bannerText}</p>
			<span onClick={handleConnectToConference}>
				{children}
			</span>
		</Block>
	</div>;
};

ConnectToConferenceBanner.displayName = "ConnectToSessionBanner";

export default React.memo(ConnectToConferenceBanner);
