import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {getAxiosConfig, getPatchParams} from "../../utils";
import {
	ICreateReportRequestContract,
	ICreateReportResponseContract,
	IGetReportsRequestContract,
	IGetReportsResponseContract,
	IPatchReportRequestContract,
	IPatchReportResponseContract
} from "./contracts";

const URL = `${BACKEND_URL}/appointment-report/v1`;

export const getReports = (
	contract: IGetReportsRequestContract
): Promise<AxiosResponse<IGetReportsResponseContract>> => {
	const {psychologistId} = contract;
	return apiClient.get(
		`${URL}/reports?psychologistId=${psychologistId}`,
		getAxiosConfig()
	);
};

export const postReport = (
	contract: ICreateReportRequestContract
): Promise<AxiosResponse<ICreateReportResponseContract>> => {
	return apiClient.post(`${URL}/reports`, contract, getAxiosConfig());
};

export const patchReport = (
	contract: IPatchReportRequestContract
): Promise<AxiosResponse<IPatchReportResponseContract>> => {
	return apiClient.patch(
		`${URL}/reports/${contract.id}`,
		getPatchParams(contract, ["skipReason", "noNextAppointment"]),
		getAxiosConfig()
	);
};
