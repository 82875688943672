import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {getAxiosConfig} from "../../utils";
import {IPatchClientTagRequest, IPatchClientTagResponce} from "./contracts";

const URL = `${BACKEND_URL}/client/v1`;

/**
 * Для принятия оферты. Версия оферты может меняться.
 * @returns 200 - OK
 * @exception 400 - учётная запись клиента не найдена
 * @exception 403 - запрещено изменять тэг клиента (разрешено только админу, менеджеру, психологу - если он прикреплен к текущему клиенту)
 * @exception 500 - Internal server error
 */
export const patchClientTag = (
	contract: IPatchClientTagRequest
): Promise<AxiosResponse<IPatchClientTagResponce>> => {
	const {clientId, tag} = contract;

	return apiClient.patch(
		`${URL}/clients/${clientId}/tag`,
		{tag}
	);
};
