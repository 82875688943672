import React, {useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";

import {routePath} from "@/app/routes";
import {getAuthState} from "@/shared/model/auth/selectors";
import {actions} from "@/shared/model/auth/slice";
import WomanComputerIcon from "@/shared/ui/assets/emoji/womancomputer.png";
import logo from "@/shared/ui/assets/Icon/HeaderLogo.svg";
import {Button, FormField, FormGroup, Input} from "@/shared/ui/uikit/Form";
import {Block, Column, Content, Header, Row} from "@/shared/ui/uikit/Layout";
import {Color, Typography} from "@/shared/ui/uikit/Typography";
import PrivacyAndPolicy from "@/widgets/PrivacyAndPolicy";
import WelcomeBanner from "@/widgets/WelcomeBanner";

import styles from "./Auth.module.scss";

const validationSchema = yup.object().shape({
	login: yup
		.string()
		.email("Введите адрес электронной почты")
		.required("Поле обязательно для заполнения"),
	password: yup
		.string()
		.matches(
			/^(?=.*\d).{8,}$/,
			"Пароль должен содержать минимум одну цифру и быть длиной не менее 8 символов"
		)
		.required("Поле обязательно для заполнения")
});

export const Login = (): React.ReactElement => {
	const loading = false;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const localEmail = localStorage.getItem("email" || "");

	const onSubmit = ({login, password}) => {
		dispatch(actions.submitAuth({login, password})
		);
	};

	const {control, handleSubmit, errors} = useForm({
		resolver: yupResolver(validationSchema)
	});

	const disableSubmitButton = loading || errors.login || errors.password;
	const {error: requestError} = useSelector(getAuthState);

	const resetServerError = () => {
		if (requestError) {
			dispatch(actions.resetError());
		}
	};

	const onRecoveryPassword = () => {
		navigate(routePath.passwordRecovery);
	};

	const onRegister = () => {
		navigate(routePath.registration);
	};

	const renderAuthBanner = useMemo(() => {
		if (localEmail) {
			return (
				<Block className={styles.popupWrapper}>
					<WelcomeBanner className={styles.popup}
						title="Пароль для входа в личный кабинет отправлен на почту"
						emoji={WomanComputerIcon}
						variant="sm"
					/>
				</Block>
			);
		}
		return null;
	}, [localEmail]);

	return (
		<Content className={styles.auth}>
			<Header url="https://zigmund.online/" logoSrc={logo}/>
			{renderAuthBanner}
			<Column className={styles.content}>
				<Row>
					<Block className={styles.description}>
						<Typography variant="h2" weight="bold" textAlign="center">
							Войти как психолог
						</Typography>
					</Block>
				</Row>
				<Row>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup>
							<FormField>
								<Controller control={control}
									name="login"
									rules={{required: true}}
									render={({onChange, value}) => (
										<Input type="text"
											placeholder="Эл. почта"
											value={value}
											error={requestError?.message || errors.login}
											onChange={(value) => {
                      	resetServerError();
                      	onChange(value);
											}}
										/>
									)}
									defaultValue={localEmail || ""}
								/>
							</FormField>
							{errors.login && (
								<Typography variant="sm" textAlign="center">
									<Color color="error">{errors.login.message}</Color>
								</Typography>
							)}
							<Row p="small">
								<FormField>
									<Controller control={control}
										name="password"
										rules={{required: true}}
										render={({onChange, value}) => (
											<Input type="password"
												placeholder="Пароль"
												value={value}
												error={requestError?.message || errors.password}
												onChange={(value) => {
                        	resetServerError();
                        	onChange(value);
												}}
											/>
										)}
										defaultValue=""
									/>
									{errors.password && (
										<Typography variant="sm" textAlign="center">
											<Color color="error">{errors.password.message}</Color>
										</Typography>
									)}
									{requestError && (
										<Typography variant="sm" textAlign="center">
											<Color color="error">{requestError.message}</Color>
										</Typography>
									)}
								</FormField>
							</Row>
							<Row p="small">
								<FormField>
									<Button variant="link"
										color="primary"
										type="button"
										onClick={onRecoveryPassword}
									>
										<Typography>Забыли пароль?</Typography>
									</Button>
								</FormField>
							</Row>
							<Row p="small">
								<FormField>
									<Block className={styles.sendBtn}>
										<Button color="primary"
											disabled={disableSubmitButton}
											type="submit"
										>
											<Typography textAlign="center">Войти</Typography>
										</Button>
									</Block>
								</FormField>
							</Row>
							<FormField>
								<Block className={styles.registerLink}>
									<Button variant="link"
										color="primary"
										onClick={onRegister}
										newPage={true}
									>
										<Typography textAlign="center"

											weight="medium"
										>
                      Зарегистрироваться
										</Typography>
									</Button>
								</Block>
							</FormField>
						</FormGroup>
					</form>
				</Row>
			</Column>
			<Column className={styles.privacyPolicy}>
				<Row p="large">
					<PrivacyAndPolicy text="Отправляя адрес электронной почты" />
				</Row>
			</Column>
		</Content>
	);
};
