import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {
	IGetPayoutsRequestContract,
	IGetPayoutsResponseContract, TGetPsychologistPayoutsCount, TPatchSentCheckContract, TPutPsychologistPaymentsCheck
} from "./contracts";

const URL = `${BACKEND_URL}/qiwi/v1`;

export const getPayouts = (contract: IGetPayoutsRequestContract): Promise<AxiosResponse<IGetPayoutsResponseContract>> => {
	const {psychologistId, limit, offset} = contract;

	return apiClient.get(`${URL}/payouts?psychologistId=${psychologistId}&limit=${limit}&offset=${offset}`
	);
};

export const patchSentChecks = (contract: TPatchSentCheckContract["payload"]): Promise<AxiosResponse<TPatchSentCheckContract["response"]>> => {
	return apiClient.patch(`${URL}/sent_checks/${contract.eaAppointmentId}`,
		[{
			op   : "replace",
			path : "/isSent",
			value: contract.isCheckSent
		}]
	);
};

export const putPsychologistPaymentsCheckApi = (contract: TPutPsychologistPaymentsCheck) => {
	const {appointmentId, isSent, checkLink} = contract;
	return apiClient.put(
		`${URL}/sent_checks/${appointmentId}`,
		{isSent, checkLink}
	);
};

export const getPayoutscount = (contract: TGetPsychologistPayoutsCount["payload"]) => {
	const {psychologistId} = contract;

	return apiClient.get(`${URL}/payouts/${psychologistId}/count`
	);
};
