import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";

import {getAuthState} from "@/shared/model/auth/selectors";
import {actions} from "@/shared/model/auth/slice";
import logo from "@/shared/ui/assets/Icon/HeaderLogo.svg";
import {Button, FormField, FormGroup, Input} from "@/shared/ui/uikit/Form";
import {
	BackButton,
	Block,
	Column,
	Content,
	Header,
	Row
} from "@/shared/ui/uikit/Layout";
import {Color, Typography} from "@/shared/ui/uikit/Typography";
import PrivacyAndPolicy from "@/widgets/PrivacyAndPolicy";

import {useHappyFlow} from "../lib/useHappyFlow";

import styles from "./Auth.module.scss";

const validationSchema = yup.object().shape({
	email: yup
		.string()
		.email("Введите адрес электронной почты")
		.required("Поле обязательно для заполнения")
});

export const PasswordRecovery = (): React.ReactElement => {
	const [isSuccess, setIsSuccess] = useState(false);
	const {passwordRecovery} = useSelector(getAuthState);
	const dispatch = useDispatch();
	useHappyFlow(passwordRecovery, () => setIsSuccess(true));
	const submittedEmailRef = useRef("");

	const navigate = useNavigate();

	const onClickBackButton = () => navigate(-1);

	const onSubmit = ({email}) => {
		submittedEmailRef.current = email;
		dispatch(actions.submitPasswordRecovery(email));
	};

	const {control, handleSubmit, errors} = useForm({
		resolver: yupResolver(validationSchema)
	});

	const resetPasswordRecovery = () => {
		dispatch(actions.resetPasswordRecovery());
	};

	// @ts-ignore
	useEffect(() => {
		return () => dispatch(actions.resetPasswordRecovery());
	}, [dispatch]);

	return (
		<Content className={styles.auth}>
			<Header url="https://zigmund.online/" logoSrc={logo}/>
			<Column className={styles.content}>
				<Row>
					<BackButton onClick={onClickBackButton} />
				</Row>
				<Row p="small">
					<Block className={styles.description}>
						<Typography variant="h2" weight="bold">
              Восстановление пароля
						</Typography>
					</Block>
				</Row>
				{!isSuccess && (
					<Row>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormGroup>
								<FormField>
									<Controller control={control}
										name="email"
										rules={{required: true}}
										render={({onChange, value}) => (
											<Input type="text"
												name="email"
												placeholder="Эл. почта"
												value={value}
												disabled={passwordRecovery.isLoading}
												error={errors.email}
												onChange={(value) => {
                        	resetPasswordRecovery();
                        	onChange(value);
												}}
											/>
										)}
										defaultValue=""
									/>
								</FormField>
								{errors.email && (
									<Typography variant="sm" textAlign="center">
										<Color color="error">{errors.email.message}</Color>
									</Typography>
								)}
								<Row p="small">
									<FormField>
										<Block className={styles.send_btn}>
											<Button color="primary"
												isLoading={passwordRecovery.isLoading}
												spinnerSize="small"
												type="submit"
											>
												<Typography textAlign="center">Восстановить</Typography>
											</Button>
										</Block>
									</FormField>
								</Row>
								{passwordRecovery.errors?.status === 204 && (
									<Row p="none">
										<Typography variant="sm" textAlign="center">
											<Color color="error">
												{passwordRecovery.errors?.message}
											</Color>
										</Typography>
									</Row>
								)}
							</FormGroup>
						</form>
					</Row>
				)}
				{isSuccess && (
					<Row>
						<Typography>Мы выслали новый пароль на {submittedEmailRef.current}</Typography>
					</Row>
				)}
			</Column>
			<Column className={styles.privacy_policy}>
				<Row p="large">
					<PrivacyAndPolicy text="Отправляя адрес электронной почты" />
				</Row>
			</Column>
		</Content>
	);
};
