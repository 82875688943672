import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {CalendarEvent} from "@/shared/types/calendar";

interface IEventModalState {
	isShowing: boolean
	isBackdropShowing: boolean
	eventData: Partial<CalendarEvent> | null
	startDate: Date | null
	isNext: boolean
	conflictEvent: CalendarEvent
	eventTooltip: CalendarEvent
}

const initialState: IEventModalState = {
	isShowing        : false,
	isBackdropShowing: false,
	eventData        : null,
	startDate        : null,
	isNext           : false,
	conflictEvent    : null,
	eventTooltip     : null
};

interface ShowEventModalPayload {
	event: Partial<CalendarEvent>
	startDate: Date | null
	isNext?: boolean
}

const showEventModal: CaseReducer<
IEventModalState,
PayloadAction<ShowEventModalPayload>
> = (state, action) => ({
	...state,
	eventData        : {...state.eventData, ...action.payload.event},
	isBackdropShowing: true,
	startDate        : action.payload.startDate,
	isNext           : action.payload.isNext,
	isShowing        : true
});

const hideEventModal: CaseReducer<IEventModalState> = (state) => ({
	...state,
	isBackdropShowing: false,
	eventData        : null,
	isShowing        : false,
	isNext           : false,
	startDate        : null
});

const showBackdrop: CaseReducer<IEventModalState> = (state) => ({
	...state,
	isBackdropShowing: true
});

const hideBackdrop: CaseReducer<IEventModalState> = (state) => ({
	...state,
	isBackdropShowing: false
});

const showConflictEventModal: CaseReducer<
IEventModalState,
PayloadAction<CalendarEvent>
> = (state, action) => ({
	...state,
	conflictEvent: action.payload
});

const hideConflictEventModal: CaseReducer<IEventModalState> = (state) => ({
	...state,
	conflictEvent: null
});

const slice = createSlice({
	name    : "eventModal",
	initialState,
	reducers: {
		showEventModal,
		hideEventModal,
		showBackdrop,
		hideBackdrop,
		showConflictEventModal,
		hideConflictEventModal
	}
});

export const {actions, reducer} = slice;
