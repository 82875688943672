import React from "react";

import {Color} from "@/shared/ui/uikit/Typography";

import {IWelcomeBannerItem} from "./WelcomeBanner.types";

import "./welcome-banner-item.scss";

interface Props {
	item: IWelcomeBannerItem
}

const WelcomePopupItem: React.FC<Props> = ({item}) => {
	const {title, text} = item;

	return (
		<li className="welcome-banner-item">
			{
				title &&
				<h3 className="welcome-banner-item__title">
					<Color color="default">{title}</Color>
				</h3>
			}
			<p className="welcome-banner-item__text">
				<Color color="default">{text}</Color>
			</p>
		</li>
	);
};

export default React.memo(WelcomePopupItem);
