import React from "react";

import "./form-group.scss";

export const FormGroup = ({
	children
}: React.PropsWithoutRef<
JSX.IntrinsicElements["fieldset"]
>): React.ReactElement => {
	return <fieldset className="form-group">{children}</fieldset>;
};
