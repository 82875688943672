import {all} from "redux-saga/effects";

import reportsSaga from "@/pages/Dashboard/model/reports/saga";
import profileSaga from "@/pages/Profile/model/saga";
import onboardingSaga from "@/pages/Questionary/model/saga";
import authSaga from "@/shared/model/auth/saga";
import calendarSaga from "@/shared/model/calendar/saga";
import notificationSaga from "@/shared/model/notification/saga";
import psychologistSaga from "@/shared/model/psychologist/saga";
import qiwiSaga from "@/shared/model/qiwi/saga";
import servicesSaga from "@/shared/model/services/saga";

function* saga() {
	yield all([
		authSaga(),
		calendarSaga(),
		notificationSaga(),
		psychologistSaga(),
		reportsSaga(),
		qiwiSaga(),
		profileSaga(),
		onboardingSaga(),
		servicesSaga()
	]);
}

export default saga;
