import React from "react";
import cls from "classnames";
import "./styles.scss";

export type Padding = "small" | "medium" | "large" | "none";
type DisplayType = "flex";
type JustifyType =
  | "start"
  | "center"
  | "end"
  | "space-between"
  | "space-around"
  | "space-evenly";
type DirectionType = "row" | "column";
type AlignItemsType = "flex-start" | "center" | "flex-end" | "baseline";

type Props = {
	children?: React.ReactNode
	display?: DisplayType
	justify?: JustifyType
	direction?: DirectionType
	alignItems?: AlignItemsType
	p?: Padding
	className?: string
};

export const Row = ({
	children,
	justify,
	alignItems,
	display,
	direction,
	p = "medium",
	className
}: Props): React.ReactElement => {
	return (
    <div className={cls(
      	`zg__row zg__row_${p}`,
      	{
      		[display]                           : display,
      		[`flex__justify-content_${justify}`]: display === "flex" && justify,
      		[`flex__direction_${direction}`]    : display === "flex" && direction,
      		[`flex__align-items_${alignItems}`] : display === "flex" && alignItems
      	},
      	className
    )}
         >
      {children}
    </div>
	);
};
