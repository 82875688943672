import React from "react";
import ReactDOM from "react-dom";
import cls from "classnames";

import logo from "@/shared/ui/assets/Icon/HeaderLogo.svg";

import {Header} from "./Header";
import {MobileOnly} from "./MobileOnly";

import "./styles.scss";

export const ModalDialog = ({
	children,
	className,
	...divProps
}: React.PropsWithoutRef<JSX.IntrinsicElements["div"]>): React.ReactElement => {
	return ReactDOM.createPortal(
		<div className={cls("modal", "modal_open")} {...divProps}>
			<div className={cls("modal__content", className)}>
				<MobileOnly>
					<Header url="https://zigmund.online/" logoSrc={logo}/>
				</MobileOnly>
				{children}
			</div>
		</div>,
		document.body
	);
};
