import {createSlice, CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {RequestError} from "@/shared/types/error";

interface INotificationState {
	error: {
		key: number
		value: RequestError
	}
}

const initialState: INotificationState = {
	error: null
};

const notifyError: CaseReducer<
INotificationState,
PayloadAction<RequestError>
> = (state) => ({
	...state
});

const notifyErrorWithKey: CaseReducer<
INotificationState,
PayloadAction<{ key: number; value: RequestError }>
> = (state, action) => ({
	...state,
	error: action.payload
});

const clearError: CaseReducer<INotificationState, PayloadAction<number>> = (
	state,
	action
) => ({
	...state,
	error: state.error.key === action.payload ? null : state.error
});

const slice = createSlice({
	name    : "notification",
	initialState,
	reducers: {
		notifyError,
		notifyErrorWithKey,
		clearError
	}
});

export const {actions, reducer} = slice;
