import React from "react";
import "./spiner.scss";

/**
 * Тип для указания размера спиннера.
 */
export type SpinnerSize = "small" | "big" | "medium";

/**
 * @prop [className] Класс.
 * @prop [isBlue] Флаг отображения синего спиннера.
 * @prop [isInline] Флаг отображения в строке.
 * @prop [size] Размер.
 */
interface TProps {
	className?: string
	isBlue?: boolean
	isInline?: boolean
	size?: SpinnerSize
}

export const Spinner = ({
	className,
	isInline,
	isBlue,
	size = "big"
}: TProps) => {
	return (
		<div className={`loadSpinner ${isInline ? "inline" : ""} ${isBlue ? "blue" : ""} ${size} ${className}`}/>
	);
};
