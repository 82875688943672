import {all, delay, put, takeLatest} from "redux-saga/effects";
import {PayloadAction} from "@reduxjs/toolkit";
import {actions} from "@/shared/model/notification/slice";
import {RequestError} from "@/shared/types/error";

let keyCounter = 0;

function* notifyErrorSaga(action: PayloadAction<RequestError>) {
	const key = ++keyCounter;
	yield put(actions.notifyErrorWithKey({key, value: action.payload}));
	yield delay(3000);
	yield put(actions.clearError(key));
}

function* saga() {
	yield all([takeLatest(actions.notifyError, notifyErrorSaga)]);
}

export default saga;
