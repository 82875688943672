import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CSSTransition} from "react-transition-group";

import {Button} from "@/shared/ui/uikit/Form";
import {Color} from "@/shared/ui/uikit/Typography";

import {getIsShowMenu} from "../model/selectors";
import {actions} from "../model/slice";

interface TProps {
	onLogout: () => void
	children?: React.ReactNode
}

export const MobileMenuContent: React.FC<TProps> = ({onLogout, children}) => {
	const dispatch = useDispatch();
	const isShow = useSelector(getIsShowMenu);
	const onToggleShow = () => dispatch(actions.toggleShow());
	const onExit = () => {
		onToggleShow();
		onLogout();
	};

	return (
		<>
			{isShow && (
				<div className="mobile-menu__blanket" onClick={onToggleShow} />
			)}
			<CSSTransition in={isShow} timeout={250} classNames="mobile-menu__area">
				<div className="mobile-menu__area">
					{children}
					<div className="mobile-menu__area__bottom">
						<Button type="button"
							color="transparent"
							onClick={onExit}
							width="auto"
						>
							<Color color="not-active">Выйти</Color>
						</Button>
					</div>
				</div>
			</CSSTransition>
		</>
	);
};
