import {phone} from "phone";

import {checkEmailOrPhone} from "@/shared/api/v1/auth/requests";

export const data = [
	{value: "male", label: "Мужской"},
	{value: "female", label: "Женский"}
];

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export enum ErrorMessage {
	EMPTY_FIELD = "Не заполнено поле",
	INVALID_PHONE_NUMBER = "Введите корректный телефон",
	INVALID_EMAIL = "Введите корректный email",
	EMAIL_ALREADY_REGISTERED = "Этот email уже зарегистрирован",
	PHONE_NUMBER_ALREADY_REGISTERED = "Этот номер уже зарегистрирован",
}

export const VALID_RULES = {
	firstName: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	lastName: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	middleName: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	sex: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	nationality: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	country: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	city: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	},
	email: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		},
		pattern: {
			value  : EMAIL_PATTERN,
			message: ErrorMessage.INVALID_EMAIL
		},
		validate: {
			isBusy: async (value) => {
				const lowerCaseEmail = value.toLowerCase();
				const response = await checkEmailOrPhone({email: lowerCaseEmail});

				if (response.status === 204) return true;

				if (response.status === 200) {
					return ErrorMessage.EMAIL_ALREADY_REGISTERED;
				}
			}
		}
	},
	phone: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		},
		validate: {
			phone : (value) => phone(value.number, {country: value.countryCode}).isValid || ErrorMessage.INVALID_PHONE_NUMBER,
			isBusy: async (value) => {
				const response = await checkEmailOrPhone({phone: `+${value.number}`});

				if (response.status === 204) return true;

				if (response.status === 200) {
					return ErrorMessage.PHONE_NUMBER_ALREADY_REGISTERED;
				}
			}
		}
	},
	birthDate: {
		required: {
			value  : true,
			message: ErrorMessage.EMPTY_FIELD
		}
	}
};
