import React, {useEffect, useRef} from "react";
import {Provider} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";

import {NotFound} from "@/pages/NotFound";
import {GTM_ANALYTICS} from "@/shared/lib/analytics/gtmEvents";
import {handleGTMLocation} from "@/shared/lib/analytics/handleGTM";
import {Layout} from "@/shared/ui/uikit/Layout";
import {Spinner} from "@/shared/ui/uikit/Spinner/Spinner";
import {PrivateRoute, UnauthorizedRoute} from "@/widgets/Route";
import ServerErrorModal from "@/widgets/ServerErrorModal";
import {Tour} from "@/widgets/Tour";

import store from "./model/store";
import {privateZone, routePathWitParams, unauthorizedZone} from "./routes";

import "./style/index.scss";

const App = () => {
	const location = useLocation();
	const prevLocationRef = useRef("");

	useEffect(() => {
		if (prevLocationRef.current === location.pathname) return;

		const event = GTM_ANALYTICS.NAVIGATE_TO_NEW_PAGE;
		const title = document.title;
		const path = location.pathname;
		const referer = prevLocationRef.current;
		prevLocationRef.current = location.pathname;

		handleGTMLocation({event, title, path, referer});
	}, [location]);

	return (
		<Provider store={store}>
			<Tour.Wrapper>
				<Layout>
					<React.Suspense fallback={<Spinner isBlue />}>
						<ServerErrorModal />
						<Routes>
							{privateZone.map(path => (
								<Route key={path }
									path={routePathWitParams[path]}
									element= {<PrivateRoute path={path}/>}
								/>
							))}
							{unauthorizedZone.map(path => (
								<Route key={path}
									path={routePathWitParams[path]}
									element={<UnauthorizedRoute path={path}/>}
								/>
							))}
							<Route key={"*"}
								path={"*"}
								element={<NotFound />}
							/>
						</Routes>
					</React.Suspense>
				</Layout>
			</Tour.Wrapper>
		</Provider>
	);
};

export default App;
