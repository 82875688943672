import React from "react";
import * as Sentry from "@sentry/browser";

/**
 * Функция-обертка для lazy, которая пытается импортировать модуль несколько раз если модуль закешировался
 *
 * @param {Function} importer - функция импорта модуля
 * @return {LazyExoticComponent<ComponentType<any>>} Компонент React.lazy
 */
export const lazyWithRetries: typeof React.lazy = (importer) => {
	const retryImport = async () => {
		try {
			return await importer();
		} catch (error: any) {
			// const errorMessage = error.message || "";
			// const urlPattern = /Failed to fetch dynamically imported module:\s*(.*)/;
			// const match = errorMessage.match(urlPattern);

			// const originalUrl = match[1].trim();

			// for (let i = 0; i < 1; i++) {
			// 	await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
			// 	try {
			// 		const url = new URL(originalUrl);
			// 		url.searchParams.set("t", `${+new Date()}`);
			// 		return await import(/* @vite-ignore */ url.href);
			// 	} catch (e) {
			// 		console.log(`Retrying import attempt ${i + 1}`, e);
			// 	}
			// }

			// Sentry.captureMessage("All retry attempts failed. Reloading the page to load the latest version.");
			// console.log("All retry attempts failed. Reloading the page to load the latest version.");
			// window.location.reload();
			// throw error;
		}
	};

	return React.lazy(retryImport);
};
