import {createSelector} from "@reduxjs/toolkit";
import {getUnixTime} from "date-fns";
import { RootState } from "@/app/model/reducer";
import {CalendarEvent} from "@/shared/types/calendar";

export const getCalendarState = (state: RootState) => state.calendar;
export const getCalendarDataState = (state: RootState) =>
	state.calendar.calendar;
export const getEventsState = (state: RootState) => state.calendar.events;
export const getRecurrenceEventsState = (state: RootState) =>
	state.calendar.recurrenceEventInstances;

export type EventStatus = "confirmed" | "tentative" | "cancelled";

export const calendarNearestEventSelector = (status: EventStatus, type: "futureEvents" | "withInProgress" = "futureEvents") => createSelector(
	getEventsState,
	getRecurrenceEventsState,
	(events, recurrenceEvents) => {
		if (type === "withInProgress") {
			const event = getNearestEventIncludingAlreadyInProgress([...events.data, ...recurrenceEvents.data]);

			return event?.status === status ? event : null;
		}

		const event = getNearestFutureEvent([...events.data]);

		return event?.status === status ? event : null;
	}
);

export const getNearestFutureEvent = (events: CalendarEvent[]) => {
	const sortedEvents = [...events].sort((a, b) => a.tsStart - b.tsStart);
	const nowTs = getUnixTime(new Date());

	return (
		sortedEvents.find((event) => event.tsStart >= nowTs && event.status !== "cancelled") || null
	);
};

const hourInSec = 3600;

export const getNearestEventIncludingAlreadyInProgress = (events: CalendarEvent[]) => {
	const sortedEvents = [...events].sort((a, b) => a.tsStart - b.tsStart);
	const nowTs = getUnixTime(new Date());
	return (
		sortedEvents.find((event) => event.tsStart + hourInSec >= nowTs && event.status !== "cancelled" && event.appointmentId) || null
	);
};
