import {createSlice, CaseReducer} from "@reduxjs/toolkit";

interface INotificationState {
	isShow: boolean
}

const initialState: INotificationState = {
	isShow: false
};

const toggleShow: CaseReducer<INotificationState> = (state) => ({
	...state,
	isShow: !state.isShow
});

const slice = createSlice({
	name    : "menu",
	initialState,
	reducers: {
		toggleShow
	}
});

export const {actions, reducer} = slice;
