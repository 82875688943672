import {ShepherdOptionsWithType} from "react-shepherd";

export const TourSteps = [
	{
		id  : "1",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Добавьте несколько мест обучения</h3>
			<p class="tour-message__text">Укажите основные, достаточно десяти наименований.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"1\"]",
			on     : "bottom"
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				const element = document.querySelector("[data-tour='1']");
				setTimeout(function() {
					element.scrollIntoView({behavior: "smooth", block: "center"});
					resolve("");
				}, 1000);
			});
		},
		classes: "tour-step-1",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
				}
			}
		]
	},
	{
		id  : "2",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Можно добавить несколько мест обучения.</h3>
			<p class="tour-message__text">После сохранения появится кнопка “Добавить еще”. Укажите основные места обучения, достаточно десяти наименований.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		beforeShowPromise: function () {
			return new Promise(function (resolve) {
				setTimeout(function() {
					resolve("");
				}, 1000);
			});
		},
		attachTo: {
			element: "[data-tour=\"2\"]",
			on     : "top"
		},
		classes: "tour-step-2",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
				}
			}
		]
	},
	{
		id  : "3",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Обязательно добавьте высшее образование</h3>
			<p class="tour-message__text">Дипломы бакалавра, магистра, специалиста и пр.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"3\"]",
			on     : "bottom"
		},
		classes: "tour-step-3",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
				}
			}
		]
	},
	{
		id  : "4",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Дополнительное образование</h3>
			<p class="tour-message__text">Подтверждает обучение психотерапевтическому подходу, курсы, повышение квалификации и пр.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"4\"]",
			on     : "bottom"
		},
		classes: "tour-step-4",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
				}
			}
		]
	},
	{
		id  : "5",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Дополнительное образование</h3>
			<p class="tour-message__text">Подтверждает обучение психотерапевтическому подходу, курсы, повышение квалификации и пр.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"4\"]",
			on     : "top"
		},
		classes: "tour-step-4",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
				}
			}
		]
	},
	{
		id  : "6",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Приложите фото диплома</h3>
			<p class="tour-message__text">Необходимо подтвердить обучение документом.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"5\"]",
			on     : "bottom"
		},
		classes: "tour-step-5",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
					localStorage.setItem("tour", "done");
				}
			}
		]
	},
	{
		id  : "7",
		text: function() {
			return [`<div class="tour-message">
			<h3 class="tour-message__title">Приложите фото диплома</h3>
			<p class="tour-message__text">Необходимо подтвердить обучение документом.</p>
			<span class="tour-message__icon">🎓</span>
		</div>`];
		},
		attachTo: {
			element: "[data-tour=\"5\"]",
			on     : "top"
		},
		classes: "tour-step-5",
		buttons: [
			{
				text  : "×",
				action: function () {
					this.next();
					localStorage.setItem("tour", "done");
				}
			}
		]
	}
] as ShepherdOptionsWithType[];
