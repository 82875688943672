import {UnleashClient} from "unleash-proxy-client";

import {isDevelopment} from "@/shared/config/constants";

import type {FeatureFlagsAdapter, FeatureFlagsList} from "../ui/provider";

export const PROJECT_PREFIX = "zo.psy.";

// думаю нет смысла прятать ключи, т.к. Unleash их открыто отправляет на клиенте.
const devKey = "default:development.b494bfaf7c776f04abcb27f4c36506ed07d3b78f99a962fc3cf733a8";
const prodKey = "default:production.fb5fae9d324e4859235b474e5314d14421c08c2db2263df2a712a6c9";

const unleashInstance = new UnleashClient({
	url           : "https://unleash.zsvc.tech/api/frontend/",
	clientKey     : isDevelopment ? devKey : prodKey,
	appName       : "default",
	disableRefresh: true
});

const unleashAdapter: FeatureFlagsAdapter = {
	async getFlags() {
		try {
			/**
			 * Unleash не реджектит промис при ошибке,
			 * поэтому приходится явно выкидывать ошибку,
			 * иначе приложение так и будет вечно ждать промис.
			 */
			unleashInstance.once("error", (error) => {
				throw new Error(`Unleash error: ${error}`);
			});

			await unleashInstance.start();
			await unleashInstance.updateToggles();
			const toggles = unleashInstance.getAllToggles();

			return toggles.reduce((acc, {name, enabled}) => {
				if (name.startsWith(PROJECT_PREFIX)) {
					const formattedName = name.slice(PROJECT_PREFIX.length);
					return {...acc, [formattedName]: enabled};
				}

				return acc;
			}, {} as FeatureFlagsList);
		} catch (error) {
			console.error(error);
			return {};
		}
	},
	onFlagCheck(flag_name) {
		try {
			// кривой способ отправить аналитику о флаге
			unleashInstance.isEnabled(flag_name);
		} catch (error) {
			console.error(error);
		}
	}
};

export default unleashAdapter;
