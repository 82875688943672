import {AxiosResponse} from "axios";
import * as contracts from "../offer/contracts";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";

/**
 * Для принятия оферты. Версия оферты может меняться.
 * @returns 200 - OK - {@link TPostAcceptOfferContract}
 * @exception 400 - Bad request - {error: bad request}
 * @exception 409 - Conflict - {error: conflict} - уже подтверждена
 * @exception 500 - internal server error - {error: internal server error}
 */
export const postOfferAcceptApi = (contract: contracts.TPostAcceptOfferContract["payload"]): Promise<AxiosResponse> => {
	return apiClient.post(
		`${BACKEND_URL}/offer/v1/offers/accept`,
		contract
	);
};

/**
 * Для принятия оферты. Версия оферты может меняться.
 * @returns - 200 - {@link TGetAllPsychologistOffersContract.response}
 * @exception - 204 - отсутствуют действующие оферты
 * @exception 400 - Bad request - {error: bad request}
 * @exception 500 - internal server error - {error: internal server error}
 */
export const getAllPsychologistOffersApi = (
	contract: contracts.TGetAllPsychologistOffersContract["payload"]
): Promise<AxiosResponse<contracts.TGetAllPsychologistOffersContract["response"]>> => {
	return apiClient.get(
		`${BACKEND_URL}/offer/v1/offers?userId=${contract.userId}`
	);
};
