import React from "react";
import {ShepherdTour, Tour as TTour} from "react-shepherd";

import {TTourProps} from "./Tour.types";
import {TourSteps} from "./TourSteps";

import "./tour.scss";

const Tour: React.FC<TTourProps> = ({
	children
}) => {
	return (
	// @ts-ignore
    <ShepherdTour tourOptions={tourOptions} steps={TourSteps}>
      {children}
    </ShepherdTour>
	);
};

const tourOptions: TTour.TourOptions = {
	defaultStepOptions: {
		modalOverlayOpeningRadius : 5,
		modalOverlayOpeningPadding: 5,
		popperOptions             : {
			modifiers: [
				{name: "offset", options: {offset: [0, 20]}}
			] as TTour.TourOptions["defaultStepOptions"]["popperOptions"]
		},
		scrollTo: true,
		classes : "tour"
	},
	useModalOverlay: true
};

export default React.memo(Tour);
