import React from "react";

import {AppRoutes, routePath} from "@/app/routes";
import logo from "@/shared/ui/assets/Icon/HeaderLogo.svg";
import {Row} from "@/shared/ui/uikit/Layout";

import MobileMenu from "./MobileMenu";

interface TProps {
	children: React.ReactNode
	onLogout: () => void
}

const Header: React.FC<TProps> = ({children, onLogout}) => {
	return (
		<header className="dashboard__header">
			<Row p="none"
				className="dashboard__header-inner"
				display="flex"
				alignItems="center"
			>
				<div className="dashboard__header-logo">
					<MobileMenu />
					<a className="url" href={routePath[AppRoutes.DASHBOARD]}>
						<img src={logo} alt="zigmund-logo" />
					</a>
				</div>
				{children}
				<button className="dashboard__logout" onClick={onLogout}>Выйти</button>
			</Row>
		</header>
	);
};

export default React.memo(Header);
