import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {IGetInvoicesRequestContract, IGetInvoicesResponseContract, IGetPayoutHistoryRequestContract, IGetPayoutHistoryResponseContract} from "./contracts";

const URL = `${BACKEND_URL}/billing/v1/psychologist`;

export const getPayoutHistory = (contract: IGetPayoutHistoryRequestContract): Promise<AxiosResponse<IGetPayoutHistoryResponseContract>> => {
	const {psychologistId, page} = contract;

	return apiClient.get(`${URL}/payments?psychologistId=${psychologistId}&page=${page}&pageSize=10`
	);
};

export const getInvoices = (contract: IGetInvoicesRequestContract): Promise<AxiosResponse<IGetInvoicesResponseContract>> => {
	const {transactionId} = contract;

	return apiClient.get(`${URL}/payment?transactionId=${transactionId}`
	);
};
