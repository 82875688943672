import * as React from "react";

export type MemoChildrenProps = {
	shouldUpdate: boolean
	children: React.ReactNode
}

// eslint-disable-next-line react/display-name
export default React.memo(({children}: MemoChildrenProps) =>
	children as React.ReactElement, (_, {shouldUpdate}) => !shouldUpdate
);
