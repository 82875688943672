import React from "react";

import "./CustomButton.scss";

export const CustomButton: React.FC<TProps> = React.memo(({
	className = "",
	children,
	onClick,
	isDisabled,
	isLoading,
	type = "primary"
}) => {
	const props: Omit<TProps, "children"> = {
		className: _className(className, isDisabled, type),
		onClick  : isLoading || isDisabled ? null : onClick
	};

	return (
		<div {...props}>
			<div className={`custom-button__content ${isLoading ? "custom-button__content--loading" : ""}`}>
				{children}
			</div>
			{isLoading && _renderLoading()}
		</div>
	);
});

const _className = (className, isDisabled, type) => (
	`custom-button ${className}
    ${isDisabled ? "custom-button--disabled" : ""}
    custom-button--${type}`
);

const _renderLoading = () => {
	return (
		<div className="custom-button__loading">
			<span className="custom-button__icon custom-button__icon--loading"/>
		</div>
	);
};

interface TProps {
	className?: string
	children?: React.ReactNode
	onClick?: (e: any) => void
	isDisabled?: boolean
	isLoading?: boolean
	type?: "primary" | "secondary" | "success"
}

CustomButton.displayName = "CustomButton";
