import {unionBy} from "lodash";
import {createSlice, CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {
	IPsychologistClient,
	IPsychologistPaymentData,
	PsychologistCustomClient,
	TPsychologist
} from "@/shared/types/psychologist";
import {RequestError} from "@/shared/types/error";
import {IPutIntegrationStateRequestContract} from "@/shared/api/v1/calendar/contracts";
import {IPutIntegrationAuthRequest} from "@/shared/api/v1/google-calendar/contracts";
import {TProfilePatchSkypeOrTelegram} from "@/shared/types/profile";

import {IPaginationPageFilter} from "@/shared/types/pagination";
import { TransactionContract, InvoiceContract, TransactionContractList } from "@/pages/PaymentHistory/ui/PaymentHistoryTable/PaymentHistoryTable.types";

const updateTag = <T extends { list: IPsychologistClient[] }>(
	clientsState: T,
	{clientId, tag}: { clientId: number; tag: string }
): T => ({
		...clientsState,
		list: clientsState.list?.map((client) =>
			client.clientId === clientId ? {...client, tag} : client
		)
	});

const updateIntegration = (
	state: IPsychologistState,
	payload: IPutIntegrationStateRequestContract
) => {
	let integrations = state.profile.data.calendar.integrations || [];
	if (
		integrations.some((integration) => integration.id === payload.integrationId)
	) {
		integrations = integrations.map((integration) =>
			integration.id === payload.integrationId &&
      integration.account === payload.account
				? {
					...integration,
					isActive  : payload.isActive,
					isFree    : payload.isFree,
					isMyClient: payload.isMyClient,
					isOther   : payload.isOther
				}
				: integration
		);
	} else {
		integrations = [
			...integrations,
			{
				id        : payload.integrationId,
				account   : payload.account,
				isActive  : payload.isActive,
				isFree    : payload.isFree,
				isMyClient: payload.isMyClient,
				isOther   : payload.isOther
			}
		];
	}
	return {
		...state,
		putIntegrationState: {
			isLoading: false
		},
		profile: {
			...state.profile,
			data: {
				...state.profile.data,
				calendar: {
					...state.profile.data.calendar,
					integrations
				}
			}
		}
	};
};

interface IPsychologistState {
	profile: {
		data: TPsychologist
		loading: boolean
		errors?: RequestError
	}
	clients: {
		loading: boolean
		list: IPsychologistClient[]
	}
	recentClients: {
		isLoading: boolean
		list: IPsychologistClient[]
	}
	patchClientTag: {
		isLoading: boolean
		errors?: RequestError
	}
	putIntegrationState: {
		isLoading: boolean
		errors?: RequestError
	}
	putIntegrationAuth: {
		isLoading: boolean
		errors?: RequestError
	}
	customClients: {
		isLoading: boolean
		data: PsychologistCustomClient[]
		errors?: RequestError
	}
	putCustomClient: {
		isLoading: boolean
		data: null
		errors?: RequestError
	}
	deleteCustomClient: {
		isLoading: boolean
		data: null
		errors?: RequestError
	}
	paymentHistory: {
		totalPages: number
		list: TransactionContract[]
		isLoading: boolean
		errors?: RequestError
		isLoaded: boolean
		isEmpty: boolean | undefined
	}
	invoices: {
		list: InvoiceContract[]
		isLoading: boolean
	}
}

const initialState: IPsychologistState = {
	profile: {
		data   : null,
		loading: false
	},
	clients: {
		loading: false,
		list   : []
	},
	recentClients: {
		isLoading: false,
		list     : []
	},
	patchClientTag: {
		isLoading: false
	},
	putIntegrationState: {
		isLoading: false
	},
	putIntegrationAuth: {
		isLoading: false
	},
	customClients: {
		isLoading: false,
		data     : []
	},
	putCustomClient: {
		isLoading: false,
		data     : null
	},
	deleteCustomClient: {
		isLoading: false,
		data     : null
	},
	paymentHistory: {
		list      : [],
		isLoading : false,
		totalPages: 0,
		isLoaded  : false,
		isEmpty   : undefined
	},
	invoices: {
		list     : [],
		isLoading: false
	}
};

const getPsychologistClients: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	clients: {
		loading: true,
		list   : []
	}
});

const getPsychologistClientsSuccess: CaseReducer<
IPsychologistState,
PayloadAction<IPsychologistClient[]>
> = (state, action) => ({
	...state,
	clients: {
		loading: false,
		list   : action.payload
	}
});

const getPsychologistClientsFailure: CaseReducer<IPsychologistState> = (
	state
) => ({
	...state,
	clients: {
		loading: false,
		list   : []
	}
});

const getPsychologistRecentClients: CaseReducer<
IPsychologistState,
PayloadAction<{ sort: boolean; periodFrom: string; periodTo: string }>
> = (state) => ({
	...state,
	recentClients: {
		isLoading: true,
		list     : []
	}
});

const getPsychologistRecentClientsSuccess: CaseReducer<
IPsychologistState,
PayloadAction<IPsychologistClient[]>
> = (state, action) => ({
	...state,
	recentClients: {
		isLoading: false,
		list     : action.payload
	}
});

const getPsychologistRecentClientsFailure: CaseReducer<IPsychologistState> = (
	state
) => ({
	...state,
	recentClients: {
		isLoading: false,
		list     : []
	}
});

const addPsychologistClient: CaseReducer<
IPsychologistState,
PayloadAction<IPsychologistClient>
> = (state, action) => ({
	...state,
	clients: {
		...state.clients,
		list: unionBy([...state.clients.list, action.payload], "clientId")
	}
});

const getPsychologist: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: true
	}
});

const getPsychologistSuccess: CaseReducer<
IPsychologistState,
PayloadAction<TPsychologist>
> = (state, action) => ({
	...state,
	profile: {
		data   : action.payload,
		loading: false
	}
});

const getPsychologistFailure: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: false
	}
});

const changePsychologistAvailability: CaseReducer<
IPsychologistState,
PayloadAction<() => void>
> = (state) => ({
	...state
});

const changePsychologistAvailabilitySuccess: CaseReducer<
IPsychologistState,
PayloadAction<TPsychologist>
> = (state, action) => ({
	...state,
	profile: {
		data: {
			...state.profile.data,
			...action.payload
		},
		loading: false
	}
});

// const CHANGE_PSYCHOLOGIST_SKYPE: CaseReducer<
// IPsychologistState,
// PayloadAction<string>
// > = (state) => ({
// 	...state,
// 	profile: {
// 		...state.profile,
// 		loading: true
// 	}
// });

// const CHANGE_PSYCHOLOGIST_SKYPE_SUCCESS: CaseReducer<
// IPsychologistState,
// PayloadAction<string>
// > = (state) => ({
// 	...state,
// 	profile: {
// 		...state.profile,
// 		loading: false
// 	}
// });

const CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE: CaseReducer<
IPsychologistState,
PayloadAction<TProfilePatchSkypeOrTelegram>
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: true
	}
});

const CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_SUCCESS: CaseReducer<
IPsychologistState,
PayloadAction<string>
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: false
	}
});

const CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_FAILURE: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	profile: {
		...state.profile,
		loading: false,
		errors : action.payload
	}
});

const CHANGE_PSYCHOLOGIST_SKYPE_URL: CaseReducer<
IPsychologistState,
PayloadAction<string>
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: true
	}
});

const CHANGE_PSYCHOLOGIST_SKYPE_URL_SUCCESS: CaseReducer<
IPsychologistState,
PayloadAction<string>
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: false
	}
});

const CHANGE_PSYCHOLOGIST_SKYPE_URL_FAILURE: CaseReducer<
IPsychologistState
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: false
	}
});

const patchClientTag: CaseReducer<
IPsychologistState,
PayloadAction<{
	clientId: number
	tag: string
	onSuccess: () => void
	onError: () => void
}>
> = (state) => ({
	...state,
	patchClientTag: {
		...state.patchClientTag,
		isLoading: true
	}
});

const patchClientTagSuccess: CaseReducer<
IPsychologistState,
PayloadAction<{ clientId: number; tag: string }>
> = (state, action) => ({
	...state,
	patchClientTag: {
		isLoading: false
	},
	clients      : updateTag(state.clients, action.payload),
	recentClients: updateTag(state.recentClients, action.payload)
});

const patchClientTagFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	patchClientTag: {
		isLoading: false,
		errors   : action.payload
	}
});

const putIntegrationState: CaseReducer<
IPsychologistState,
PayloadAction<IPutIntegrationStateRequestContract>
> = (state) => ({
	...state,
	putIntegrationState: {
		...state.putIntegrationState,
		isLoading: true
	}
});

const putIntegrationStateSuccess: CaseReducer<
IPsychologistState,
PayloadAction<IPutIntegrationStateRequestContract>
> = (state, action) => updateIntegration(state, action.payload);

const putIntegrationStateFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	putIntegrationState: {
		isLoading: false,
		errors   : action.payload
	}
});

const putIntegrationAuth: CaseReducer<
IPsychologistState,
PayloadAction<IPutIntegrationAuthRequest & { onSuccess: () => void }>
> = (state) => ({
	...state,
	putIntegrationAuth: {
		...state.putIntegrationAuth,
		isLoading: true
	}
});

const putIntegrationAuthSuccess: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	putIntegrationAuth: {
		isLoading: false
	}
});

const putIntegrationAuthFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	putIntegrationAuth: {
		isLoading: false,
		errors   : action.payload
	}
});

const getCustomClients: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	customClients: {
		isLoading: true,
		data     : []
	}
});

const getCustomClientsSuccess: CaseReducer<
IPsychologistState,
PayloadAction<PsychologistCustomClient[]>
> = (state, action) => ({
	...state,
	customClients: {
		isLoading: false,
		data     : action.payload || []
	}
});

const getCustomClientsFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	customClients: {
		...state.customClients,
		isLoading: false,
		errors   : action.payload
	}
});

const putCustomClient: CaseReducer<
IPsychologistState,
PayloadAction<string>
> = (state) => ({
	...state,
	putCustomClient: {
		...state.putCustomClient,
		isLoading: true,
		errors   : null
	}
});

const putCustomClientSuccess: CaseReducer<
IPsychologistState,
PayloadAction<PsychologistCustomClient>
> = (state, action) => ({
	...state,
	putCustomClient: {
		...state.putCustomClient,
		isLoading: false,
		errors   : null
	},
	customClients: {
		...state.customClients,
		data: [...state.customClients.data, action.payload]
	}
});

const putCustomClientFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	putCustomClient: {
		...state.putCustomClient,
		isLoading: false,
		errors   : action.payload
	}
});

const deleteCustomClient: CaseReducer<
IPsychologistState,
PayloadAction<{ id: number; onSuccess: () => void }>
> = (state) => ({
	...state,
	deleteCustomClient: {
		...state.deleteCustomClient,
		isLoading: true,
		errors   : null
	}
});

const deleteCustomClientSuccess: CaseReducer<
IPsychologistState,
PayloadAction<number>
> = (state, action) => ({
	...state,
	deleteCustomClient: {
		...state.deleteCustomClient,
		isLoading: false,
		errors   : null
	},
	customClients: {
		...state.customClients,
		data: state.customClients.data?.filter(
			(client) => client.id !== action.payload
		)
	}
});

const deleteCustomClientFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	deleteCustomClient: {
		...state.deleteCustomClient,
		isLoading: false,
		errors   : action.payload
	}
});

const putPsychologistPaymentData: CaseReducer<
IPsychologistState,
PayloadAction<IPsychologistPaymentData>
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: true
	}
});

const putPsychologistPaymentDataSuccess: CaseReducer<
IPsychologistState
> = (state) => ({
	...state,
	profile: {
		...state.profile,
		loading: false
	}
});

const putPsychologistPaymentDataFailure: CaseReducer<
IPsychologistState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	profile: {
		...state.profile,
		isLoading: false,
		errors   : action.payload
	}
});

const getPsychologistPaymentHistory: CaseReducer<IPsychologistState, PayloadAction<IPaginationPageFilter>> = (state, action) => ({
	...state,
	paymentHistory: {
		totalPages: state.paymentHistory.totalPages,
		isLoading : true,
		list      : [],
		isLoaded  : false,
		isEmpty   : true
	}
});

const getPsychologistPaymentHistorySuccess: CaseReducer<
IPsychologistState,
PayloadAction<TransactionContractList>
> = (state, action) => ({
	...state,
	paymentHistory: {
		totalPages: action.payload.totalPages,
		isLoading : false,
		list      : action.payload.transactions,
		isLoaded  : true,
		isEmpty   : action.payload.transactions ? action.payload.transactions?.length < 1 : true
	}
});

const getPsychologistPaymentHistoryFailure: CaseReducer<IPsychologistState, PayloadAction<RequestError>> = (
	state, action
) => ({
	...state,
	paymentHistory: {
		totalPages: 0,
		isLoading : false,
		list      : [],
		errors    : action.payload,
		isLoaded  : true,
		isEmpty   : true
	}
});

const getPsychologistInvoices: CaseReducer<IPsychologistState> = (state) => ({
	...state,
	invoices: {
		isLoading: true,
		list     : []
	}
});

const getPsychologistInvoicesSuccess: CaseReducer<
IPsychologistState,
PayloadAction<InvoiceContract[]>
> = (state, action) => ({
	...state,
	invoices: {
		isLoading: false,
		list     : action.payload
	}
});

const getPsychologistInvoicesFailure: CaseReducer<IPsychologistState> = (
	state
) => ({
	...state,
	invoices: {
		isLoading: false,
		list     : []
	}
});

const slice = createSlice({
	initialState,
	name    : "psychologist",
	reducers: {
		getPsychologistRecentClients,
		getPsychologistRecentClientsSuccess,
		getPsychologistRecentClientsFailure,
		getPsychologistClients,
		getPsychologistClientsSuccess,
		getPsychologistClientsFailure,
		getPsychologist,
		getPsychologistSuccess,
		getPsychologistFailure,
		changePsychologistAvailability,
		changePsychologistAvailabilitySuccess,
		addPsychologistClient,
		// CHANGE_PSYCHOLOGIST_SKYPE,
		// CHANGE_PSYCHOLOGIST_SKYPE_SUCCESS,
		CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE,
		CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_SUCCESS,
		CHANGE_PSYCHOLOGIST_TELEGRAM_OR_SKYPE_FAILURE,
		CHANGE_PSYCHOLOGIST_SKYPE_URL,
		CHANGE_PSYCHOLOGIST_SKYPE_URL_SUCCESS,
		CHANGE_PSYCHOLOGIST_SKYPE_URL_FAILURE,
		patchClientTag,
		patchClientTagSuccess,
		patchClientTagFailure,
		putIntegrationState,
		putIntegrationStateSuccess,
		putIntegrationStateFailure,
		putIntegrationAuth,
		putIntegrationAuthSuccess,
		putIntegrationAuthFailure,
		getCustomClients,
		getCustomClientsSuccess,
		getCustomClientsFailure,
		putCustomClient,
		putCustomClientSuccess,
		putCustomClientFailure,
		deleteCustomClient,
		deleteCustomClientSuccess,
		deleteCustomClientFailure,
		putPsychologistPaymentData,
		putPsychologistPaymentDataSuccess,
		putPsychologistPaymentDataFailure,
		getPsychologistPaymentHistory,
		getPsychologistPaymentHistorySuccess,
		getPsychologistPaymentHistoryFailure
	}
});

export const {reducer, actions} = slice;
