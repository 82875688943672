import TagManager from "react-gtm-module";

export function handleGTM(events: string[]): void {
	for (let i = 0; i < events.length; i++) {
		TagManager.dataLayer({
			dataLayer: {
				event: events[i]
			},
			dataLayerName: "PageDataLayer"
		});
	}
}

export function handleGTMLocation(eventObject: {event: string; title: string; path: string; referer: string}) {
	TagManager.dataLayer({
		dataLayer    : eventObject,
		dataLayerName: "PageDataLayer"
	});
}

export function handleClickConnectToConf(eventObject: {event: string; APT_ID: number }) {
	TagManager.dataLayer({
		dataLayer    : eventObject,
		dataLayerName: "PageDataLayer"
	});
}
