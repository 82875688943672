import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {CSSTransition} from "react-transition-group";

import {CloseSvg} from "@/shared/ui/assets/svg";

import {CustomButton} from "../CustomButton/CustomButton";

import MemoChildren from "./MemoChildren";

import "./custom-popup.scss";

interface TProps {
	className?: string
	children: React.ReactNode
	isOpened?: boolean
	title?: string | number
	onClose: (args?: any) => void
	onCloseText?: string
	onSubmit?: (args?: any) => void
	onSubmitText?: string
	isLoading?: boolean
	isDisabled?: boolean
	closeOnClickAway?: boolean
	isCloseBtnVisible?: boolean
}

export const CustomPopup: React.FC<TProps> = React.memo(({
	className,
	children,
	isOpened,
	title,
	onClose,
	onCloseText,
	onSubmit,
	onSubmitText,
	isLoading,
	isDisabled,
	closeOnClickAway = true,
	isCloseBtnVisible = true
}) => {
	const outsideRef = useRef(null);
	useEffect(() => {
		const bodyStyle = document.querySelector("body").style;
		isOpened ? bodyStyle.overflow = "hidden" : bodyStyle.overflow = null;
		_initClickAway();
		return () => {
			bodyStyle.overflow = null;
			_cleanupClickAway();
		};
	}, [isOpened]);

	const _renderBody = () => {
		return (
			<div className="custom-popup__body">
				<MemoChildren shouldUpdate={isOpened}>
					{children}
				</MemoChildren>
			</div>
		);
	};

	const _renderPopup = () => {
		return (
			<div ref={outsideRef} className={`custom-popup ${className}`}>
				<div className="custom-popup__wrapper">
					{_renderHeader({isCloseBtnVisible, title, onClose, isLoading})}
					{_renderBody()}
					{_renderFooter({isLoading, isDisabled, onClose, onSubmit, onCloseText, onSubmitText})}
				</div>
			</div>
		);
	};

	const _makePortal = () => {
		return (
			<>
				{ReactDOM.createPortal(_renderPopup(), document && document.querySelector("body"))}
			</>
		);
	};

	return (
		<CSSTransition in={isOpened} timeout={200} classNames="custom-popup-" unmountOnExit>
			{_makePortal()}
		</CSSTransition>
	);

	function _cleanupClickAway() {
		window.removeEventListener("mousedown", _clickAway);
	}

	function _initClickAway() {
		window.addEventListener("mousedown", _clickAway);
	}

	function _clickAway(e) {
		if (outsideRef.current === e.target && closeOnClickAway) onClose();
	}
});
const _renderHeader = ({isCloseBtnVisible, title, onClose, isLoading}: Omit<TProps, "className" | "children">) => {
	return (
		<div className="custom-popup__header">
			{title && <h2 className="custom-popup__title">{title}</h2>}
			{isCloseBtnVisible && <CloseSvg className="custom-popup__icon custom-popup__icon--close" onClick={isLoading ? null : onClose}/>}
		</div>
	);
};

const _renderFooter = ({isLoading, isDisabled, onClose, onSubmit, onCloseText = "Закрыть", onSubmitText = "Готово"}: Omit<TProps, "className"| "children">) => {
	return (
		<div className="custom-popup__footer">
			{onClose && <CustomButton className="custom-popup__btn custom-popup__btn--close"
				onClick={onClose}
				type="secondary"
				isLoading={isLoading}
			>
				{onCloseText}
			</CustomButton>}
			{onSubmit && <CustomButton className="custom-popup__btn custom-popup__btn--ok"
				onClick={onSubmit}
				type="primary"
				isDisabled={isDisabled}
				isLoading={isLoading}
			>
				{onSubmitText}
			</CustomButton>}
		</div>
	);
};

CustomPopup.displayName = "CustomPopup";
