import React from "react";
import {useDispatch} from "react-redux";

import burger from "@/shared/ui/assets/Icon/Burger.svg";

import {actions} from "../model/slice";

const MobileMenu = () => {
	const dispatch = useDispatch();

	const onToggleShow = () => dispatch(actions.toggleShow());

	return (
		<div className="mobile-menu">
			<div className="mobile-menu__button">
				<img onClick={onToggleShow} src={burger} />
			</div>
		</div>
	);
};

export default MobileMenu;
