import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import * as contracts from "./contracts";

export const getPsychologist = (contract: contracts.TGetPsychologistContract["payload"]): Promise<AxiosResponse<contracts.TGetPsychologistContract["response"]>> => {
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v2/psychologists/${contract.userId}`
	);
};
