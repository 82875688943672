import React from "react";

import Telegram from "@/shared/ui/assets/Icon/Telegram.svg";
import {Button} from "@/shared/ui/uikit/Form";
import {Typography} from "@/shared/ui/uikit/Typography/Typography";

import {Block} from "./Block";

import "./styles.scss";

export const SupportButtons = (): React.ReactElement => {
	const goToTelegram = () => {
		window.open("https://t.me/ZigmundOnline_bot", "_blank");
	};

	return (
		<Button onClick={goToTelegram}>
			<Block className="supprot-buttons__btn">
				<Block className="support-buttons__telegram-btn__icon">
					<img src={Telegram} alt="telegram" />
				</Block>
				<Typography textAlign="center" variant="sm">
					Поддержка
				</Typography>
			</Block>
		</Button>
	);
};
