import {createSlice, CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {CalendarEvent} from "@/shared/types/calendar";

/**
 * @param [isShowing] флаг, открыта ли модалка
 * @param [reportId] id отчета, который сейчас просматриваем (нужен, чтобы сделать patch)
 * @param [eventData] событие, для которого заполняем отчет
 */
interface IReportModalState {
	isShowing: boolean
	reportId: number | null
	eventData: CalendarEvent | null
}

const initialState: IReportModalState = {
	isShowing: false,
	reportId : null,
	eventData: null
};

interface ShowReportModalPayload {
	event: CalendarEvent
}

const showReportModal: CaseReducer<
IReportModalState,
PayloadAction<ShowReportModalPayload>
> = (state, action) => ({
	...state,
	eventData: {...action.payload.event},
	reportId : action.payload.event?.reportId || null,
	isShowing: true
});

const addReportId: CaseReducer<IReportModalState, PayloadAction<number>> = (
	state,
	action
) => ({
	...state,
	reportId: action.payload
});

const hideReportModal: CaseReducer<IReportModalState> = (state) => ({
	...state,
	reportId : null,
	eventData: null,
	isShowing: false
});

const slice = createSlice({
	name    : "eventModal",
	initialState,
	reducers: {
		showReportModal,
		hideReportModal,
		addReportId
	}
});

export const {actions, reducer} = slice;
