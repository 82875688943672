import {useContext} from "react";

import {FeatureFlagsContext, FeatureFlagsContextProps, FeatureFlagsValue, flagsSourcesList} from "../ui/provider";

/**
 * Хук для проверки флага
 *
 * @param flag_name - Название флага, который нужно проверить.
 * @returns Значение флага
 */
export const useFeatureFlag = (flag_name: string): FeatureFlagsValue | null => {
	const context = useContext<FeatureFlagsContextProps | null>(FeatureFlagsContext);

	if (!context) {
		throw new Error("useFeatureFlag must be used within a FeatureFlagsProvider");
	}

	if (!flag_name.length) return null;

	const {flags} = context;
	const flagValue = flags?.[flag_name] ?? null;
	flagsSourcesList.forEach(source => source.onFlagCheck(flag_name));

	return flagValue;
};
