import React from "react";

import {Login, PasswordRecovery} from "@/pages/Auth";
import Registration from "@/pages/Registration";
import {lazyWithRetries} from "@/shared/lib/browser/lazyWithRetries";

const ProfileContainer = lazyWithRetries(() =>
	import("@/pages/Profile")
);

const QuestionaryContainer = lazyWithRetries(() =>
	import("@/pages/Questionary")
);

const DashboardContainer = lazyWithRetries(() =>
	import("@/pages/Dashboard/")
);

const PaymentsContainer = lazyWithRetries(() =>
	import("@/pages/Payments")
);

const PaymentHistoryContainer = lazyWithRetries(() =>
	import("@/pages/PaymentHistory")
);

const Root = lazyWithRetries(() =>
	import("@/pages/Root/Root")
);

export interface IRoute {
	path: string
	component: React.FC
	exact?: boolean
	routes?: IRoute[]
}

export const defaultRoute = "/psychologist-space";

export enum AppRoutes {
	DEFAULT = "default",
	LOGIN = "login",
	REGISTRATION = "registration",
	PASSWORDRECOVERY = "passwordRecovery",
	DASHBOARD = "dashboard",
	PROFILE = "profile",
	QUESTIONARY = "questionary",
	PAYMENTS = "payments",
	PAYMENT_HISTORY = "paymentHistory"
}

export const routePathWitParams = {
	[AppRoutes.DEFAULT]         : defaultRoute,
	[AppRoutes.LOGIN]           : `${defaultRoute}/login`,
	[AppRoutes.REGISTRATION]    : `${defaultRoute}/registration`,
	[AppRoutes.PASSWORDRECOVERY]: `${defaultRoute}/login/password-recovery`,
	[AppRoutes.DASHBOARD]       : `${defaultRoute}/dashboard`,
	[AppRoutes.PROFILE]         : `${defaultRoute}/dashboard/profile/:editor?/:subeditor?`,
	[AppRoutes.QUESTIONARY]     : `${defaultRoute}/questionary/:editor?`,
	[AppRoutes.PAYMENTS]        : `${defaultRoute}/dashboard/payments`,
	[AppRoutes.PAYMENT_HISTORY] : `${defaultRoute}/dashboard/payment-history`,
	conference                  : "/conf"
};

export const routePath = {
	[AppRoutes.DEFAULT]         : defaultRoute,
	[AppRoutes.LOGIN]           : `${defaultRoute}/login`,
	[AppRoutes.REGISTRATION]    : `${defaultRoute}/registration`,
	[AppRoutes.PASSWORDRECOVERY]: `${defaultRoute}/login/password-recovery`,
	[AppRoutes.DASHBOARD]       : `${defaultRoute}/dashboard`,
	[AppRoutes.PROFILE]         : `${defaultRoute}/dashboard/profile`,
	[AppRoutes.QUESTIONARY]     : `${defaultRoute}/questionary`,
	[AppRoutes.PAYMENTS]        : `${defaultRoute}/dashboard/payments`,
	[AppRoutes.PAYMENT_HISTORY] : `${defaultRoute}/dashboard/payment-history`,
	conference                  : "/conf"
};

export const privateZone = [AppRoutes.DASHBOARD, AppRoutes.PAYMENTS, AppRoutes.PROFILE, AppRoutes.QUESTIONARY, AppRoutes.PAYMENT_HISTORY];
export const unauthorizedZone = [AppRoutes.LOGIN, AppRoutes.PASSWORDRECOVERY, AppRoutes.REGISTRATION, AppRoutes.DEFAULT];

export const privateRoutes = {
	[AppRoutes.DASHBOARD]: {
		component: DashboardContainer
	},
	[AppRoutes.PAYMENTS]: {
		component: PaymentsContainer
	},
	[AppRoutes.PROFILE]: {
		component: ProfileContainer
	},
	[AppRoutes.QUESTIONARY]: {
		component: QuestionaryContainer
	},
	[AppRoutes.PAYMENT_HISTORY]: {
		component: PaymentHistoryContainer
	}
};

export const publicRoutes = {
	[AppRoutes.DEFAULT]: {
		component: Root
	},
	[AppRoutes.LOGIN]: {
		component: Login
	},
	[AppRoutes.REGISTRATION]: {
		component: Registration
	},
	[AppRoutes.PASSWORDRECOVERY]: {
		component: PasswordRecovery
	}
};
