import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {all, call, put, takeLatest} from "redux-saga/effects";

import {sendNewSmsCode} from "@/shared/model/auth/actions";
import {actions as authActions} from "@/shared/model/auth/slice";
import {actions as notificationActions} from "@/shared/model/notification/slice";
import {actions as psychologistActions} from "@/shared/model/psychologist/slice";
import {routePath} from "@/app/routes";
import {PostCreateEmailSessionRequest} from "@/shared/api/v1/auth/contracts";
import {
	changePassword,
	commitEmailSession,
	createEmailSession,
	emailChangeConfirmApi,
	emailChangeRequestApi,
	login,
	recoveryPassword
} from "@/shared/api/v1/auth/requests";
import {getPsychologist} from "@/shared/api/v2/psychologist/requests";
import * as types from "@/shared/types/auth";
import {TPutEmailSession} from "@/shared/types/auth";

export const parseError = (error: Error | AxiosError) => {
	if ("response" in error && error.response) {
		const status = error.response.status;
		const message = error.response.data.message;

		return {status, message};
	}

	return {
		status : undefined,
		message: error.message
	};
};

function* loginSaga(
	action: PayloadAction<{
		login: string
		password: string
	}>
) {
	try {
		const response = yield call(login, action.payload);
		const {status, data} = response;
		if (status === 204) {
			yield put(
				authActions.submitAuthFailed({
					status,
					message: "Пользователь не найден"
				})
			);
		} else {
			const {userID, accessToken, refreshToken} = data;
			const userId = Number(userID);
			localStorage.setItem("userId", userID);
			localStorage.setItem("accessToken", accessToken);
			localStorage.setItem("refreshToken", refreshToken);
			const psychologist = yield call(getPsychologist, {userId});
			if (psychologist.status === 200) {
				window.location.href = routePath.dashboard;
				yield put(authActions.submitAuthSuccess());
			} else if (psychologist.status === 204) {
				window.location.href = routePath.questionary;
				yield put(authActions.submitAuthSuccess());
				localStorage.removeItem("email");
			}
		}
	} catch (error) {
		const axiosError = error as AxiosError;
		if (!error.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		const status = axiosError.response.status;
		let message: string;

		switch (status) {
			case 400:
				message = "Неправильный формат запроса";
				break;
			case 401:
				message = "Неправильный логин или пароль";
				break;
			case 500:
				message = "Внутренняя ошибка сервера";
				break;
			default:
				message = "Неизвестная ошибка сервера";
		}

		yield put(
			authActions.submitAuthFailed({
				status,
				message
			})
		);
	}
}

function* recoveryPasswordSaga(action: PayloadAction<string>) {
	try {
		yield call(recoveryPassword, {email: action.payload});
		yield put(authActions.submitPasswordRecoverySuccess());
	} catch (error) {
		const axiosError = error as AxiosError;

		const status = axiosError.response.status;
		let message: string;

		switch (status) {
			case 204:
				message = "Пользователь с данным email не существует";
				break;
			case 500:
				message = "Внутренняя ошибка сервера";
				yield put(
					notificationActions.notifyError({
						status,
						message
					})
				);
				break;
			default:
				message = "Неизвестная ошибка сервера";
				yield put(
					notificationActions.notifyError({
						status,
						message
					})
				);
		}

		yield put(
			authActions.submitPasswordRecoveryFailure({
				status,
				message
			})
		);
	}
}

function* callPatchPasswordChangeSaga({payload}: types.PatchChangePasswordTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(changePassword, {psychologistId, passwords: payload});
		yield put(authActions.PATCH_PASSWORD_CHANGE_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(authActions.PATCH_PASSWORD_CHANGE_ERROR(err.response.status));
	}
}

function* callPostEmailChangeRequestSaga({payload}: types.PostEmailChangeRequestTAction) {
	try {
		const userId = +localStorage.getItem("userId");
		yield call(emailChangeRequestApi, {userId, email: payload.email, role: payload.role});
		yield put(authActions.POST_EMAIL_CHANGE_REQUEST_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(authActions.POST_EMAIL_CHANGE_REQUEST_ERROR({status: err.response.status, message: err.response.data}));
	}
}

function* callPutEmailChangeConfirmSaga({payload}: types.PutEmailChangeConfirmTAction) {
	try {
		const userId = +localStorage.getItem("userId");
		yield call(emailChangeConfirmApi, {userId, email: payload.email, code: payload.code});
		yield put(authActions.PUT_EMAIL_CHANGE_CONFIRM_SUCCESS());
		yield put(psychologistActions.getPsychologist());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(authActions.PUT_EMAIL_CHANGE_CONFIRM_ERROR({status: err.response.status, message: err.response.data}));
	}
}

function* startConfirmEmailSessionSaga(
	action: PayloadAction<PostCreateEmailSessionRequest>
) {
	try {
		const {email} = action.payload;
		const response = yield call(createEmailSession, email);
		if (response.status === 200) {
			yield put(authActions.SET_EMAIL_CONFIRM_MODAL_STATE(true));
		}
		localStorage.setItem(
			"session",
			JSON.stringify({
				email,
				sessionId: response.data.sessionId
			})
		);
	} catch (error) {
		const {status} = parseError(error);
		if (status === 429) {
			yield put(authActions.processRequestError({status, message: "Вы исчерпали свои попытки зарегистрироваться, попробуйте снова через 10 минут"}));
			yield put(authActions.processRequestErrorTimer(25));
		} else if (status === 204) {
			yield put(authActions.processRequestError({status, message: "Вы еще не зарегистрированы, нажмите на кнопку “Я первый раз в сервисе”"}));
		} else if (status === 400) {
			yield put(authActions.processRequestError({status, message: "Данные введены некорректно"}));
		} else if (status === 403) {
			yield put(authActions.processRequestError({status, message: "Ошибка капчи. Пожалуйста, напишите нам."}));
		} else {
			yield put(authActions.processRequestError({status, message: "Неизвестная ошибка. Пожалуйста, напишите нам."}));
		}
		yield put(authActions.submitEmailError());
	}
}

function* commitEmailSessionCodeSaga(action: PayloadAction<TPutEmailSession>) {
	try {
		const {phone, name} = action.payload;
		const code = parseInt(action.payload.code, 10);
		const sessionData = localStorage.getItem("session");
		const session: types.IOnboardingAuthSession = JSON.parse(sessionData || "{}");
		const {sessionId, email} = session;
		const response = yield call(
			commitEmailSession,
			{
				email,
				code,
				name,
				phone,
				sessionId
			}
		);

		if (response.status === 204) {
			yield put(
				authActions.processRequestError({
					status : response.status,
					message: "Упс! Код неправильный"
				})
			);
		}

		if (response.status === 200) {
			const {userId, accessToken, refreshToken} = response.data;
			localStorage.setItem("userId", userId);
			localStorage.setItem("accessToken", accessToken);
			localStorage.setItem("refreshToken", refreshToken);
			yield put(authActions.processRequestError(null));
		}
	} catch (error) {
		const axiosError = error as AxiosError;
		if (axiosError.response?.status === 429) {
			yield put(
				authActions.processRequestError({
					status : axiosError.response?.status,
					message:
            "Вы исчерпали свои попытки авторизоваться, попробуйте снова через 10 минут"
				})
			);
		} else {
			yield put(
				authActions.processRequestError({
					status : axiosError.response?.status,
					message: "Ошибка сервера. Напишите нам, мы всё починим."
				})
			);
		}
	}
}

function* sendNewSmsCodeSaga() {
	try {
		const sessionData = localStorage.getItem("session");
		const session = JSON.parse(sessionData || "{}");

		const response = yield call(createEmailSession, session.email);

		yield call(
			[localStorage, "setItem"],
			"session",
			JSON.stringify({
				...session,
				sessionId: response.data.sessionId
			})
		);
	} catch (error) {
		const axiosError = error as AxiosError;
		if (axiosError.response?.status === 429) {
			yield put(
				authActions.processRequestError({
					status : axiosError.response?.status,
					message:
            "Вы исчерпали свои попытки авторизоваться, попробуйте снова через 10 минут"
				})
			);
		} else {
			yield put(
				authActions.processRequestError({
					status : axiosError.response?.status,
					message: "Ошибка сервера. Напишите нам, мы всё починим."
				})
			);
		}
	}
}

function* saga() {
	yield all([
		takeLatest(authActions.submitEmail, startConfirmEmailSessionSaga),
		takeLatest(authActions.submitCode, commitEmailSessionCodeSaga),
		takeLatest(sendNewSmsCode, sendNewSmsCodeSaga),
		takeLatest(authActions.submitAuth, loginSaga),
		takeLatest(authActions.submitPasswordRecovery, recoveryPasswordSaga),
		takeLatest(authActions.PATCH_PASSWORD_CHANGE, callPatchPasswordChangeSaga),
		takeLatest(authActions.POST_EMAIL_CHANGE_REQUEST, callPostEmailChangeRequestSaga),
		takeLatest(authActions.PUT_EMAIL_CHANGE_CONFIRM, callPutEmailChangeConfirmSaga)
	]);
}

export default saga;
