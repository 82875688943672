import {useEffect, useState} from "react";
import {usePrevious} from "react-use";
import {RequestError} from "@/shared/types/error";

export function useHappyFlow(
	asyncData: { isLoading: boolean; errors?: RequestError },
	onSuccess: () => void
) {
	const [isSuccess, setIsSuccess] = useState(false);
	const prevAsyncData = usePrevious(asyncData);

	if (prevAsyncData?.isLoading && !asyncData.isLoading && !asyncData.errors) {
		!isSuccess && setIsSuccess(true);
	}
	if (asyncData.isLoading && !prevAsyncData?.isLoading) {
		isSuccess && setIsSuccess(false);
	}

	useEffect(() => {
		// Гарантированный вызов один раз после загрузки данных.
		isSuccess && onSuccess();
	}, [isSuccess, onSuccess]);
}
