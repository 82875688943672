import {createSlice, CaseReducer} from "@reduxjs/toolkit";

interface IErrorModalState {
	isShowingErrorModal: boolean
}

const initialState: IErrorModalState = {
	isShowingErrorModal: false
};

const showErrorModal: CaseReducer<IErrorModalState> = (state) => ({
	...state,
	isShowingErrorModal: true
});

const hideErrorModal: CaseReducer<IErrorModalState> = (state) => ({
	...state,
	isShowingErrorModal: false
});

const slice = createSlice({
	name    : "errorModal",
	initialState,
	reducers: {
		showErrorModal,
		hideErrorModal
	}
});

export const {actions, reducer} = slice;
