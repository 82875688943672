import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {BACKEND_URL} from "@/shared/config/constants";
import {getAxiosConfig, getPatchParams} from "../../utils";
import {TProfileOnModeration} from "@/shared/types/profile";
import * as contracts from "./contracts";

export const getPsychologistClientsApi = (
	contract: contracts.IGetPsychologistClientsRequestContract
) => {
	const {psychologistId} = contract;

	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/psychologists/${psychologistId}/clients`,
		getAxiosConfig({
			validateStatus: function (status) {
				// 204 код - это тоже ошибочный код.
				return status === 200;
			}
		})
	);
};

export const getPsychologistRecentClientsApi = (
	contract: contracts.IGetPsychologistRecentClientsRequestContract
): Promise<AxiosResponse<contracts.TGetPsychologistRecentClientResponseContract>> => {
	const {psychologistId, sort, periodFrom, periodTo} = contract;
	const queryParams = `?sort=${sort}&periodFrom=${periodFrom}&periodTo=${periodTo}`;

	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/psychologists/${psychologistId}/recent/clients${queryParams}`,
		getAxiosConfig({
			validateStatus: function (status) {
				// 204 код - это тоже ошибочный код.
				return status === 200;
			}
		})
	);
};

export const getPsychologistClientByIdApi = (contract: contracts.IGetPsychologistClientByIdRequestContract) => {
	const {psychologistId, clientId} = contract;
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/psychologists/${psychologistId}/client/${clientId}`,
		getAxiosConfig({
			validateStatus: function (status) {
				// 204 код - это тоже ошибочный код.
				return status === 200;
			}
		})
	);
};

export const changePsychologistAvailableStatusApi = (
	contract: contracts.IChangePsychologistAvailableStatusRequestContract
): Promise<AxiosResponse<contracts.IGetPsychologistResponseContract>> => {
	return apiClient.patch(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psyID}`,
		[
			{
				op   : "replace",
				path : "/isShownInAutomatch",
				value: contract.isShownInAutomatch
			}
		]
	);
};

export const changePsychologistTelegramOrSkypeApi = (
	contract: contracts.IChangePsychologistTelegramOrSkypeRequestContract
): Promise<AxiosResponse<contracts.IGetPsychologistResponseContract>> => {
	return apiClient.patch(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psyID}`,
		getPatchParams(contract, ["skype", "telegram"])
	);
};

export const changePsychologistSkypeUrlApi = (
	contract: contracts.IChangePsychologistSkypeUrlRequestContract
): Promise<AxiosResponse<contracts.IGetPsychologistResponseContract>> => {
	return apiClient.patch(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psyID}`,
		getPatchParams(contract, ["skypeInvite"])
	);
};

export const postGetPhotoOnModerationApi = (contract: contracts.TGetPhotoOnModerationContract): Promise<AxiosResponse<contracts.TGetPhotoOnModerationContract["response"]>> => {
	return apiClient.post(
		`${BACKEND_URL}/media/v1/files`,
		contract.payload
	);
};

export const getAllTopicsApi = (): Promise<AxiosResponse<contracts.TGetAllTopicsContract["response"][]>> => {
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/topics`
	);
};

export const getAllPracticesApi = (): Promise<AxiosResponse<contracts.TGetAllPracticesContract["response"][]>> => {
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/practices`
	);
};

export const getAllDocumentTypesApi = (): Promise<AxiosResponse<contracts.TGetAllDocumentTypesContract["response"][]>> => {
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/document_types`
	);
};

export const getProfileOnModerationApi = (contract: contracts.TGetProfileOnModerationContract): Promise<AxiosResponse<TProfileOnModeration>> => {
	return apiClient.get(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile`
	);
};

/**
 * Для отправки компетенций на модерацию.
 * @returns 200 - OK - {profileId: 777}
 * @exception 400 - Bad request - {error: bad request}
 * @exception 403 - Forbidden - {error: forbidden}
 * @exception 409 - Conflict - {error: conflict}
 */
export const putCompetencesOnModerationApi = (contract: contracts.TPutCompetencesOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/topics`,
		contract.competences
	);
};

export const putPracticesOnModerationApi = (contract: contracts.TPutPracticesOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/practices`,
		contract.practices
	);
};

export const putAboutOnModerationApi = (contract: contracts.TPutAboutOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/general`,
		contract.about
	);
};

export const putPersonalOnModerationApi = (contract: contracts.TPutPersonalOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/person`,
		contract.personal
	);
};

export const putEducationOnModerationApi = (contract: contracts.TPutEducationOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/educations`,
		contract.education
	);
};

export const putPhotoOnModerationApi = (contract: contracts.TPutPhotoOnModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/photo`,
		contract.payload
	);
};

export const putRejectModerationApi = (contract: contracts.TPutRejectModerationContract): Promise<AxiosResponse> => {
	return apiClient.put(
		`${BACKEND_URL}/psychologist/v1/psychologists/${contract.psychologistId}/moderation/profile/reject?reject=${contract.payload.service}`,
		{reason: contract.payload.reason}
	);
};
