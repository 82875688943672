import {all, call, put, takeLatest} from "redux-saga/effects";
import {AxiosError} from "axios";
import {PayloadAction} from "@reduxjs/toolkit";
import {actions as qiwiSlice} from "@/shared/model/qiwi/slice";
import {actions as notificationSlice} from "@/shared/model/notification/slice";
import {getPayouts, getPayoutscount, patchSentChecks, putPsychologistPaymentsCheckApi} from "@/shared/api/v1/qiwi/requests";
import {IPaginationFilter} from "@/shared/types/pagination";
import {TPatchSentCheckContract} from "@/shared/api/v1/qiwi/contracts";
import {TPostPsychologistPayments} from "@/shared/types/payout";

function* getPayoutsSaga(action: PayloadAction<IPaginationFilter>) {
	try {
		const {limit, offset} = action.payload;
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getPayouts, {
			psychologistId,
			limit,
			offset
		});
		yield put(qiwiSlice.getPayoutsSuccess({
			items  : response.data || [],
			hasMore: response.status !== 204 && response.data.length === 20
		}));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при загрузке выписок"
		};
		yield put(qiwiSlice.getPayoutsSuccessFailure(requestError));
		yield put(notificationSlice.notifyError(requestError));
	}
}

function* toggleSentCheckSaga(action: PayloadAction<TPatchSentCheckContract["payload"] & IPaginationFilter>) {
	try {
		const checksData: TPatchSentCheckContract["payload"] = {
			isCheckSent    : action.payload.isCheckSent,
			eaAppointmentId: action.payload.eaAppointmentId
		};
		const payoutsData: IPaginationFilter = {
			limit : action.payload.limit,
			offset: action.payload.offset
		};
		yield call(patchSentChecks, checksData);
		const psychologistId = +localStorage.getItem("userId");
		const {data = []} = yield call(getPayouts, {psychologistId, ...payoutsData});
		yield put(qiwiSlice.TOGGLE_SENT_CHECK_SUCCESS({payout: data[0], offset: action.payload.offset}));
	} catch (error) {
		console.error(error, "error TOGGLE_SENT_CHECK");
		yield put(qiwiSlice.TOGGLE_SENT_CHECK_ERROR());
	}
}

function* putPsychologistPaymentsCheckSaga(
	action: PayloadAction<TPostPsychologistPayments>
) {
	try {
		const {isSent, checkLink, eaAppointmentId, limit, offset} = action.payload;
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(putPsychologistPaymentsCheckApi, {
			isSent,
			checkLink,
			appointmentId: eaAppointmentId
		});
		if (response.status === 200) {
			yield put(qiwiSlice.getPayoutsCount());
			const {data = []} = yield call(getPayouts, {psychologistId, ...{limit, offset}});
			yield put(qiwiSlice.putPsychologistPaymentsCheckSuccess({payout: data[0], offset}));
			yield put(qiwiSlice.setIsFormOpen(false));
			yield put(qiwiSlice.setCurrentCheckData(null));
		}
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка"
		};
		yield put(qiwiSlice.putPsychologistPaymentsCheckFailure(requestError));
		yield put(qiwiSlice.setIsFormOpen(false));
	}
}

function* getPsychologistPayoutsCountSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getPayoutscount, {
			psychologistId
		});
		if (response.status === 200) {
			yield put(qiwiSlice.getPayoutsCountSuccess(response.data));
		}
	} catch (error) {
		yield put(qiwiSlice.getPayoutsCountFailure());
	}
}

function* saga() {
	yield all([
		takeLatest(qiwiSlice.getPayouts, getPayoutsSaga),
		takeLatest(qiwiSlice.getPayoutsCount, getPsychologistPayoutsCountSaga),
		takeLatest(qiwiSlice.TOGGLE_SENT_CHECK, toggleSentCheckSaga),
		takeLatest(qiwiSlice.putPsychologistPaymentsCheck, putPsychologistPaymentsCheckSaga)
	]);
}

export default saga;
