import {createSlice, CaseReducer, PayloadAction} from "@reduxjs/toolkit";
import {IPayout, TPostPsychologistPayments} from "@/shared/types/payout";
import {RequestError} from "@/shared/types/error";
import {IPaginationFilter, IPaginationItems} from "@/shared/types/pagination";
import {TGetPsychologistPayoutsCount, TPatchSentCheckContract} from "@/shared/api/v1/qiwi/contracts";

export interface IQiwiState {
	payouts: {
		data: IPayout[]
		count: number
		loading: boolean
		errors?: RequestError
		hasMore: boolean
	}
	checks: {
		loading: boolean
		errors?:RequestError
	}
	currentCheckData: {
		id: number
		index: number
	}
	isFormOpen: boolean
}

const setIsFormOpen: CaseReducer<IQiwiState, PayloadAction<boolean>> = (state, action) => ({
	...state,
	isFormOpen: action.payload
});

const getPayoutsCount: CaseReducer<IQiwiState> = (state) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: true
	}
});

const getPayoutsCountSuccess: CaseReducer<IQiwiState, PayloadAction<TGetPsychologistPayoutsCount["response"]>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		count  : action.payload.checksCount
	}
});

const getPayoutsCountFailure: CaseReducer<IQiwiState, PayloadAction<RequestError>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		errors : action.payload,
		hasMore: false
	}
});

const getPayouts: CaseReducer<IQiwiState, PayloadAction<IPaginationFilter>> = (state) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: true
	}
});

const getPayoutsSuccess: CaseReducer<IQiwiState, PayloadAction<IPaginationItems<IPayout>>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		data   : [...state.payouts.data, ...action.payload.items],
		// data   : action.payload.items,
		hasMore: action.payload.hasMore
	}
});

const getPayoutsSuccessFailure: CaseReducer<IQiwiState, PayloadAction<RequestError>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		errors : action.payload,
		hasMore: false
	}
});

const TOGGLE_SENT_CHECK: CaseReducer<IQiwiState, PayloadAction<TPatchSentCheckContract["payload"] & IPaginationFilter>> = (state) => ({
	...state,
	checks: {
		...state.checks,
		loading: true
	}
});

const TOGGLE_SENT_CHECK_SUCCESS: CaseReducer<IQiwiState, PayloadAction<{offset: number; payout: IPayout}>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		data: state.payouts.data.map((existPayout, index) => {
			return action.payload.offset !== index ? existPayout : action.payload.payout;
		})
	},
	checks: {
		...state.checks,
		loading: false
	}
});

const TOGGLE_SENT_CHECK_ERROR: CaseReducer<IQiwiState> = (state) => ({
	...state,
	checks: {
		...state.checks,
		loading: false
	}
});

const clearPayouts: CaseReducer<IQiwiState> = (state) => ({
	...state,
	payouts: initialState.payouts
});

const setCurrentCheckData: CaseReducer<IQiwiState, PayloadAction<{ id: number; index: number }>> = (state, action) => ({
	...state,
	currentCheckData: action.payload
});

const putPsychologistPaymentsCheck: CaseReducer<
IQiwiState,
PayloadAction<TPostPsychologistPayments>
> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: true
	}
});

const putPsychologistPaymentsCheckSuccess: CaseReducer<
IQiwiState,
PayloadAction<{offset: number; payout: IPayout}
>> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		data   : state.payouts.data.map((existPayout, index) => {
			return action.payload.offset !== index ? existPayout : action.payload.payout;
		})
	}
});

const putPsychologistPaymentsCheckFailure: CaseReducer<
IQiwiState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	payouts: {
		...state.payouts,
		loading: false,
		errors : action.payload
	}
});

const initialState: IQiwiState = {
	payouts: {
		data   : [],
		count  : null,
		loading: false,
		hasMore: true
	},
	checks: {
		loading: false
	},
	currentCheckData: null,
	isFormOpen      : false
};

const slice = createSlice({
	initialState,
	name    : "qiwi",
	reducers: {
		setIsFormOpen,
		getPayouts,
		getPayoutsSuccess,
		getPayoutsSuccessFailure,
		getPayoutsCount,
		getPayoutsCountSuccess,
		getPayoutsCountFailure,
		TOGGLE_SENT_CHECK,
		TOGGLE_SENT_CHECK_SUCCESS,
		TOGGLE_SENT_CHECK_ERROR,
		clearPayouts,
		setCurrentCheckData,
		putPsychologistPaymentsCheck,
		putPsychologistPaymentsCheckSuccess,
		putPsychologistPaymentsCheckFailure
	}
});

export const {reducer, actions} = slice;
