import React from "react";

import BackButtonIcon from "@/shared/ui/assets/Icon/ArrowLeft.svg";
import {Button} from "@/shared/ui/uikit/Form";
import {Typography} from "@/shared/ui/uikit/Typography/Typography";

import {Block} from "./Block";

import "./styles.scss";

interface Props {
	onClick: () => void
}

export const BackButton = ({onClick}: Props): React.ReactElement => {
	return (
		<Button color="primary" variant="link" onClick={onClick}>
			<Block className="buttons-group__btn">
				<Block className="btn-back">
					<img src={BackButtonIcon} alt="logout" />
				</Block>
				<Typography textAlign="center" >
          Назад
				</Typography>
			</Block>
		</Button>
	);
};
