import type {FeatureFlagsAdapter} from "../ui/provider";

const QUERY_KEY_PREFIX = "zo_ab_";

const queryAdapter: FeatureFlagsAdapter = {
	async getFlags() {
		try {
			const query = new URLSearchParams(window.location.search);

			const result = {};
			query.forEach((value, key) => {
				if (key.startsWith(QUERY_KEY_PREFIX)) {
					const formattedKey = key.slice(QUERY_KEY_PREFIX.length);
					const formattedValue = value === "true" || value === "false" ? Boolean(value) : value;
					result[formattedKey] = formattedValue;
				}
			});

			return result;
		} catch (error) {
			console.error(error);
			return {};
		}
	},
	onFlagCheck(flag_name) {
		// not implemented
	}
};

export default queryAdapter;
