import {AxiosResponse} from "axios";
import {apiClient} from "../../apiService";
import {getAxiosConfig} from "../../utils";
import {BACKEND_URL} from "@/shared/config/constants";
import {IPutIntegrationAuthRequest, IPutIntegrationAuthResponse} from "./contracts";

const URL = `${BACKEND_URL}/google-calendar/v1`;

export const putIntegrationAuthApi = (
	contract: IPutIntegrationAuthRequest
): Promise<AxiosResponse<IPutIntegrationAuthResponse>> => {
	return apiClient.put(`${URL}/calendars/auth`, contract, getAxiosConfig());
};
