import {
	ConsoleInstrumentation,
	ConsoleTransport,
	ErrorsInstrumentation,
	FetchTransport,
	initializeFaro,
	WebVitalsInstrumentation
} from "@grafana/faro-web-sdk";
import {APP_NAME, ENVIROMENT, TAG_ENV, FARO_API_KEY, grafanaFaroURL} from "@/shared/config/constants";

export const faro = initializeFaro({
	instrumentations: [
		new ErrorsInstrumentation(),
		new WebVitalsInstrumentation(),
		new ConsoleInstrumentation()
	],
	transports: [
		new FetchTransport({
			url   : grafanaFaroURL,
			apiKey: FARO_API_KEY
		}),
		new ConsoleTransport()
	],
	app: {
		name       : APP_NAME,
		version    : TAG_ENV || "0.0.0",
		environment: ENVIROMENT
	}
});
