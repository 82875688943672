import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {isString} from "lodash";
import {all, call, put, select, takeLatest} from "redux-saga/effects";

import {
	actions,
	EventPayloadWithSuccess,
	IdPayloadWithSuccess,
	PayloadWithReason
} from "@/shared/model/calendar/slice";
import {actions as notificationActions} from "@/shared/model/notification/slice";
import {getPsychologistState} from "@/shared/model/psychologist/selectors";
import {actions as psychologistActions} from "@/shared/model/psychologist/slice";
import {
	deleteEventApi,
	deleteRecurrenceEventApi,
	deleteRecurrenceEventInstanceApi,
	getEventsApi,
	getRecurrenceEventInstancesApi,
	patchCalendarApi,
	patchEventApi,
	patchRecurrenceEventApi,
	patchRecurrenceEventInstanceApi,
	postEventsApi,
	postRecurrenceEventApi,
	postRecurrenceRuleApi,
	putCustomClient
} from "@/shared/api/v1/calendar/requests";
import {CalendarEvent, CalendarRecurranceRule} from "@/shared/types/calendar";
import {actions as errorModalActions} from "@/widgets/ServerErrorModal/model/slice";

function* createCustomClient(event: CalendarEvent) {
	if (event.type === "my_client" && isString(event.clientInfoId)) {
		const psychologist = yield select(getPsychologistState);
		const calendarId = psychologist.profile.data?.calendar?.calendarId;
		const name = event.clientInfoId;
		const response = yield call(putCustomClient, {
			calendarId,
			name
		});
		yield put(
			psychologistActions.putCustomClientSuccess({
				id: response.data.id,
				calendarId,
				name
			})
		);
		return {
			...event,
			clientInfoId: response.data.id
		};
	}
	return event;
}

function* patchCalendarSaga(
	action: PayloadAction<{
		calendarId: number
		timezone: string
	}>
) {
	try {
		yield call(patchCalendarApi, action.payload);
		yield put(actions.patchCalendarSuccess(action.payload.timezone));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.patchCalendarFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* getEventsSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getEventsApi, {
			psychologistId
		});
		yield put(
			actions.getEventsSuccess(
				(response.data || []).map((evt) => ({
					...evt,
					calendarId: evt.idCalendar
				}))
			)
		);
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.getEventsFailure(requestError));
		if (axiosError.response?.status === 500) {
			yield put(errorModalActions.showErrorModal());
		} else {
			yield put(notificationActions.notifyError(requestError));
		}
	}
}

function* createEventSaga(action: PayloadAction<EventPayloadWithSuccess>) {
	try {
		const event = yield * createCustomClient(action.payload.event);
		const response = yield call(postEventsApi, event);
		yield put(
			actions.createEventSuccess({
				...event,
				id        : response.data?.eventId,
				idCalendar: event.calendarId
			})
		);
		action.payload.onSuccess();
		yield put(actions.getEvents());
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};

		yield put(actions.createEventFailure(requestError));
		// При 422 коде уведомляем пользователя в форме.
		if (axiosError.response?.status !== 422) {
			/* Отдельно обрабатываем кейс, когда пользователь пытается создать свободный слот на то время, когда такой слот уже есть */
			if (axiosError.response?.status === 409) {
				yield put(
					notificationActions.notifyError({
						...requestError,
						message:
              "Произошла ошибка. Обновите страницу, чтобы продолжить работу с расписанием."
					})
				);
			} else {
				yield put(notificationActions.notifyError(requestError));
			}
		}
	}
}

function* deleteEventSaga(action: PayloadAction<IdPayloadWithSuccess & PayloadWithReason>) {
	try {
		yield call(deleteEventApi, {id: action.payload.id, reason: action.payload.reason});
		yield put(actions.deleteEventSuccess(action.payload.id));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.deleteEventFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* patchEventSaga(action: PayloadAction<EventPayloadWithSuccess>) {
	try {
		const event = yield * createCustomClient(action.payload.event);
		yield call(patchEventApi, event);
		yield put(actions.patchEventSuccess(event));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.patchEventFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* createRecurrenceRuleSaga(
	action: PayloadAction<CalendarRecurranceRule>
) {
	try {
		const response = yield call(postRecurrenceRuleApi, action.payload);
		yield put(
			actions.createRecurrenceRuleSuccess({
				...action.payload,
				recurrenceRuleId: response.data?.recurrenceRuleId
			})
		);
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.createRecurrenceRuleFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* createRecurrenceEventSaga(
	action: PayloadAction<{
		event: CalendarEvent
		rule: CalendarRecurranceRule
		onSuccess: () => void
	}>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		// const {
		//   data: { recurrenceRuleId }
		// } = yield call(postRecurrenceRule, action.payload.rule);
		// yield call(postRecurrenceEvent, {
		//   ...action.payload.event,
		//   recurrenceRuleId
		// });
		const event = yield * createCustomClient(action.payload.event);
		yield call(postRecurrenceEventApi, event);
		const responseInstances = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(actions.createRecurrenceEventSuccess(responseInstances.data));
		// Необходимо обновить также обычные события, так как туда приходит родительский эвент.
		const responseEvents = yield call(getEventsApi, {
			psychologistId
		});
		yield put(
			actions.getEventsSuccess(
				responseEvents.data.map((evt) => ({
					...evt,
					calendarId: evt.idCalendar
				}))
			)
		);
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		if (
			axiosError.response?.status === 409 &&
      action.payload.event.type === "free"
		) {
			requestError.message = "Ошибка. На это время уже есть свободный слот";
		}
		yield put(actions.createRecurrenceEventFailure(requestError));
		// При 422 коде уведомляем пользователя в форме.
		if (axiosError.response?.status !== 422) {
			yield put(notificationActions.notifyError(requestError));
		}
	}
}

function* deleteRecurrenceEventSaga(
	action: PayloadAction<IdPayloadWithSuccess & PayloadWithReason>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(deleteRecurrenceEventApi, {id: action.payload.id, reason: action.payload.reason});
		const responseInstances = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(actions.deleteRecurrenceEventSuccess(responseInstances.data));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.deleteRecurrenceEventFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* patchRecurrenceEventSaga(
	action: PayloadAction<EventPayloadWithSuccess>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const event = yield * createCustomClient(action.payload.event);
		yield call(patchRecurrenceEventApi, event);
		const responseInstances = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(actions.patchRecurrenceEventSuccess(responseInstances.data));
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.patchRecurrenceEventFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* patchRecurrenceEventInstanceSaga(
	action: PayloadAction<EventPayloadWithSuccess>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const event = yield * createCustomClient(action.payload.event);
		yield call(patchRecurrenceEventInstanceApi, event);
		const responseInstances = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(
			actions.patchRecurrenceEventInstanceSuccess(responseInstances.data)
		);
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.patchRecurrenceEventInstanceFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* deleteRecurrenceEventInstanceSaga(
	action: PayloadAction<EventPayloadWithSuccess & PayloadWithReason>
) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const {id, idEvent} = action.payload.event;
		yield call(deleteRecurrenceEventInstanceApi, {id, idEvent, reason: action.payload.reason});
		const responseInstances = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(
			actions.deleteRecurrenceEventInstanceSuccess(responseInstances.data)
		);
		action.payload.onSuccess();
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.deleteRecurrenceEventInstanceFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* getRecurrenceEventInstancesSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getRecurrenceEventInstancesApi, {
			psychologistId
		});
		yield put(actions.getRecurrenceEventInstancesSuccess(response.data || []));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.getRecurrenceEventInstancesFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* saga() {
	yield all([
		takeLatest(actions.patchCalendar, patchCalendarSaga),
		takeLatest(actions.getEvents, getEventsSaga),
		takeLatest(actions.createEvent, createEventSaga),
		takeLatest(actions.deleteEvent, deleteEventSaga),
		takeLatest(actions.patchEvent, patchEventSaga),
		takeLatest(actions.createRecurrenceRule, createRecurrenceRuleSaga),
		takeLatest(actions.createRecurrenceEvent, createRecurrenceEventSaga),
		takeLatest(actions.deleteRecurrenceEvent, deleteRecurrenceEventSaga),
		takeLatest(actions.patchRecurrenceEvent, patchRecurrenceEventSaga),
		takeLatest(actions.patchRecurrenceEventInstance, patchRecurrenceEventInstanceSaga),
		takeLatest(actions.deleteRecurrenceEventInstance, deleteRecurrenceEventInstanceSaga),
		takeLatest(actions.getRecurrenceEventInstances, getRecurrenceEventInstancesSaga)
	]);
}

export default saga;
