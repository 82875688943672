import React from "react";
import {Block} from "./Block";
import "./styles.scss";

type Props = {
	children: React.ReactChild
};

export const MobileOnly = (props: Props): React.ReactElement => {
	return <Block className="zg_mobile-only">{props.children}</Block>;
};
