import React from "react";
import {Controller, FieldError, RegisterOptions, UseFormMethods} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";

import {Country, TPhoneNumberReturnType} from "./types";

import "react-phone-input-2/lib/style.css";
import "./phone-number.scss";

const PhoneNumber: React.FC<TProps> = ({
	className = "",
	control,
	errorMessage,
	defaultValue,
	label,
	rules,
	isValid
}) => {
	return (
    <div className={`phone-number ${className}`}>
      {label && <label htmlFor="phone-number" className="phone-number__label">{label}</label>}
      <Controller name="phone"
                  control={control}
                  defaultValue={!defaultValue && null}
                  rules={rules}
                  render={props => {
                  	return (
            <PhoneInput inputClass={"phone-number__input"}
                        enableAreaCodes
                        inputProps={{
                        	id      : "phone-number",
                        	name    : "phone",
                        	required: true
                        }}
                        country="ru"
                        localization={ru}
                        onChange={handleOnChange}
                        value={props.value?.number}
                        isValid={isValid}
            />
                  	);
                  	function handleOnChange(unformatted: string, country: Country) {
                  		const isRu = country.countryCode?.toLowerCase() === "ru";
                  		const ruWithoutSevenAtStart = isRu && unformatted.length === 11 && unformatted[0] !== "7";
                  		const number = ruWithoutSevenAtStart ? `7${unformatted.slice(1)}` : unformatted;
                  		props.onChange({number, countryCode: country.countryCode?.toUpperCase()});
                  	}
                  }}
      />
			{errorMessage && <span className='phone-number__error'>{errorMessage}</span>}
    </div>
	);
};

interface TProps {
	className?: string
	control: UseFormMethods["control"]
	defaultValue?: TPhoneNumberReturnType | null
	label?: string
	rules?: RegisterOptions
	isValid?: boolean
	errorMessage?: string
}

export default React.memo(PhoneNumber);
