import React, {useMemo} from "react";

import {Color} from "@/shared/ui/uikit/Typography";
import {Block} from "@/shared/ui/uikit/Layout";

import {IWelcomeBannerItem} from "./WelcomeBanner.types";
import WelcomePopupItem from "./WelcomeBannerItem";

import "./welcome-banner.scss";

interface Props {
	data?: IWelcomeBannerItem[]
	title?: string
	className?: string
	onClose?: () => void
	emoji?: string
	text?: string
	variant?: "sm"
}

const WelcomeBanner: React.FC<Props> = ({
	data,
	title = "Добро пожаловать в Zigmund.Online!",
	className,
	onClose,
	emoji,
	text,
	variant = ""
}) => {
	return (
		<Block className={`welcome-banner ${className} ${variant}`}>
			<Block className="welcome-banner__icon-wrapper">
				<img className="welcome-banner__icon" src={emoji} alt="emoji" />
			</Block>
			<Block>
				<h2 className="welcome-banner__title">
					<Color color="default">{title}</Color>
				</h2>
				{text && <p className="welcome-banner__text">{text}</p>}
			</Block>
			{data && _renderBody(data)}
			{onClose 	&&
				<button onClick={() => onClose()}
					className="welcome-banner__btn"
				>
							Понятно!
				</button>
			}
		</Block>
	);
};

const _renderBody = (
	data: IWelcomeBannerItem[]
) => useMemo(() => {
	return (
		<ul className="welcome-banner__list">
			{data.map((item) => (
				<WelcomePopupItem key={item.title || item.text}
					item={item}
				/>
			))}
		</ul>
	);
}, [data]);

export default WelcomeBanner;
