import { RootState } from "@/app/model/reducer";

export const getPsychologistState = (state: RootState) => state.psychologist;

export const getPsychologistCustomClientsState = (state: RootState) => state.psychologist.customClients;

export const GET_PSYCHOLOGIST_LOADING = (state: RootState) => state.psychologist.profile.loading;

export const GET_PSYCHOLOGIST_BETA = (state: RootState) => state.psychologist.profile.data.isBetaTester;

export const getPsychologistClients = (state: RootState) => state.psychologist.clients.list;

export const getPsychologistProfile = (state: RootState) => state.psychologist.profile.data;

export const getPsychologistPaymentHistory = (state: RootState) => state.psychologist.paymentHistory;
