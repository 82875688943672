import React from "react";

import closeButtonIcon from "@/shared/ui/assets/Icon/Cross.svg";
import {Button} from "@/shared/ui/uikit/Form";

import {Block} from "./Block";

import "./styles.scss";

interface Props {
	onClick: () => void
	className?: string
}

export const CloseButton = ({onClick, className}: Props): React.ReactElement => {
	return (
		<Button color="primary" variant="icon" onClick={onClick} className={className}>
			<Block className="btn-back">
				<img src={closeButtonIcon} alt="закрыть" />
			</Block>
		</Button>
	);
};
