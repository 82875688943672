import React, {KeyboardEventHandler, MouseEventHandler} from "react";

import {Spinner, SpinnerSize} from "@/shared/ui/uikit/Spinner/Spinner";

import "./form.scss";

type ButtonVariant = "button" | "link" | "icon";
type ButtonColor = "default" | "primary" | "error" | "transparent" | "additional";
type ButtonType = "submit" | "button";
type ButtonWidth = "wide" | "auto";

interface Props {
	variant?: ButtonVariant
	color?: ButtonColor
	type?: ButtonType
	width?: ButtonWidth
	disabled?: boolean
	onClick?: MouseEventHandler<HTMLElement>
	children?: React.ReactChild
	url?: string
	newPage?: boolean
	isLoading?: boolean
	spinnerSize?: SpinnerSize
	text?: string
	className?: string
}

export const Button = ({
	disabled = false,
	variant = "button",
	type = "button",
	color = "default",
	width = variant === "link" ? "auto" : "wide",
	onClick,
	children,
	url,
	newPage,
	isLoading,
	spinnerSize = "medium",
	text,
	className
}: Props): React.ReactElement => {
	const handleClick: MouseEventHandler<HTMLElement> = (event) => {
		if (onClick && !disabled) {
			onClick(event);
		}
	};
	const handleKeyPress: React.KeyboardEventHandler<HTMLAnchorElement> = (
		event
	) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleClick(event as unknown as React.MouseEvent<HTMLElement>);
		}
	};
	switch (variant) {
		case "button":
			return (
				<button tabIndex={0}
					className={`form-btn form-btn-${color} form-btn-${width} ${
     	disabled ? "form-btn_disabled" : ""
					} ${isLoading ? "form-btn__loading" : ""} ${className}`}
					disabled={disabled || isLoading}
					onClick={onClick}
					type={type}
				>
					{children && <span className="form-btn__content">{children}</span>}
					{text && <span className="form-btn__text">{!isLoading ? text : ""}</span>}
					{isLoading && <Spinner size={spinnerSize} />}
				</button>
			);
		case "link":
			return (
				<a href={url}
					tabIndex={0}
					className={`form-btn__link form-btn__link-${color} form-btn__link-${width} ${className}`}
					onClick={onClick}
					onKeyDown={handleKeyPress}
					target={newPage ? "_blank" : "_self"}
					rel="noreferrer"
				>
					{children && <span className="form-btn__content">{children}</span>}
					{text && <span className="form-btn__text">{text}</span>}
				</a>
			);
		case "icon":
			return (
				<button onClick={onClick}
					type="button"
					tabIndex={0}
					className={`form-btn form-btn__icon ${className}`}
				>
					{children}
				</button>
			);
	}
};
