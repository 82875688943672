let backendUrl;
switch (process.env.DEPLOY_ENV) {
case "production":
	backendUrl = "https://zsvc.tech";
	break;
case "staging":
	backendUrl = "https://zservice.tech";
	break;
	// default: backendUrl = 'http://10.10.15.119:3030'; break;
default:
	backendUrl = "https://zservice.tech";
	break;
}

export const BACKEND_URL = backendUrl;
export const isDevelopment = process.env.DEPLOY_ENV !== "production";
export const TAG_ENV = process.env.TAG_ENV;

export const landingURL = isDevelopment ? "https://zservice.tech/" : "https://zigmund.online/";

/**
 * Временная зона по умолчанию.
 */
export const DEFAULT_TIMEZONE = "Europe/Moscow";

const GOOGLE_REDIRECT_URL =
  location.host === "stgpsychologist.zigmund.online"
  	? "https%3A%2F%2Fstgpsychologist.zigmund.online"
  	: "https%3A%2F%2Fpsychologist.zigmund.online";

/**
 * Ссылка на авторизацию в гугл для синхронизации календаря.
 */
export const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=717838382712-4n3oouha827qvekpcdkc35svecgq9p2q.apps.googleusercontent.com&prompt=consent&redirect_uri=${GOOGLE_REDIRECT_URL}&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.app.created+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=state-token`;

let enviroment;

switch (process.env.DEPLOY_ENV) {
case "production":
	enviroment = "prod";
	break;
case "staging":
	enviroment = "stage";
	break;
default:
	enviroment = "dev";
	break;
}

let FARO_BACKEND_URL;

switch (process.env.DEPLOY_ENV) {
case "production":
	FARO_BACKEND_URL = "https://zigmund.online";
	break;
case "staging":
	FARO_BACKEND_URL = "https://zservice.tech";
	break;
default:
	FARO_BACKEND_URL = "https://zservice.tech";
	break;
}

let faroAPIKEY;

switch (process.env.DEPLOY_ENV) {
case "production":
	faroAPIKEY = "m8sr9ei7rwmvh29wr8u4uw7bg603g4wh";
	break;
case "staging":
	faroAPIKEY = "z4jforkeh89r736sgqyuz7re7lsymg65";
	break;
default:
	faroAPIKEY = "z4jforkeh89r736sgqyuz7re7lsymg65";
	break;
}

export const FARO_API_KEY = faroAPIKEY;

export const ENVIROMENT = enviroment;

export const grafanaFaroURL = `${FARO_BACKEND_URL}/collector`;

export const APP_NAME = "psychologist-backoffice";

export const isMobile = window.innerWidth < 768;
