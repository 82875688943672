import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {GTM_ANALYTICS} from "@/shared/lib/analytics/gtmEvents";
import {handleGTM} from "@/shared/lib/analytics/handleGTM";
import selectors from "@/shared/model/auth/selectors";
import {actions} from "@/shared/model/auth/slice";
import {TPutUserPersonalOnModeration} from "@/shared/types/onboarding";
import {Block} from "@/shared/ui/uikit/Layout";

import ConfirmEmail from "../ConfirmEmail";
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import RegistrationPopUp from "../RegistrationPopUp/RegistrationPopUp";

import "./registration-main.scss";

const RegistrationMain: React.FC = () => {
	const [formData, setFormData] = useState<TPutUserPersonalOnModeration>();
	const isConfirmModalOpen = useSelector(selectors.GET_EMAIL_CONFIRM_MODAL_STATE);
	const dispatch = useDispatch();

	const handleSubmitData = useCallback((data, email) => {
		handleGTM(["psy_registration_form_submit", GTM_ANALYTICS.REGISTER]);
		setFormData(data);
		dispatch(actions.submitEmail({email}));
	}, []);

	const handleCloseModal = () => {
		dispatch(actions.SET_EMAIL_CONFIRM_MODAL_STATE(false));
	};

	return (
		<main className="registration-main">
			<Block className="registration-main__wrapper">
				<h2 className="registration-main__title">Регистрация для психологов</h2>
				<p className="registration-main__text">Заполните поля и нажмите “Далее”.</p>
				<p className="registration-main__text">На указанную почту отправим код подтверждения. После входа откроется анкета об опыте и образовании.</p>
				<RegistrationForm onSubmit={handleSubmitData} />
				{isConfirmModalOpen && <ConfirmEmail data={formData} onClose={handleCloseModal} />}
			</Block>
			<RegistrationPopUp/>
		</main>
	);
};

export default React.memo(RegistrationMain);
