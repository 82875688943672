import * as offerContract from "@/shared/api/v1/offer/contracts";
import * as psychologistContract from "@/shared/api/v1/psychologists/contracts";

type TOfferMethods = "GET_OFFERS" | "ACCEPT_OFFER"
type TPsychologistMethods = "GET_ALL_TOPICS" | "GET_ALL_PRACTICES" | "GET_ALL_DOCUMENT_TYPES"

/**
 * OFFER
 */

export const GET_ALL_OFFERS = "OFFER/GET_ALL_OFFERS";
type TGetAllPsychologistOffers = {
	type: typeof GET_ALL_OFFERS
	response: offerContract.TGetAllPsychologistOffersContract["response"]
}

export const POST_ACCEPT = "OFFER/POST_ACCEPT";
type TPostPsychologistOfferAccept = {
	type: typeof POST_ACCEPT
	payload: Omit<offerContract.TPostAcceptOfferContract["payload"], "userId">
	response: offerContract.TPostAcceptOfferContract["response"]
}

export type TOffer = {
	methods: TOfferMethods
	TGetAllPsychologistOffers: TGetAllPsychologistOffers
	TPostPsychologistOfferAccept: TPostPsychologistOfferAccept
}

/**
 * PSYCHOLOGIST
 */

export const GET_ALL_TOPICS = "PSYCHOLOGIST/GET_ALL_TOPICS";
type TGetAllTopics = {
	type: typeof GET_ALL_TOPICS
	response: psychologistContract.TGetAllTopicsContract["response"]
}

export const GET_ALL_PRACTICES = "PSYCHOLOGIST/GET_ALL_PRACTICES";
type TGetAllPractices = {
	type: typeof GET_ALL_PRACTICES
	response: psychologistContract.TGetAllPracticesContract["response"]
}

export const GET_ALL_DOCUMENT_TYPES = "PSYCHOLOGIST/GET_ALL_DOCUMENT_TYPES";
type TGetAllDocumentTypes = {
	type: typeof GET_ALL_DOCUMENT_TYPES
	response: psychologistContract.TGetAllDocumentTypesContract["response"]
}

export type TPsychologist = {
	methods: TPsychologistMethods
	TGetAllTopics: TGetAllTopics
	TGetAllPractices: TGetAllPractices
	TGetAllDocumentTypes: TGetAllDocumentTypes
}
