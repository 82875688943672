import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {all, call, put, takeLatest} from "redux-saga/effects";

import {routePath} from "@/app/routes";
import * as api from "@/shared/api/v1/onboarding/requests";
import {putOnboardingDataToAmoAPI} from "@/shared/api/v2/amo/requests";
import {clearUserOnboardingDataLocalStorage} from "@/shared/model/localStorage/storage";
import {
	PostEducationOnModerationTAction,
	PutEducationOnModerationTAction,
	PutExperienceOnModerationTAction,
	PutPracticesOnModerationTAction,
	PutSupervisionOnModerationTAction,
	PutUserInformationOnModerationTAction,
	TPutUserPersonalOnModeration
} from "@/shared/types/onboarding";

import {actions} from "../model/slice";

function* getUserSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserDataApi, {
			userId
		});
		yield put(actions.getUserSuccess(response.data));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.getUserFailure(requestError));
	}
}

function* postUserPersonalSaga(
	action: PayloadAction<TPutUserPersonalOnModeration>
) {
	try {
		const data = action.payload;
		const userId = localStorage.getItem("userId");
		const response = yield call(api.postUserPersonalApi,	{
			data,
			userId
		});
		if (response.status === 200) {
			yield call(putOnboardingDataToAmoAPI, {userId: +userId});
			window.location.href = routePath.login;
			yield put(actions.postUserPersonalSuccess());
		}
	} catch (error) {
		const axiosError = error as AxiosError;
		if (axiosError.response.status === 409) {
			window.location.href = routePath.login;
		} else {
			const requestError = {
				status : axiosError.response?.status,
				message: "Произошла ошибка, попробуйте позже."
			};
			yield put(actions.postUserPersonalFailure(requestError));
		}
	}
}

function* updateUserPersonalSaga(
	action: PayloadAction<TPutUserPersonalOnModeration>
) {
	try {
		const data = action.payload;
		const userId = localStorage.getItem("userId");
		const response = yield call(api.putUserPersonalApi,	{
			data,
			userId
		});
		if (response.status === 200) {
			yield put(actions.updateUserPersonalSuccess());
			yield put(actions.getUserPersonal());
		}
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Произошла ошибка, попробуйте позже."
		};
		yield put(actions.updateUserPersonalFailure(requestError));
	}
}

function* getUserPersonalSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserPersonalApi, {
			userId
		});
		if (response.status === 200) {
			yield put(actions.getUserPersonalSuccess(response.data));
		}
	} catch (error) {
		yield put(actions.getUserPersonalFailure());
	}
}

function* getUserSupervisionSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserSupervisionApi, {userId});
		if (response.status === 200) {
			yield put(actions.getUserSupervisionSuccess(response.data));
		}
	} catch (error) {
		yield put(actions.getUserSupervisionFailure());
	}
}

function* putUserSupervisionSaga(
	action: PayloadAction<PutSupervisionOnModerationTAction["payload"]>
) {
	try {
		const data = action.payload;
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.putUserSupervisionApi, {data, userId});
		if (response.status === 200) {
			yield put(actions.putUserSupervisionSuccess());
			yield put(actions.getUserSupervision());
		}
	} catch (error) {
		yield put(actions.putUserSupervisionFailure());
	}
}

function* putUserEducationSaga(
	action: PayloadAction<PutEducationOnModerationTAction["payload"]>
) {
	try {
		const data = action.payload;
		const response = yield call(api.putUserEducationApi, {data});
		if (response.status === 200) {
			yield put(actions.putUserEducationSuccess());
			yield put(actions.getUser());
		}
	} catch (error) {
		yield put(actions.putUserEducationFailure());
	}
}

function* postUserEducationSaga(
	action: PayloadAction<PostEducationOnModerationTAction["payload"]>
) {
	try {
		const data = action.payload;
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.postUserEducationApi, {data, userId});
		if (response.status === 200) {
			yield put(actions.postUserEducationSuccess());
			yield put(actions.getUser());
		}
	} catch (error) {
		yield put(actions.postUserEducationFailure());
	}
}

function* deleteUserEducationSaga(
	action: PayloadAction<number | string>
) {
	try {
		const id = action.payload;
		const response = yield call(api.deleteUserEducationApi, {
			id
		});
		if (response.status === 200) {
			yield put(actions.deleteUserEducationSuccess());
			yield put(actions.getUser());
		}
	} catch (error) {
		yield put(actions.deleteUserEducationFailure());
	}
}

function* getUserEducationSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserEducationApi, {userId});
		if (response.status === 200) {
			yield put(actions.getUserEducationSuccess(response.data));
		}
	} catch (error) {
		yield put(actions.getUserEducationFailure());
	}
}

function* getUserExperienceSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserExperienceApi, {userId});
		yield put(actions.getUserExperienceSuccess(response.data));
	} catch (error) {
		yield put(actions.getUserExperienceFailure());
	}
}

function* putUserExperienceSaga(
	action: PayloadAction<PutExperienceOnModerationTAction["payload"]>
) {
	try {
		const data = action.payload;
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.putUserExperienceApi, {data, userId});
		if (response.status === 200) {
			yield put(actions.putUserExperienceSuccess());
			yield put(actions.getUserExperience());
		}
	} catch (error) {
		yield put(actions.putUserExperienceFailure());
	}
}

function* getUserPracticesSaga() {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.getUserPracticesApi, {userId});
		if (response.status === 200) {
			yield put(actions.getUserPracticesSuccess(response.data));
		}
	} catch (error) {
		yield put(actions.getUserPracticesFailure());
	}
}

function* putUserPracticesSaga(
	action: PayloadAction<PutPracticesOnModerationTAction["payload"]>
) {
	try {
		const data = action.payload;
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.putUserPracticesApi, {data, userId});
		if (response.status === 200) {
			yield put(actions.putUserPracticesSuccess());
			yield put(actions.getUserPractices());
		}
	} catch (error) {
		yield put(actions.putUserPracticesFailure());
	}
}

function* puthUserSkypeSaga({payload}: PayloadAction<string>) {
	try {
		const skype = payload;
		const userId = +localStorage.getItem("userId");
		yield call(api.putUserSkypeApi, {
			skype: skype,
			userId
		});
		yield put(actions.putUserSkypeSuccess());
		yield put(actions.getUserPersonal());
	} catch (error) {
		yield put(actions.putUserSkypeFailure());
	}
}

function* putUserInformationSaga({payload}: PayloadAction<PutUserInformationOnModerationTAction["payload"]>) {
	try {
		const userId = +localStorage.getItem("userId");
		yield call(api.putUserInformationApi, {
			hoursPerWeek: payload.hoursPerWeek,
			userId
		});

		const response = yield call(api.getUserPersonalApi, {userId});

		if (response.status === 200) {
			const amoResponse = yield call(putOnboardingDataToAmoAPI, {userId});
			if (amoResponse.status === 200) {
				yield call(api.putUserStatusApi, {
					statusIdNew: 9,
					userId
				});
				yield call(putOnboardingDataToAmoAPI, {userId});
				yield call(clearUserOnboardingDataLocalStorage);
				yield put(actions.getUserPersonalSuccess(response.data));
				yield put(actions.putUserInformationSuccess());
				yield put(actions.getUser());
			}
		}
	} catch (error) {
		yield put(actions.putUserInformationFailure());
	}
}

function* changeUserEmailSaga ({payload}: PayloadAction<string>) {
	try {
		const userId = +localStorage.getItem("userId");
		const response = yield call(api.changeUserEmailApi, {
			email: payload,
			userId
		});
		if (response.status === 200) {
			yield put(actions.changeUserEmailSuccess());
			yield put(actions.getUserPersonal());
		}
	} catch (error) {
		yield put(actions.changeUserEmailFailure());
	}
}

function* saga() {
	yield all([
		takeLatest(actions.getUser, getUserSaga),
		takeLatest(actions.getUserPersonal, getUserPersonalSaga),
		takeLatest(actions.postUserPersonal, postUserPersonalSaga),
		takeLatest(actions.updateUserPersonal, updateUserPersonalSaga),
		takeLatest(actions.getUserSupervision, getUserSupervisionSaga),
		takeLatest(actions.putUserSupervision, putUserSupervisionSaga),
		takeLatest(actions.putUserEducation, putUserEducationSaga),
		takeLatest(actions.postUserEducation, postUserEducationSaga),
		takeLatest(actions.deleteUserEducation, deleteUserEducationSaga),
		takeLatest(actions.getUserEducation, getUserEducationSaga),
		takeLatest(actions.getUserExperience, getUserExperienceSaga),
		takeLatest(actions.putUserExperience, putUserExperienceSaga),
		takeLatest(actions.getUserPractices, getUserPracticesSaga),
		takeLatest(actions.putUserPractices, putUserPracticesSaga),
		takeLatest(actions.putUserInformation, putUserInformationSaga),
		takeLatest(actions.putUserSkype, puthUserSkypeSaga),
		takeLatest(actions.changeUserEmail, changeUserEmailSaga)
	]);
}

export default saga;
