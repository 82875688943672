import {CaseReducer, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RequestError} from "@/shared/types/error";
import {AppointmentReport} from "@/shared/types/report";

interface IReportsState {
	reports: {
		isLoading: boolean
		data: AppointmentReport[]
		errors?: RequestError
	}
	createReport: {
		isLoading: boolean
		data: AppointmentReport
		errors?: RequestError
	}
	patchReport: {
		isLoading: boolean
		errors?: RequestError
	}
}

const addReportToState = (
	reportState: IReportsState["reports"],
	report: AppointmentReport
): IReportsState["reports"] => ({
	...reportState,
	data: [...reportState.data, report]
});

const updateReportForState = (
	reportState: IReportsState["reports"],
	report: AppointmentReport
): IReportsState["reports"] => ({
	...reportState,
	data: reportState.data.map((rep) => (rep.id === report.id ? report : rep))
});

const getInitialState = (): IReportsState => ({
	reports: {
		isLoading: false,
		data     : null
	},
	createReport: {
		isLoading: false,
		data     : null
	},
	patchReport: {
		isLoading: false
	}
});

const getReports: CaseReducer<IReportsState> = (state) => ({
	...state,
	reports: {
		...state.reports,
		isLoading: true
	}
});

const getReportsSuccess: CaseReducer<
IReportsState,
PayloadAction<AppointmentReport[]>
> = (state, action) => ({
	...state,
	reports: {
		isLoading: false,
		data     : action.payload
	}
});

const getReportsFailure: CaseReducer<
IReportsState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	reports: {
		...state.reports,
		isLoading: false,
		errors   : action.payload
	}
});

const createReport: CaseReducer<
IReportsState,
PayloadAction<Omit<AppointmentReport, "id">>
> = (state) => ({
	...state,
	createReport: {
		...state.createReport,
		isLoading: true
	}
});

const createReportSuccess: CaseReducer<
IReportsState,
PayloadAction<AppointmentReport>
> = (state, action) => ({
	...state,
	createReport: {
		data     : action.payload,
		isLoading: false
	},
	reports: addReportToState(state.reports, action.payload)
});

const createReportFailure: CaseReducer<
IReportsState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	createReport: {
		...state.createReport,
		isLoading: false,
		errors   : action.payload
	}
});

const patchReport: CaseReducer<
IReportsState,
PayloadAction<AppointmentReport>
> = (state) => ({
	...state,
	patchReport: {
		...state.patchReport,
		isLoading: true
	}
});

const patchReportSuccess: CaseReducer<
IReportsState,
PayloadAction<AppointmentReport>
> = (state, action) => ({
	...state,
	patchReport: {
		isLoading: false
	},
	reports: updateReportForState(state.reports, action.payload)
});

const patchReportFailure: CaseReducer<
IReportsState,
PayloadAction<RequestError>
> = (state, action) => ({
	...state,
	patchReport: {
		isLoading: false,
		errors   : action.payload
	}
});

const slice = createSlice({
	name        : "reports",
	initialState: getInitialState(),
	reducers    : {
		getReports,
		getReportsSuccess,
		getReportsFailure,
		createReport,
		createReportSuccess,
		createReportFailure,
		patchReport,
		patchReportSuccess,
		patchReportFailure
	}
});

export const {reducer, actions} = slice;
