import React from "react";
import {useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import {getPsychologistState} from "@/shared/model/psychologist/selectors";
import {routePath} from "@/app/routes";
import {Row} from "@/shared/ui/uikit/Layout";

import ProfileNavigation from "./ProfileNavigation";
import ProfilePhoto from "./ProfilePhoto";

const Profile = ({className}: TProps) => {
	const psychologistState = useSelector(getPsychologistState);
	const profile = psychologistState.profile.data;
	const location = useLocation();

	const _profileLink = () => {
		const isActive = location.pathname.includes("profile") ? "profile-menu__link--active" : "";
		return (
			<Link className={`profile-menu__link ${isActive}`} to={`${routePath.dashboard}/profile`}>
				<Row p="none" display="flex" alignItems="center">
					{profile.protectedName}
					<ProfilePhoto url={profile.photo} />
				</Row>
			</Link>
		);
	};

	return (
		<Row className="profile-menu"
			p="none"
			display="flex"
			justify="space-between"
		>
			<ProfileNavigation className={className}/>
			{_profileLink()}
		</Row>
	);
};

interface TProps {
	className?: string
}

export default Profile;
