import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-use";
import * as Sentry from "@sentry/react";


import {faro} from "@/shared/lib/monitoring/faroInit";

import OnboardingBlock from "./OnboardingBlock";
import ProfileBlock from "./ProfileBlock";
import {routePath} from "@/app/routes";

import "./styles.scss";

interface TProps {
	children: React.ReactNode
}

export const PrivateLayout: React.FC<TProps> = ({children}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const userId = localStorage.getItem("userId");

	useEffect(() => {
		if (!userId) {
			onLogout();
		}
	}, []);

	const onLogout = useCallback(() => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("userId");

		Sentry.setUser(null);
		faro.api.setUser({
			id        : String(userId) || "undefined",
			attributes: {
				role: "psychologist"
			}
		});

		navigate(routePath.login);
	}, []);
	return (
		<>
			{
				location.pathname.startsWith(`${routePath.default}/questionary`)
					? <OnboardingBlock onLogout={onLogout}>
						{children}
					</OnboardingBlock>
					: <ProfileBlock onLogout={onLogout}>
						{children}
					</ProfileBlock>
			}
		</>
	);
};
