import {all, call, put, takeLatest} from "redux-saga/effects";

import {
	getProfileOnModerationApi,
	postGetPhotoOnModerationApi, 	putAboutOnModerationApi,
	putCompetencesOnModerationApi,
	putEducationOnModerationApi,
	putPersonalOnModerationApi,
	putPhotoOnModerationApi, putPracticesOnModerationApi,
	putRejectModerationApi
} from "@/shared/api/v1/psychologists/requests";
import * as types from "@/shared/types/profile";

import {actions as profileActions} from "./slice";

function* callPutCompetencesOnModerationSaga({payload}: types.PutCompetencesOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(putCompetencesOnModerationApi, {psychologistId, competences: payload});
		yield put(profileActions.PUT_COMPETENCES_ON_MODERATION_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_COMPETENCES_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutPracticesOnModerationSaga({payload}: types.PutPracticesOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(putPracticesOnModerationApi, {psychologistId, practices: payload});
		yield put(profileActions.PUT_PRACTICES_ON_MODERATION_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_PRACTICES_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutAboutOnModerationSaga({payload}: types.PutAboutOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(putAboutOnModerationApi, {psychologistId, about: payload});
		yield put(profileActions.PUT_ABOUT_ON_MODERATION_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_ABOUT_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutPersonalOnModerationSaga({payload}: types.PutPersonalOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(putPersonalOnModerationApi, {psychologistId, personal: payload});
		yield put(profileActions.PUT_PERSONAL_ON_MODERATION_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_PERSONAL_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutEducationOnModerationSaga({payload}: types.PutEducationOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		yield call(putEducationOnModerationApi, {psychologistId, education: payload});
		yield put(profileActions.PUT_EDUCATION_ON_MODERATION_SUCCESS());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_EDUCATION_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutPhotoOnModerationSaga({payload}: types.PutPhotoOnModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const formData = new FormData();
		yield formData.append("file", payload);
		yield formData.append("userId", String(psychologistId));
		const photo = yield call(postGetPhotoOnModerationApi, {payload: formData});

		yield call(putPhotoOnModerationApi, {psychologistId, payload: {photoUrl: photo.data.file.url}});
		yield put(profileActions.PUT_PHOTO_ON_MODERATION_SUCCESS());
		yield put(profileActions.GET_PROFILE_ON_MODERATION());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_PHOTO_ON_MODERATION_ERROR(err.response.status));
	}
}

function* callPutRejectModerationSaga({payload}: types.PutRejectModerationTAction) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		/** В случе, если передаем массив сервисов для реджекта модерации e.g. {reason: 'no reason', service: ['person', 'photo']} **/
		if (Array.isArray(payload.service)) {
			yield all(payload.service.map(service => {
				return call(putRejectModerationApi, {psychologistId, payload: {reason: payload.reason, service: service}});
			}));
			/** либо одним сервисом обходимся e.g. {reason: 'no reason', service: 'person'} **/
		} else {
			yield call(putRejectModerationApi, {psychologistId, payload});
		}
		yield put(profileActions.PUT_REJECT_MODERATION_SUCCESS(200));
		yield put(profileActions.GET_PROFILE_ON_MODERATION());
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.PUT_REJECT_MODERATION_ERROR(err.response.status));
	}
}

function* callGetProfileOnModerationSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const moderation = yield call(getProfileOnModerationApi, {psychologistId});
		yield put(profileActions.GET_PROFILE_ON_MODERATION_SUCCESS(moderation.data));
	} catch (err) {
		if (!err.response) return new Error("Непредвиденная ошибка. Возможно, CORS.");
		yield put(profileActions.GET_PROFILE_ON_MODERATION_ERROR(err.response));
	}
}

function* profileSaga() {
	yield all([
		takeLatest(profileActions.PUT_COMPETENCES_ON_MODERATION, callPutCompetencesOnModerationSaga),
		takeLatest(profileActions.PUT_PRACTICES_ON_MODERATION, callPutPracticesOnModerationSaga),
		takeLatest(profileActions.PUT_ABOUT_ON_MODERATION, callPutAboutOnModerationSaga),
		takeLatest(profileActions.PUT_PERSONAL_ON_MODERATION, callPutPersonalOnModerationSaga),
		takeLatest(profileActions.PUT_EDUCATION_ON_MODERATION, callPutEducationOnModerationSaga),
		takeLatest(profileActions.PUT_PHOTO_ON_MODERATION, callPutPhotoOnModerationSaga),
		takeLatest(profileActions.GET_PROFILE_ON_MODERATION, callGetProfileOnModerationSaga),
		takeLatest(profileActions.PUT_REJECT_MODERATION, callPutRejectModerationSaga)
	]);
}

export default profileSaga;
