import React from "react";
import cls from "classnames";

import {Column} from "./Column";

/**
 * @prop [footer] Прибиваемые к футеру модалки кнопки.
 */
interface Props {
	footer?: React.ReactElement
}

export const DialogContent = ({
	children,
	footer,
	className
}: Props &
React.PropsWithoutRef<JSX.IntrinsicElements["div"]>): React.ReactElement => {
	return (
		<div className="zg__dialog-content">
			<div className={cls("zg__dialog-content__container", className)}>
				<Column>{children}</Column>
			</div>
			{footer && <div className="zg__dialog-content__footer">{footer}</div>}
		</div>
	);
};
