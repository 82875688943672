import React from "react";
import {useSelector} from "react-redux";

import onboardingSelectors from "@/pages/Questionary/model/selectors";
import {Row} from "@/shared/ui/uikit/Layout";

import "./questionary-menu.scss";

interface TProps {
	className?: string
}

const Questionary: React.FC<TProps> = ({className}) => {
	const personal = useSelector(onboardingSelectors.GET_DATA_PERSONAL);

	return (
		<Row className={`questionary-menu ${className}`}
			p="none"
			display="flex"
			justify="end"
		>
			<Row p="none" display="flex" alignItems="center">
				{`${personal.firstName} ${personal.lastName[0]}.`}
			</Row>
		</Row>
	);
};

export default React.memo(Questionary);
