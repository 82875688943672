import axios from "axios";

import {routePath} from "@/app/routes";

import {refreshToken} from "./v1/auth/requests";

/*
Логика работы авторизации:
всем запросам нужен Authorization header с accessToken, берется из localStorage
для обновления accessToken по refreshToken используется кука
(и это единственное место, где нужны куки, в остальном полагаемся на токены в localStorage)
*/

const getRefreshToken = () => {
	const refreshToken = localStorage.getItem("refreshToken");
	return refreshToken;
};

const getAccessToken = () => {
	const accessToken = localStorage.getItem("accessToken");
	return accessToken;
};

const setAccessToken = (newAccessToken: string) => {
	localStorage.setItem("accessToken", newAccessToken);
};

// При каждом запросе, добавим заголовки авторизации
axios.interceptors.request.use(
	(config) => {
		const accessToken = getAccessToken();
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// Логика для проверки, не протух ли AccessToken, и его обновления
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;

		// Если это запрос создания сессии или восстановления запроса пароля, то не выполняем refresh запросы.
		if (
			!originalRequest.url.endsWith("password_session") &&
      !originalRequest.url.endsWith("password_reset")
		) {
			if (error && error.response && error.response.status) {
				// если ошибка авторизации или refresh-token протух и это был запрос на refresh
				if (
					(error.response.status === 401 || error.response.status === 400) &&
			originalRequest.url.endsWith("refresh")
				) {
					// Рефреш не сработал, редирект на логин
					localStorage.clear();
					window.location.href = routePath.login;
					return Promise.reject(error);
				}

				// если ошибка авторизации и это был НЕ запрос на refresh
				// и это не синхронизация с гугл
				if (
					error.response.status === 401 &&
			!originalRequest._retry &&
			!originalRequest.url.endsWith("refresh") &&
			!originalRequest.url.endsWith("sync") &&
			!originalRequest.url.endsWith("/calendars/auth")
				) {
					originalRequest._retry = true;

					// Пробуем обновить access токен...
					return refreshToken().then((res) => {
						if (res.status === 200) {
							setAccessToken(res.data.accessToken);
							axios.defaults.headers.common.Authorization =
				  "Bearer " + getAccessToken();
							return axios(originalRequest);
						}
					});
				}
			}
		}
		return Promise.reject(error);
	}
);

export const apiClient = axios;
