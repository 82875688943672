// import {connectRouter} from 'connected-react-router';
import {combineReducers} from "@reduxjs/toolkit";

import {reducer as eventModal} from "@/pages/Dashboard/model/eventModal/slice";
import {reducer as reportModal} from "@/pages/Dashboard/model/reportModal/slice";
import {reducer as reports} from "@/pages/Dashboard/model/reports/slice";
import {reducer as profile} from "@/pages/Profile/model/slice";
import {reducer as onboarding} from "@/pages/Questionary/model/slice";
import {reducer as auth} from "@/shared/model/auth/slice";
import {reducer as calendar} from "@/shared/model/calendar/slice";
import {reducer as notification} from "@/shared/model/notification/slice";
import {reducer as psychologist} from "@/shared/model/psychologist/slice";
import {reducer as qiwi} from "@/shared/model/qiwi/slice";
import {reducer as services} from "@/shared/model/services/slice";
import {reducer as menu} from "@/widgets/PrivateLayout/model/slice";
import {reducer as errorModal} from "@/widgets/ServerErrorModal/model/slice";

// const router = connectRouter(history);

const reducer = combineReducers({
	// router,
	auth,
	calendar,
	notification,
	psychologist,
	reports,
	qiwi,
	errorModal,
	eventModal,
	reportModal,
	menu,
	profile,
	services,
	onboarding
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
