import {PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {all, call, put, takeLatest} from "redux-saga/effects";

import {actions as notificationActions} from "@/shared/model/notification/slice";
import {
	getReports,
	patchReport,
	postReport
} from "@/shared/api/v1/reports/requests";
import {AppointmentReport} from "@/shared/types/report";

import {actions} from "./slice";

function* getReportsSaga() {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(getReports, {
			psychologistId
		});
		yield put(actions.getReportsSuccess(response.data || []));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при загрузке отчетов по сессиям"
		};
		yield put(actions.getReportsFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* createReportSaga(action: PayloadAction<Omit<AppointmentReport, "id">>) {
	try {
		const psychologistId = +localStorage.getItem("userId");
		const response = yield call(postReport, action.payload);
		yield put(
			actions.createReportSuccess({...action.payload, id: response.data})
		);
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при сохранении отчета. Попробуйте позже."
		};
		yield put(actions.createReportFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* patchReportSaga(action: PayloadAction<AppointmentReport>) {
	try {
		yield call(patchReport, action.payload);
		yield put(actions.patchReportSuccess(action.payload));
	} catch (error) {
		const axiosError = error as AxiosError;
		const requestError = {
			status : axiosError.response?.status,
			message: "Ошибка при сохранении отчета. Попробуйте позже."
		};
		yield put(actions.patchReportFailure(requestError));
		yield put(notificationActions.notifyError(requestError));
	}
}

function* saga() {
	yield all([takeLatest(actions.getReports, getReportsSaga)]);
	yield all([takeLatest(actions.createReport, createReportSaga)]);
	yield all([takeLatest(actions.patchReport, patchReportSaga)]);
}

export default saga;
