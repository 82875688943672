import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {CalendarEvent} from "@/shared/types/calendar";
import {Button} from "@/shared/ui/uikit/Form";
import {CloseButton, DialogContent, ModalDialog, Row, SupportButtons} from "@/shared/ui/uikit/Layout";

import {Typography} from "../../../shared/ui/uikit/Typography";
import {getErrorModalState} from "../model/selectors";
import {actions as errorModalActions} from "../model/slice";

interface Props {
	onClose: () => void
	event: CalendarEvent
}

export const ServerErrorModal = () => {
	const dispatch = useDispatch();
	const {isShowingErrorModal} = useSelector(getErrorModalState);
	const onClose = () => {
		dispatch(errorModalActions.hideErrorModal());
	};

	return (
		<>
			{isShowingErrorModal && (
				<ModalDialog className="modal-confirm">
					<DialogContent>
						<div className="closeBtnWrapper">
							<CloseButton onClick={onClose} />
						</div>
						<Row p="large">
							<Typography variant="h2" weight="bold" textAlign="center">
                Упс, что-то пошло не так...
							</Typography>
						</Row>
						<Row p="medium">
							<Typography textAlign="center">
                При загрузке произошла ошибка.
							</Typography>
							<Typography textAlign="center">
                Напишите нам, мы все починим.
							</Typography>
						</Row>
						<Row display="flex" justify="space-between">
							<Button color="primary" width="wide" onClick={onClose}>
								<Typography textAlign="center">Понятно</Typography>
							</Button>
							<SupportButtons />
						</Row>
						<Row p="large" />
					</DialogContent>
				</ModalDialog>
			)}
		</>
	);
};
