import React, {forwardRef} from "react";

import "./custom-radio.scss";

export const CustomRadio: React.FC<TProps> = React.memo(forwardRef((
	{
		className,
		checked,
		data,
		isValid = true,
		label,
		name,
		onChange,
		errorMessage
	}
	, ref) => {
	const _renderRadio = () => {
		return data.map(radio => {
			return (
        <label key={radio.value} className='custom-radio__wrapper' data-tour={radio.attr}>
          <input className='custom-radio__input'
                 type='radio'
                 name={name}
                 onChange={() => onChange(radio)}
                 defaultChecked={radio.checked}
                 checked={radio.value === checked}
                 value={radio.value}
                 ref={ref}
          />
          <span className="custom-radio__text">{radio.label}</span>
        </label>
			);
		});
	};

	const _renderLabel = () => {
		return (
      <span className="custom-radio__label">{label}</span>
		);
	};

	return (
    <div className={`custom-radio ${className} ${isValid ? "" : "custom-radio--invalid"}`}>
      {label && _renderLabel()}
      <div className="custom-radio__inputs-wrapper">
        {_renderRadio()}
      </div>
			{_renderErrorMessage(errorMessage)}
    </div>
	);
}));

const _renderErrorMessage = (errorMessage) => {
	if (!errorMessage) return null;
	return (
    <span className="custom-radio__error-message">{errorMessage}</span>
	);
};

interface TProps {
	className: string
	onChange: (returnData: TRadioData) => void
	data: TRadioData[]
	name: string
	ref?: React.ForwardedRef<HTMLInputElement>
	checked?: string
	isValid?: boolean
	label?: string
	errorMessage?: string
}

export interface TRadioData {
	value: string
	label: string
	checked?: boolean
	attr?: any
}

CustomRadio.displayName = "CustomRadio";
